import { Button, Form, message, Modal } from 'antd';
import React, { useState } from 'react';
import DebounceInput from '../utility/DebounceInput';
import { useAxiosChatPut, useAxiosGet } from '../../configs/axios';

function ReAssignChatModal({ open, setState, roomRef, chatSource }) {
  const [form] = Form.useForm();
  const closeModal = () => {
    setState(false);
  };
  const { request: getAllAgents } = useAxiosGet('/users/assign');
  const { request: reAssignChat, loading: loadingReAssignChat } = useAxiosChatPut('/orders/reassignChat');
  
  const fetchAgentsDropdown = async (searchQuery) => {
    const response = await getAllAgents({ search: searchQuery });
    
    return response.map((user) => ({
      label: `${user.userName}`,
      value: JSON.stringify(user),
    }));
  };
  const [agentSearchValue, setAgentSearchValue] = useState('');
  
  const onFinish = async (values) => {
    const agent = JSON.parse(values.agentId[0].value);
    const body = {
      roomRef,
      source: chatSource,
      agentId: agent._id
    };
    try {
      await reAssignChat(body);
      setState(false);
    } catch (error) {
      message.error('failed to re-assign chat!');
    }
  };
  
  return (
    <Modal
      title = {'Re-Assign Chat'}
      open = {open}
      onCancel = {closeModal}
      footer = {null}
      width = {'30%'}
    >
      <Form
        form = {form}
        onFinish = {onFinish}
        layout = "vertical"
      >
        <Form.Item
          label = {'Choose an agent'}
          name = "agentId"
        >
          <DebounceInput
            value = {agentSearchValue}
            placeholder = "Filter by Agent"
            fetchOptions = {fetchAgentsDropdown}
            onChange = {(newValue) => {
              setAgentSearchValue(newValue);
            }}
          />
        </Form.Item>
        <Form.Item
          wrapperCol = {{
            offset: 18,
            span: 6
          }}
        >
          <Button
            style = {{ marginTop: '20px', width: '100%' }}
            type = "primary"
            loading = {loadingReAssignChat}
            htmlType = "submit"
          >
            {'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ReAssignChatModal;
