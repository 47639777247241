import { useRef } from 'react';
import { Mentions } from 'antd';
import { useAxiosGet } from '../../configs/axios';
const CustomMention = ({selectedUser,comment}) => {
  const {
    request: requestGetUser,
    loading: loadingQAOrders,
    data: dataQAOrders,
  } = useAxiosGet('/users/getUserForMention', {
    autoRun: false,
  });

  const ref = useRef();

  const onSearch = (search) => {
    console.log('Search:', search);
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      requestGetUser({ search });
    }, 300);
  };
  return (
    <Mentions
      style={{
        width: '100%',

      }}
      rows={4} // number of rows to be written !!!!
      onChange={(value) =>comment.current = value}
      onSelect={(option) => {
        
        selectedUser.current = [...selectedUser.current, option]
        /// make sure there is no duplicate
        selectedUser.current = selectedUser.current.filter((v, i, a) => a.indexOf(v) === i);
      }}
      loading={loadingQAOrders}
      onSearch={onSearch}
      options={dataQAOrders?.map(({ userName }) => {
        return {
          key: userName,
          value: userName,
          label: userName,
        }
      })}
    />
  );
};
export default CustomMention;
