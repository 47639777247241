import { Modal, Tabs } from 'antd';
import React from 'react';
import { useAxiosGet } from '../../../configs/axios';
import AssignAgentForm from './AssignAgentForm';
import AssignOrderTagForm from './AssignOrderTagForm';
import CancelOrderForm from './CancelOrderForm';
import ManualOrderForm from './ManualOrderForm';
import RejectOrderForm from './RejectOrderForm';
import CreateProductForm from './CreateProductForm';
import EditOrderNotes from './EditOrderNotes';
import PenaltyMerchants from './PenaltyMerchants';
import useLocalStorage from '../../../configs/localStorage';
import { MerchantPenalties } from '../../../configs/constants';
import ChangeOrderPickUpAndDiningToOnDemand from './ChangeOrderPickUpAndDiningToOnDemand';

function EditOrderModal(props) {
  const {
    status,
    setStatus,
    selectedOrder,
    getAllOrders,
    ordersSearchFilters,
  } = props;
  const [auth] = useLocalStorage('auth', null);

  const setPermission = auth.user.role.permissions.filter(
    (role) => role.name == MerchantPenalties,
  ).length;

  const { data: orderCancelationData } = useAxiosGet('/orderCancelation', {
    autoRun: true,
  });
  return (
    <Modal
      title="Edit Order"
      destroyOnClose
      getContainer={false}
      open={status}
      onCancel={() => {
        setStatus(false);
      }}
      footer={null}
      centered
      width={'50%'}
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: `Manual Order`,
            key: '1',
            children: (
              <ManualOrderForm
                closeModal={() => {
                  setStatus(false);
                }}
                getAllOrders={getAllOrders}
                selectedOrder={selectedOrder}
              />
            ),
          },
          {
            label: `Assign Tags`,
            key: '3',
            children: (
              <AssignOrderTagForm
                closeModal={() => {
                  setStatus(false);
                }}
                orderCancelationData={orderCancelationData}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            ),
          },
          {
            label: `Assign Agents`,
            key: '4',
            children: (
              <AssignAgentForm
                closeModal={() => {
                  setStatus(false);
                }}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            ),
          },
          {
            label: `Cancel Order`,
            key: '2',
            children: (
              <CancelOrderForm
                closeModal={() => {
                  setStatus(false);
                }}
                orderCancelationData={orderCancelationData}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            ),
          },
          {
            label: `Reject Order`,
            key: '10',
            disabled: !selectedOrder.isRejectionEnabled,
            children: (
              <RejectOrderForm
                closeModal={() => {
                  setStatus(false);
                }}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            ),
          },
          {
            label: `Create New Product`,
            key: '13',
            children: (
              <CreateProductForm
                closeModal={() => {
                  setStatus(false);
                }}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
                selectedOrder={selectedOrder}
              />
            ),
          },
          {
            label: `Edit Order Notes`,
            key: '15',
            children: (
              <EditOrderNotes
                closeModal={() => {
                  setStatus(false);
                }}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
                selectedOrder={selectedOrder}
              />
            ),
          },
          {
            label: `Penalty Merchants`,
            key: '16',
            disabled: setPermission == 0,
            children: (
              <PenaltyMerchants
                closeModal={() => {
                  setStatus(false);
                }}
                getAllOrders={getAllOrders}
                selectedOrder={selectedOrder}
              />
            ),
          },
          
          {
            label: `To On Demand`,
            key: '17',
            disabled:![5,6].includes(selectedOrder.orderTypeId),
            children: (
              <ChangeOrderPickUpAndDiningToOnDemand
                closeModal={() => {
                  setStatus(false);
                }}
                getAllOrders={getAllOrders}
                selectedOrder={selectedOrder}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default EditOrderModal;
