const Merchant = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.44 3.19935C11.38 2.89268 11.1067 2.66602 10.7867 2.66602L1.21333 2.66602C0.893333 2.66602 0.62 2.89268 0.56 3.19935L0 5.99935L0 6.66602C0 7.03268 0.3 7.33268 0.666667 7.33268L0.666667 10.666C0.666667 11.0327 0.966667 11.3327 1.33333 11.3327L6.66667 11.3327C7.03333 11.3327 7.33333 11.0327 7.33333 10.666L7.33333 7.33268L10 7.33268V10.666C10 11.0327 10.3 11.3327 10.6667 11.3327C11.0333 11.3327 11.3333 11.0327 11.3333 10.666V7.33268C11.7 7.33268 12 7.03268 12 6.66602L12 5.99935L11.44 3.19935ZM6 9.99935L2 9.99935L2 7.33268L6 7.33268L6 9.99935ZM1.33333 1.99935L10.6667 1.99935C11.0333 1.99935 11.3333 1.69935 11.3333 1.33268C11.3333 0.966016 11.0333 0.666016 10.6667 0.666016L1.33333 0.666016C0.966667 0.666016 0.666667 0.966016 0.666667 1.33268C0.666667 1.69935 0.966667 1.99935 1.33333 1.99935Z"
        fill="#313131"
      />
    </svg>
  );
};

export default Merchant;
