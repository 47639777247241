
import { Col, Modal, Row, Typography, Table } from 'antd';
import { useAxiosGet } from '../../configs/axios';
import dayjs from 'dayjs';
const { Title, Paragraph } = Typography;
const VipCustomerInformationModal = ({ customer_id, vipVisible, setVipVisible }) => {
  const {
      data: VipCustomerData,
      loading: VipCustomerLoading,
  } = useAxiosGet(`/vip/${customer_id}`, {
    autoRun: customer_id,
  });

    const onClose = () => {
      setVipVisible(false);
    };
    const VoucherColumns = [
      {
        title: 'Voucher Customer Uses',
        dataIndex: 'voucher_customer_uses',
        key: 'voucher_customer_uses',
      },
      {
        title: 'Voucher Customer Used',
        dataIndex: 'voucher_customer_used',
        key: 'voucher_customer_used',
      },
    ];
    const SubscriptionColumns = [
      {
        title: 'Subscription Order ID',
        dataIndex: 'subscription_order_id',
        key: 'subscription_order_id',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Price',
        dataIndex: 'tier_price',
        key: 'tier_price',
      },
      {
        title: 'Start Date',
        dataIndex: 'subscription_start_date',
        key: 'subscription_start_date',
        render: (text) => dayjs(text).format('YYYY-MM-DD'), // Convert to readable date
      },
      {
        title: 'End Date',
        dataIndex: 'subscription_end_date',
        key: 'subscription_end_date',
        render: (text) => dayjs(text).format('YYYY-MM-DD'), // Convert to readable date
      },
      {
        title: 'Order Status',
        dataIndex: 'subscription_order_status',
        key: 'subscription_order_status',
      },
      {
        title: 'Reference Order ID',
        dataIndex: 'ref_order_id',
        key: 'ref_order_id',
      },
    ];
  
    return (
      <Modal
        title="VIP Information"
        open={vipVisible}
        onCancel={onClose}
        onClose={onClose}
        footer={null}
        centered
        width={1200}
      >
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={3}>Current Subscription</Title>
        <Table
          dataSource={VipCustomerData?.currentSubscription ? [VipCustomerData?.currentSubscription] : []}
          columns={SubscriptionColumns}
          pagination={false}
          loading={VipCustomerLoading}
        />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={3}>Vouchers</Title>
        <Table
          dataSource={VipCustomerData?.voucher}
          columns={VoucherColumns}
          pagination={false}
          loading={VipCustomerLoading}
        />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={3}>All Subscriptions</Title>
        <Table
          scroll={{ y: 400 }}
          dataSource={VipCustomerData?.allSubscriptions}
          columns={SubscriptionColumns}
          pagination={false}
          loading={VipCustomerLoading}
        />
      </Col>
    </Row>
  
      </Modal>
    );
  };
  export default VipCustomerInformationModal;