import {
  Button,
  Image,
  Input,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useCallback, useState } from 'react';
import RoleModal from './RoleModal';
import { useHookstate } from '@hookstate/core';
import supportTeamMetricsStates from '../States';
import supportTeamMetricsFunctions from '../SupportTeamMetricsFunctions';
import TabList from './TabList';
import { useAxiosChatPut } from '../../../configs/axios';

function AgentList() {
  const dataState = useHookstate(supportTeamMetricsStates.dataState);

  const modalState = useHookstate(supportTeamMetricsStates.modalState);
  const [statusLoading, setStatusLoading] = useState(false); // State to manage loading status of the Switch component

  const { request: updateStatus } = useAxiosChatPut(
    'agents/changeActive',
    {},
  );

  const debouncedSearch = useCallback(
    debounce((value) => {
      supportTeamMetricsFunctions.getSupportTeamMetrics(value);
    }, 500),
    [],
  );
  const handleSearch = (e) => {
    const { value } = e.target;
    debouncedSearch(value);
  };

  //every 10 seconds get the support team metrics
  useEffect(() => {
    const interval = setInterval(() => {
      supportTeamMetricsFunctions.getSupportTeamMetrics();
    }, 10000);

    const getSupportTeamMetrics = async () => {
      await supportTeamMetricsFunctions.getSupportTeamMetrics();
    };

    getSupportTeamMetrics();
    return () => clearInterval(interval);
  }, []);

  const onChangeStatus = async (agentId, checked) => {
    setStatusLoading(true);
    try {
      await updateStatus({
        agentId: agentId,
        isActive: checked,
      });
      supportTeamMetricsFunctions.getSupportTeamMetrics();
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      setStatusLoading(false);
    }
  };
  const getRoleColor = (role) => {
    switch (role) {
      case 'CA':
        return 'blue';
      case 'RA':
        return 'green';
      case 'MS':
        return 'purple';
      case 'DS':
        return 'red';
      case 'SP':
        return 'grey';
      default:
        return 'grey';
    }
  };

  const columns = [
    {
      title: 'Agent',
      dataIndex: '',
      key: 'agent',
      width: 170,
      align: 'left',
      render: (e) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 50,
              height: 50,
              borderRadius: '50%',
            }}
          >
            <Image
              src={e?.agentImage || 'https://picsum.photos/200'}
              width={50}
              style={{
                borderRadius: '50%',
              }}
            />
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: e?.isOnline ? '#496' : '#e4343e',
                borderRadius: '50%',
                position: 'absolute',
                right: 0,
                bottom: 0,
                border: '2px solid white',
                zIndex: 1,
              }}
            ></div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Text
              style={{
                fontWeight: 'bold',
              }}
            >
              {e?.agentName}
            </Typography.Text>
            <Typography.Text
              style={{
                color: e?.isOnline ? '#496' : '#e4343e',
                fontWeight: 'bold',
              }}
            >
              {e?.isOnline ? 'Online' : 'Offline'}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: 'Is Active (Chat)',
      responsive: ['sm'],
      rowSpan: 2,
      key: 'activeChats',
      align: 'center',
      render: (e) => {
        return (
          <Switch
            loading={statusLoading} 
            checked={e?.isInChatQueue}
            onChange={(checked) => onChangeStatus(e?.agentId, checked)}
          />
        );
      },
    },
    {
      title: 'Chats',
      responsive: ['sm'],
      rowSpan: 2,
      key: 'activeChats',
      render: (e) => (
        <Typography.Text
          style={{
            fontWeight: 'bold',
          }}
        >
          {e?.activeChats} {e?.limit ? `/ ${e?.limit}` : ''}
        </Typography.Text>
      ),
    },

    {
      title: 'Total Chats',
      dataIndex: 'totalChats',
      key: 'totalChats',
      render: (e) => (
        <Typography.Text
          style={{
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'Not Responded Chats',
      dataIndex: 'notRespondedChats',
      key: 'notRespondedChats',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 2 ? 'red' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'Late Responded Chats',
      dataIndex: 'lateRespondedChats',
      key: 'lateRespondedChats',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 2 ? 'red' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'Avg Response Time',
      dataIndex: 'avgResponseTime',
      key: 'avgResponseTime',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 2 ? 'red' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}m
        </Typography.Text>
      ),
    },
    {
      title: 'Pending Issues',
      dataIndex: 'pendingIssues',
      key: 'pendingIssues',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 2 ? 'red' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'In Progress Issues',
      dataIndex: 'inProgressIssues',
      key: 'inProgressIssues',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 2 ? 'red' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'Done Issues',
      dataIndex: 'doneIssues',
      key: 'doneIssues',
      render: (e) => (
        <Typography.Text
          style={{
            color: e > 100 ? 'green' : 'black',
            fontWeight: 'bold',
          }}
        >
          {e}
        </Typography.Text>
      ),
    },
    {
      title: 'Change Role',
      key: 'changeRole',
      render: (e) => (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tag color={getRoleColor(e?.agentType)}>
            <Button
              type="text"
              onClick={() => {
                modalState.data.set(_.cloneDeep(e));
                modalState.visible.set(true);
              }}
              style={{
                fontWeight: 'bold',
              }}
            >
              {e?.agentType} Edit
            </Button>
          </Tag>
        </Row>
      ),
    },
  ];
  return (
    <>
      <TabList />
      <Input.Search
        placeholder="Search agents..."
        style={{ margin: '16px 0 0', width: 300 }}
        onChange={handleSearch}
      />
      <Table
        tableLayout="fixed"
        rowKey={(record) => record.agentId}
        loading={dataState.loading.get()}
        dataSource={(dataState.filteredData?.get?.() || []).filter(
          (e) => e.agentType !== 'system',
        )}
        bordered
        style={{
          width: '100%',
          marginTop: 5,
        }}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Total
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.activeChats || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.totalChats || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')
                    ?.notRespondedChats || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')
                    ?.lateRespondedChats || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.avgResponseTime ||
                    0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.pendingIssues || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.inProgressIssues ||
                    0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <Typography.Text
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {dataState.filteredData
                    ?.get()
                    ?.find((e) => e.agentType === 'system')?.doneIssues || 0}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                <Typography.Text></Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        columns={columns}
      />
      <RoleModal />
    </>
  );
}

// Debounce function
function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}
export default AgentList;
