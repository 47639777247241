import { io } from 'socket.io-client';

class SocketManager {
  constructor(url, options = {}) {
    console.log('SocketManager constructor');

    this.url = url;
    this.options = options;
    this.socket = null;
    this.listeners = {};
  }

  connect() {
    if (this.socket && this.socket.connected) {
      console.log('Socket already connected.');
      return;
    }

    this.socket = io(this.url, this.options);

    this.socket.on('connect', () => {
      console.log('Socket connected inside socket!');
    });

    this.socket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
    });

    // this.socket.on('sent-message', (data) => {
    //   console.log('sent-message:', data);
    // });

    // this.socket.on('chat-room-resolved', (data) => {
    //   console.log('chat-room-resolved:', data);
    // });

    // this.socket.on('counter-update', (data) => {
    //   console.log('counter-update:', data);
    // });

    // this.socket.emit('join', 'agent:chatChannel');

    // Add your default listeners if any
    for (const [event, handler] of Object.entries(this.listeners)) {
      this.socket.on(event, handler);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log('Socket disconnected.');
    }
  }

  emit(event, data) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(event, data);
    } else {
      console.error('Cannot emit event. Socket not connected.');
    }
  }

  on(event, handler) {
    //console.log('SocketManager on:', event);
    if (this.socket) {
      this.socket.on(event, handler);
    }
    this.listeners[event] = handler; // Store listener for future reconnection
  }

  off(event) {
    if (this.socket) {
      this.socket.off(event);
    }
    delete this.listeners[event];
  }

  getSocket() {
    return this.socket;
  }
}

export default SocketManager;