import { Button, Modal, Select } from 'antd';
import React, { useState } from 'react';
import Invoice from './invoice';
import html2PDF from 'jspdf-html2canvas';

function ShowInvoiceOrder({ visible, setVisible, selectedOrder }) {
  const ref = React.createRef();
  const hexOrderId = selectedOrder.orderId.toString(16);

  const downloadDeliveryNotes = async () => {
    const page = document.getElementById('invoiceContainer');

    await html2PDF(page, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: `Invoice - #${selectedOrder.orderId}.pdf`,
    });
  };

  return (
    <Modal
      maskClosable={false}
      width={'95%'}
      destroyOnClose
      title={'Invoice of order id: #' + hexOrderId}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button type="primary" onClick={downloadDeliveryNotes}>
            Download Invoice
          </Button>
          <Button
              onClick={() => {
              import('./print/templateInvoice').then((module) => {
                    const getInvoice = module.templateInvoice(selectedOrder);
                    const win = window.open(
                      '',
                      'Title',
                      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=600,'
                    );

                    win.document.body.innerHTML = getInvoice;

                    setTimeout(() => {
                      win.print();
                    }, 2000);
                });
                }}
          >
            Print Invoice
              </Button>
        </div>

        <div
          style={{
            border: '1px solid black',
            width: '21.0cm',
            // height: "29.7cm",
            marginTop: '30px',
            paddingBottom: '30px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          id="invoiceContainer"
          ref={ref}
        >
          <Invoice dataSource={selectedOrder} />
        </div>
      </div>
    </Modal>
  );
}

export default ShowInvoiceOrder;
