import React, { useEffect, useState } from 'react';
import { Button, Image, Modal, Upload } from 'antd';
import {
  CloseOutlined,
  EyeTwoTone,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Player } from 'video-react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import 'video-react/dist/video-react.css'; // import css
import videoIcon from '../../assets/images/video.png';
import application from '../../assets/images/application.png';
import { useAxiosPost } from '../../configs/axios';

function FileUploader({
  form,
  imgUrl,
  formName,
  numberOfUpload = 1,
  imageUploader = false, // image uploader check if using form data to server or request to server and get the URL.
  body,
}) {
  const { loading: loadingImageUpload, request: requestImageUpload } =
    useAxiosPost('/upload/file', { autoRun: false });

  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState();

  const handleChange = (info) => {
    let fileList = [...info.fileList];
    if (!imageUploader) {
      setFileList(fileList);
      form.setFieldsValue({
        [formName]: fileList.map((file) => {
          return file.originFileObj;
        }),
      });
    }
  };

  const handleDelete = (fileRemoved) => {
    if (fileList.length === 1) {
      setFileList([]);
      form.setFieldsValue({
        [formName]: '',
      });
    } else {
      const filterImages = fileList.filter((file) => file.uid !== fileRemoved.uid);
      setFileList(filterImages);
      form.setFieldsValue({
        [formName]: filterImages,
      });
    }
  };

  useEffect(() => {
    if (imgUrl && fileList?.length < 1) {
      if (typeof imgUrl === 'object') {
        const newImage = [];
        imgUrl.forEach((img) => {
          newImage.push({
            uid: img.id,
            name: 'preview.png',
            status: 'done',
            url: img.url,
          });
        });
        setFileList(newImage);
        form.setFieldsValue({
          [formName]: newImage,
        });
      } else {
        setFileList([
          {
            uid: '-2',
            name: 'preview.png',
            status: 'done',
            url: imgUrl,
          },
        ]);
      }
    }
  }, [imgUrl, fileList, form, formName]);

  const upload = (
    <Upload
      multiple={true}
      listType="picture-card"
      fileList={fileList}
      progress={{
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 5,
        format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
      }}
      itemRender={(originNode, file, currFileList) => {
        const fileExtension = file.name.split('.').pop(); // Get file extension

        switch (file.type) {
          case 'video':
            return (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <Image src={videoIcon} preview={false} />
                <Button
                  onClick={() => handleDelete(file)}
                  icon={<CloseOutlined />}
                  style={{
                    top: 1,
                    right: 2,
                    position: 'absolute',
                  }}
                />
                <Button
                  type="link"
                  onClick={() => setFilePreview(file.url)}
                  icon={<EyeTwoTone />}
                  style={{
                    top: 40,
                    right: 40,
                    position: 'absolute',
                  }}
                />
              </div>
            );
          case 'image':
            return (
              <div
                style={{
                  position: 'relative',
                }}
              >
                <Image src={file.url} />
                <Button
                  onClick={() => handleDelete(file)}
                  icon={<CloseOutlined />}
                  style={{
                    top: 1,
                    right: 2,
                    position: 'absolute',
                  }}
                />
              </div>
            );
          default:
            return (
              <div
                style={{
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                {/* Use FileIcon for non-image and non-video files */}
                <FileIcon
                  extension={fileExtension}
                  {...defaultStyles[fileExtension] || defaultStyles.default}
                />
                <Button
                  onClick={() => handleDelete(file)}
                  icon={<CloseOutlined />}
                  style={{
                    top: 1,
                    right: 2,
                    position: 'absolute',
                  }}
                />
              </div>
            );
        }
      }}
      onChange={handleChange}
      onRemove={handleDelete}
      maxCount={numberOfUpload}
      customRequest={async (options) => {
        const { onSuccess, onError, file } = options;

        try {
          if (imageUploader) {
            const formData = new FormData();
            formData.append(formName, file);
            if (body) {
              for (const key in body) {
                const value = body[key];
                formData.append(key, value);
              }
            }

            const res = await requestImageUpload(formData);
            if (numberOfUpload === 1) {
              form.setFieldsValue({
                [formName]: res.url,
              });
              setFileList([
                {
                  uid: '-2',
                  status: 'done',
                  url: res.url,
                  type: res.type,
                  name: res.name,
                },
              ]);
            } else {
              const arrayImage = form.getFieldValue(formName) || [];
              arrayImage.push({
                uid: Math.random(),
                status: 'done',
                url: res.url,
                type: res.type,
                name: res.name,
              });
              form.setFieldsValue({
                [formName]: arrayImage,
              });
              setFileList(arrayImage);
            }
          }
          onSuccess('Ok');
        } catch (err) {
          onError({ err });
        }
      }}
    >
      {fileList.length >= numberOfUpload ? null : (
        <div>
          {loadingImageUpload ? <LoadingOutlined /> : <UploadOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      )}
    </Upload>
  );

  return (
    <>
      <Modal
        open={filePreview}
        destroyOnClose
        title="Preview"
        width={900}
        onClose={() => setFilePreview(null)}
        footer={null}
        onCancel={() => setFilePreview(null)}
      >
        <Player src={filePreview} />
      </Modal>
      {upload}
    </>
  );
}

export default FileUploader;
