import React from 'react';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { useAxiosPost } from '../../../configs/axios';

const CreateManualOrderModal = ({ closeModal, openCreateManualOrderModal }) => {
  const {  loading, request } = useAxiosPost('/orders/manual');

  const onManualOrderSubmit = async (values) => {
    try {
      await request(values);
      message.success('Order Created Successfully');
      closeModal();
    } catch (error) {
      message.error('Error Creating Order');
    }
  };
  return (
    <Modal
      onCancel={closeModal}
      title="Create Manual Order"
      open={openCreateManualOrderModal}
      footer={null}
    >
      <Form onFinish={onManualOrderSubmit} layout="vertical" >
        <Row justify={'end' } gutter={[10,10]}>
          <Col span={12} >
            <Form.Item label="Order ID" name="orderId" rules={[
              {
                required: true,
                message: 'Please input Order ID!',
              }
            ]}>
              <Input type="number" placeholder="Order Id" />
            </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item label="Total Price" name="orderPrice">
            <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
          </Form.Item>
          </Col>

          <Col span={12}>
          <Form.Item label="Customer Delivery Fee" name="orderDeliveryPrice">
            <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
          </Form.Item>

          </Col>
          <Col span={12}>
          <Form.Item label="Merchant Delivery Fee" name="orderMerchantDelivery">
            <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
          </Form.Item>
          </Col>
          <Col>
          <Button loading={loading} onClick={closeModal}>
            Cancel
          </Button>
          </Col>
          <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
          </Col>
     
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateManualOrderModal;
