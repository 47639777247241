import { Button, Form, Input, Modal, Row, Col, Spin } from "antd";
import { useAxiosGetTaskManagement, useAxiosTaskManagementPut } from "../../configs/axios";

const EditProductIssueModal = ({ open, onClose, selectedProduct, task }) => {
    const [form] = Form.useForm();
    const { data: productIssueData, loading: productIssueLoading, request: productIssueReq } = useAxiosGetTaskManagement(
        `/products/single`,
        {
            autoRun: true,
            product_id: selectedProduct.source_id,
        }
    )

    const {
        request: productIssueRequest
    } = useAxiosTaskManagementPut(`products/UpdateProductNameAndPrice`, {
        autoRun: false,
    });

    const handleCancel = () => {
        onClose();
    };
    const handleOk = async (values) => {
        try {
            const requestData = {
                product_id: selectedProduct.source_id,
                en_title: values.nameEn,
                kr_title: values.nameKr,
                ar_title: values.nameAr,
                bd_title: values.nameBd,
                price: values.price,
                discount: values.discountPrice,
            };
            await productIssueRequest(requestData);
            onClose();
        } catch (error) {
            console.error("Error updating product:", error);

        }
    };
    return (
        <Modal
            title="Edit Product Issue"
            open={open}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                null
            ]}
            width={1200}
        >
            {
                productIssueLoading || !productIssueData ? <Spin /> :
                    <Form form={form} layout="vertical"
                        onFinish={handleOk}
                        initialValues={productIssueData}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="nameEn"
                                    label="Product Name (English)"
                                    rules={[{ required: true, message: "Please enter the product name" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="nameKr"
                                    label="Product Name (Sorani)"
                                    rules={[{ required: true, message: "Please enter the product name" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="nameBd"
                                    label="Product Name (Badini)"
                                    rules={[{ required: true, message: "Please enter the product name" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="nameAr"
                                    label="Product Name (Arabic)"
                                    rules={[{ required: true, message: "Please enter the product name" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="price"
                                    label="Price"
                                    rules={[{ required: true, message: "Please enter the price" }]}
                                >
                                    <Input min={0} style={{ width: "100%" }}
                                        onChange={(e) => {
                                            form.setFieldsValue({
                                                discountPrice: e.target.value,
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="discountPrice"
                                    label="Discount Price "
                                    rules={[{ required: false }]}
                                >
                                    <Input min={0} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>

                                <Row gutter={[10, 10]} justify={'end'}>
                                    <Col>
                                        <Button key="back" onClick={handleCancel}>
                                            Close
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button key="submit" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>}
        </Modal>
    );
};

export default EditProductIssueModal;
