const Customer = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.5C4.64471 0.5 3.13709 2.01834 3.13709 3.88681C3.13709 5.75529 4.64471 7.27358 6.5 7.27358C8.35529 7.27358 9.86291 5.75529 9.86291 3.88681C9.86291 2.01834 8.35529 0.5 6.5 0.5ZM6.50122 7.77345C3.73777 7.77462 1.30059 9.60243 0.507629 12.2685C0.473307 12.3839 0.559097 12.5 0.678723 12.5H12.3213C12.4409 12.5 12.5267 12.3839 12.4924 12.2685C11.6997 9.60337 9.26393 7.7758 6.50145 7.77345H6.50122Z"
        fill="#313131"
      />
    </svg>
  );
};

export default Customer;
