import React from 'react';
import lezzooimg from '../../assets/lezzoo.png';
import './customStyle.css';
import _ from 'lodash';
import moment from 'moment';

function Invoice(props) {
  const moneyFormatter = (value) => {
    return (value || 0).toLocaleString() + ' IQD';
  };

  const carts = props.dataSource.cart.map((item) => {
    let name = '';

    try {
      name = (item.product_name || item.name).en;
    } catch (error) {
      name = '';
    }

    return {
      name,
      image: item.image ,
      product_image: item.product_image,
      quantity: item.quantity,
      singlePrice: moneyFormatter(
        item.single_product_price || item.single_product_subtotal,
      ),
      totalPrice: moneyFormatter(item.price || item.productTotalPrice),
    };
  });

  const subtotal = moneyFormatter(props.dataSource.orderSubtotal);
  const delivery = moneyFormatter(props.dataSource.orderDeliveryPrice);
  const total = moneyFormatter(props.dataSource.orderTotal);

  return (
    <div className="invoice">
      <div className="top">
        <img src={lezzooimg} alt="" />
        <div className="location">
          Lezzoo.Inc,
          <br />
          Esporta Building,
          <br />
          100m Street,
          <br />
          Erbil,
          <br />
          Iraq
        </div>
        <div>
          <p>
            <span className="red">Contact Us:</span>{' '}
            <strong>{'066 211 5600'}</strong>
          </p>
          <p>
            <span className="red">Reference Id:</span>{' '}
            <strong>{props.dataSource.orderId} </strong>
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h1 style={{ width: '50%' }}>{'Invoice'}</h1>
        <div style={{ width: '50%' }}>
          <h2>
            {props.dataSource.customer.customer_name +
              ' ' +
              props.dataSource.customer.customer_last_name}
          </h2>
        </div>
      </div>
      <div
        style={{
          gap: '50px',
          display: 'flex',
        }}
      >
        <p>
          <span className="red">Source:</span>{' '}
          <strong>{'#' + props.dataSource.orderId.toString(16)}</strong>
        </p>

        <p>
          <span className="red">Ordered At:</span>{' '}
          <strong>
            {moment(props.dataSource.orderDateAdded).format('YYYY-MM-DD HH:mm')}
          </strong>
        </p>
        <p>
          <span className="red">Phone No:</span>{' '}
          <strong>{props.dataSource.customer.customer_phone}</strong>
        </p>
      </div>

      <table
        style={{
          width: '100%',
        }}
      >
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {carts?.map((cart, index) => {
            if (index === 11 && index + 1 !== carts.length) {
              return (
                <>
                  <tr key={index}>
                  <td>
                      <img src={cart.image || cart.product_image} />
                    </td>
                    <td
                      style={{
                        wordWrap: 'break-word',
                        width: '200px',
                      }}
                    >
                      {cart.name}
                    </td>
                    <td>{cart.quantity}</td>
                    <td>{cart.singlePrice}</td>
                    <td>{cart.totalPrice}</td>
                  </tr>
                  <br />
                  <br />
                </>
              );
            }
            if (
              index > 11 &&
              index + 1 !== carts.length &&
              (index - 11) % 17 === 0
            ) {
              return (
                <>
                  <tr key={index}>
                  <td>
                      <img src={cart.image || cart.product_image} />
                    </td>
                    <td
                      style={{
                        wordWrap: 'break-word',
                        width: '200px',
                      }}
                    >
                      {cart.name}
                    </td>
                    <td>{cart.quantity}</td>
                    <td>{cart.singlePrice}</td>
                    <td>{cart.totalPrice}</td>
                  </tr>
                  <br />
                  <br />
                </>
              );
            }

            return (
              <tr key={index}>
                <td>
                    <img src={cart.image || cart.product_image} />
                    </td>
                <td
                  style={{
                    wordWrap: 'break-word',
                    width: '200px',
                  }}
                >
                  {cart.name}
                </td>
                <td>{cart.quantity}</td>
                <td>{cart.singlePrice}</td>
                <td>{cart.totalPrice}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="total final">
          <h4>Receiver Signature</h4>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <div className="total">
            <div>Delivery</div>
            <div>{delivery}</div>
          </div>
          <div className="total">
            <div>Subtotal</div>
            <div>{subtotal}</div>
          </div>

          <div className="total final">
            <div>Total</div>
            <div style={{ display: 'flex' }}>
              <div>{total}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
