import { Card } from 'antd';
import React, { useContext, useState } from 'react';
import { OrderContext } from '../../pages/Home';
import {
  UilFileMedicalAlt,
  UilClock,
  UilProcess,
  UilFileRedoAlt,
  UilFileCheckAlt,
  UilCarSideview,
  UilCheckCircle,
  UilTimesCircle,
  UilExclamationOctagon,
  UilCalendarAlt
} from '@iconscout/react-unicons';

const StatusesSelectionComponent = (props) => {
  const {
    getAllOrders,
    ordersSearchFilters,
    setOrdersSearchFilters,
    ordersLoading,
    ordersData,
  } = useContext(OrderContext);
  const [orderStatuses, setOrderStatuses] = useState([
    // {
    //   id: "all",
    //   name: "All Orders",
    //   active: false,
    //   icon: <UilFileInfoAlt />,
    // },
    {
      id: 'current',
      name: 'Current',
      active: false,
      icon: <UilFileMedicalAlt />,
    },
    {
      id: 'pending',
      name: 'Pending',
      active: true,
      icon: <UilClock />,
    },
    {
      id: 'processing',
      name: 'Processing',
      active: false,
      icon: <UilProcess />,
    },
    {
      id: 'preparing',
      name: 'Preparing',
      active: false,
      icon: <UilFileRedoAlt />,
    },
    {
      id: 'prepared',
      name: 'Prepared',
      active: false,
      icon: <UilFileCheckAlt />,
    },
    {
      id: 'ontheway',
      name: 'On the way',
      active: false,
      icon: <UilCarSideview />,
    },
    {
      id: 'schedule',
      name: 'Schedule',
      active: false,
      icon: <UilCalendarAlt />,
    },
    {
      id: 'completed',
      name: 'Completed',
      active: false,
      icon: <UilCheckCircle />,
    },
    {
      id: 'canceled',
      name: 'Canceled',
      active: false,
      icon: <UilTimesCircle />,
    },
    {
      id: 'urgent',
      name: 'Urgent',
      active: false,
      icon: <UilExclamationOctagon />,
    },
  ]);
  const onOrderStatusClick = async (id) => {
    if (ordersLoading) return;
    if (ordersSearchFilters.orderStatus === id) return;
    try {
      const newOrdersFilter = {
        ...ordersSearchFilters,
        orderStatus: id,
        page: 1,
      };
      await getAllOrders(newOrdersFilter);
      setOrdersSearchFilters(newOrdersFilter);
      const newOrderStatuses = orderStatuses.map((orderStatus) => {
        if (orderStatus.id === id)
          return {
            ...orderStatus,
            active: true,
          };
        else
          return {
            ...orderStatus,
            active: false,
          };
      });
      setOrderStatuses(newOrderStatuses);
    } catch (error) {}
  };

  return (
    <Card
      style={{
        boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.15)',
        zIndex: 1,
        position: 'fixed',
        width: 'calc(100% - 100px)'
      }}
      title={null}
      extra={null}
    >
      {orderStatuses.map((status, index) => (
        <Card.Grid
          style={{
            width: `${100/orderStatuses?.length||1}%`,
            // width: `10%`,
            cursor: 'pointer',
            textAlign: 'center',
            borderBottom: status.active
              ? '5px solid #1890ff'
              : '5px solid #757575',
            fontSize: '13px',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            height: 50
          }}
          key={status.id}
          onClick={() => onOrderStatusClick(status.id)}
        >
          {/* {cloneElement(status.icon, {
            key: index,
            style: {
              color: status.active ? '#1890ff' : '#000',

            },
          })} */}
          <span
            style={{
              // color: status.active ? '#1890ff' : '#000',
            }}
          >
            {status.name}{' '}
            <strong>
              (
              {
                ordersData?.tabCounters?.find(
                  (counter) => counter.status.toLowerCase() === status.id
                )?.count
              }
              )
            </strong>
          </span>
        </Card.Grid>
      ))}
    </Card>
  );
};

export default StatusesSelectionComponent;
