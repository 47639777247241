import { Form, Input, message, Modal, Radio, Space, Spin } from "antd";
import React, {  useEffect, useState } from "react";
import { useAxiosGet, useAxiosPut } from "../../configs/axios";

const ChangeOrderStatusModalComponent = (props) => {
  const [form] = Form.useForm();
  const {
    loading: orderStatusesLoading,
    error: orderStatusesError,
    data: orderStatusesData,
    request: getAllOrderStatuses,
  } = useAxiosGet("/orders/status");

  const [value, setValue] = useState(null);
  const [extraCredentials, setExtraCredentials] = useState({});

  const { loading: changeOrderStatusLoading, request: changeOrderStatus } =
    useAxiosPut("/orders/status");
  const { status, setStatus ,selectedOrder, getAllOrders, ordersSearchFilters} = props;

  const onChangeOrderStatus = async (values) => {
    const requestBody = {
      orderId: selectedOrder.orderId,
      statusId: values.statusId,
      courierOrderId: selectedOrder.courierSystemOrderId,
      preparationTime: Number(values.preparationTime),
    };

    try {
      await changeOrderStatus(requestBody);
      if(ordersSearchFilters){
      await getAllOrders(ordersSearchFilters);
      }
      else {
        await getAllOrders();
      }
      setStatus(false);
      message.success("Order status changed successfully");
    } catch (error) {
      message.error("Error while changing order status");
    }
  };

  useEffect(() => {
    getAllOrderStatuses({
      orderId: selectedOrder.orderId,
      orderStatus: selectedOrder.orderStatus,
      courierSystemOrderId: selectedOrder.courierSystemOrderId,
      orderIsConfirmed: selectedOrder.orderIsConfirmed,
      orderIsReady: selectedOrder.orderIsReady,
      orderProcessedAt: selectedOrder.orderProcessedAt,
      merchantId: selectedOrder.merchant?.merchant_id
    });
  }, []);

  return (
    <Modal
      title="Change Order Status"
      destroyOnClose
      open={status}
      getContainer={false}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ loading: changeOrderStatusLoading }}
      onCancel={() => {
        setStatus(false);
      }}
    >
      {orderStatusesLoading ? (
        <Spin size="large" />
      ) : orderStatusesError ? (
        <div>{orderStatusesError.message}</div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => onChangeOrderStatus(values)}
        >
          <Form.Item
            name="statusId"
            rules={[
              {
                required: true,
                message: "Please select order status!",
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                {orderStatusesData?.map((orderStatus) => (
                  <Radio
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    key={orderStatus.statusId}
                    value={orderStatus.statusId}
                    style={{ display: "flex" }}
                  >
                    {orderStatus.title}
                    {orderStatus.statusId === 4 && value === 4 && (
                      <Form.Item
                        name="preparationTime"
                        rules={[
                          {
                            required: orderStatus.statusId === 4 && value === 4,
                            message: "Please enter preparation time!",
                          },
                        ]}
                      >
                        <Input type="number" placeholder="Preparation Time"  step={5}/>
                      </Form.Item>
                    )}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default ChangeOrderStatusModalComponent;
