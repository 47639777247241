import React from 'react';

const TaskIcon = ({ width = 10, height = 10 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 11" fill="none">
      <path
        d="M8 6.82422H4.25C4.045 6.82422 3.875 6.65422 3.875 6.44922C3.875 6.24422 4.045 6.07422 4.25 6.07422H8C8.205 6.07422 8.375 6.24422 8.375 6.44922C8.375 6.65422 8.205 6.82422 8 6.82422Z"
        fill="#919191"
      />
      <path
        d="M8 4.22266H4.25C4.045 4.22266 3.875 4.05266 3.875 3.84766C3.875 3.64266 4.045 3.47266 4.25 3.47266H8C8.205 3.47266 8.375 3.64266 8.375 3.84766C8.375 4.05266 8.205 4.22266 8 4.22266Z"
        fill="#919191"
      />
      <path
        d="M2.44479 4.63826C2.34979 4.63826 2.25979 4.60326 2.18979 4.53826L1.74479 4.12326C1.59479 3.98326 1.58479 3.74326 1.72979 3.59326C1.86979 3.44326 2.10979 3.43326 2.25979 3.57826L2.42979 3.73326L2.92979 3.19326C3.06979 3.04326 3.30979 3.03326 3.45979 3.17326C3.60979 3.31326 3.61979 3.55326 3.47979 3.70326L2.72479 4.51826C2.65479 4.59326 2.56479 4.63326 2.46479 4.63826H2.44979H2.44479Z"
        fill="#919191"
      />
      <path
        d="M2.44479 7.24373C2.34979 7.24373 2.25979 7.20873 2.18979 7.14373L1.74479 6.72873C1.59479 6.58873 1.58479 6.34873 1.72979 6.19873C1.86979 6.04873 2.10979 6.03873 2.25979 6.18373L2.42979 6.33873L2.92979 5.79873C3.06979 5.64873 3.30979 5.63873 3.45979 5.77873C3.60979 5.91873 3.61979 6.15873 3.47979 6.30873L2.72479 7.12373C2.65479 7.19873 2.56479 7.23873 2.46479 7.24373H2.44979H2.44479Z"
        fill="#919191"
      />
      <path
        d="M8.5 0.908203C8.915 0.908203 9.25 1.2432 9.25 1.6582V8.6582C9.25 9.0732 8.915 9.4082 8.5 9.4082H1.5C1.085 9.4082 0.75 9.0732 0.75 8.6582V1.6582C0.75 1.2432 1.085 0.908203 1.5 0.908203H8.5ZM8.5 0.158203H1.5C0.67 0.158203 0 0.828203 0 1.6582V8.6582C0 9.4882 0.67 10.1582 1.5 10.1582H8.5C9.33 10.1582 10 9.4882 10 8.6582V1.6582C10 0.828203 9.33 0.158203 8.5 0.158203Z"
        fill="#919191"
      />
    </svg>
  );
};

export default TaskIcon;
