import { Button, Card, Col, Form, Input, Row, message } from 'antd';
import React from 'react';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import { useAxiosPostTaskManagement , useAxiosGetTaskManagement} from '../../configs/axios';

const CreateTicketComponent = ({
  issue_source_type,
  issue_source_id,
  issue_type,
  hideTitle,
}) => {
  const { request: requestCreateTicket } = useAxiosPostTaskManagement(
    '/OperationTasks',
    {
      autoRun: false,
    },
  );

  const onFinish = async (values) => {
    try {
      const body = {
        issue_id: values.issue_id.value,
        issue_source_type: issue_source_type,
        issue_source_id: issue_source_id,
        task_comment: values.task_comment,
        created_by_source: 'agent',
      };

      await requestCreateTicket(body);
      message.success('operation successful');
    } catch (err) {
      console.log('🚀 ~ onFinish ~ err:', err);
      message.error('something went wrong');
    }
  };
  return (
    <Card>
      <Form
        layout="vertical"
        style={{
          width: '100%',
        }}
        onFinish={onFinish}
      >
        <Row gutter={[10, 0]}>
          {hideTitle ? null : (
            <Col
              span={24}
              style={{
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Create A Ticket
              </span>
            </Col>
          )}
          <Col span={24}>
            <Form.Item>
              <RemoteSelectForm
                endpoint={`/OperationTasks/issueList?issue_type=${issue_type}`}
                labelForm={'Issue'}
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                style={{
                  width: '100%',
                }}
                valueOption={'task_issue_id'}
                labelOption={'task_issue_name'}
                nameForm="issue_id"
                allowClear={true}
                placeholder={'Select a issue'}
                query={{
                  issueType: issue_type,
                }}
                fetchAxios={useAxiosGetTaskManagement}
              />
            </Form.Item>
          </Col>
          <Col
            span={18}
            style={{
              marginTop: -30,
            }}
          >
            <Form.Item
              name={'task_comment'}
              label="Comment"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button
              style={{
                width: '100%',
                backgroundColor: '#4BB543',
              }}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateTicketComponent;
