/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, createContext, useRef } from 'react';
import { Row, Col } from 'antd';
import StatusesSelectionComponent from '../components/home/StatusesSelectionComponent';
import OrdersTableComponent from '../components/home/OrdersTableComponent';
import ChangeOrderStatusModalComponent from '../components/home/ChangeOrderStatusModalComponent';
import OrderMapModalComponent from '../components/home/OrderMapModalComponent';
import OrderCartModalComponent from '../components/home/OrderCartModalComponent';
import CustomerInformationModal from '../components/home/CustomerInformationModal';
import MerchantInformationModal from '../components/home/MerchantInformationModal';
import DriverInformationModal from '../components/home/DriverInformationModal';
import EditOrderModal from '../components/home/EditOrderModal';
import OrderAmountDetailsComponent from '../components/home/OrderAmountDetailsComponent';
import OrderRefundModal from '../components/home/OrderRefundModal';
import { useAxiosGet } from '../configs/axios';
import OrderFilterInputsComponent from '../components/home/OrderFilterInputsComponent';
import useLocalStorage from '../configs/localStorage';
import OrderEvaluationModalComponent from '../components/home/OrderEvaluationModalComponent';
import ChatModalComponent from '../components/home/ChatModalComponent';
import ShowInvoiceOrder from '../components/home/ShowInvoiceOrder';

export const OrderContext = createContext();
// import CustomerChatModalComponent from "../comp onents/home/CustomerChatModalComponent";
const Home = () => {
  const [auth] = useLocalStorage('auth', null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);

  const [changeOrderStatusModalVisible, setChangeOrderStatusModalVisible] =
    useState(false);
  const [orderMapModalVisible, setOrderMapModalVisible] = useState(false);
  const [orderCartModalVisible, setOrderCartModalVisible] = useState(false);
  const [customerInformationModalVisible, setCustomerInformationModalVisible] =
    useState(false);
  const [merchantInformationModalVisible, setMerchantInformationModalVisible] =
    useState(false);
  const [driverInformationModalVisible, setDriverInformationModalVisible] =
    useState(false);
  const [editOrderModalVisible, setEditOrderModalVisible] = useState(false);
  const [orderAmountDetailsModalVisible, setOrderAmountDetailsModalVisible] =
    useState(false);
  const [orderRefundModalVisible, setOrderRefundModalVisible] = useState(false);
  const [orderEvaluationModalVisible, setOrderEvaluationModalVisible] =
    useState(false);
  const [ordersSearchFilters, setOrdersSearchFilters] = useState({
    orderStatus: 'pending',
    customerId: undefined,
    merchantId: undefined,
    date: undefined,
    city: undefined,
    orderId: undefined,
    driverId: undefined,
    agentId: undefined,
  });
  const [chatSectionOpen, setChatSectionOpen] = useState(false);
  // const [customerChatVisible, setCustomerChatVisible] = useState(false);
  const intervalRef = useRef(0);

  const {
    data: ordersData,
    loading: ordersLoading,
    error: ordersError,
    request: getAllOrders,
  } = useAxiosGet('/orders', {
    orderStatus: 'pending',
  });

  if (!auth) {
    return location.replace('/login');
  }

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (!orderCartModalVisible) {
        getAllOrders(ordersSearchFilters);
      }
      // }, 10000);
    }, 10000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [ordersSearchFilters, orderCartModalVisible]);

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <>
      <OrderContext.Provider
        value={{
          getAllOrders,
          ordersData,
          ordersError,
          ordersLoading,
          selectedOrder,
          setSelectedOrder,
          ordersSearchFilters,
          setOrdersSearchFilters,
        }}
      >
        <div>
          <Row
            gutter={[20,20]}
          >
            <Col
              span={24}
            >
              <StatusesSelectionComponent />
            </Col>
            <Col span={24}>
              <OrderFilterInputsComponent />
            </Col>
            <Col
              span={24} 
            >
              <OrdersTableComponent
                openOrderStatusChangeModal={(order) => {
                  setSelectedOrder(order);
                  setChangeOrderStatusModalVisible(true);
                }}
                openOrderMapModal={(order) => {
                  setSelectedOrder(order);
                  setOrderMapModalVisible(true);
                }}
                openOrderCartModal={(order) => {
                  setSelectedOrder(order);
                  setOrderCartModalVisible(true);
                }}
                openCustomerInformationModal={(order) => {
                  setCustomerInformationModalVisible(true);
                  setSelectedOrder(order);
                }}
                openMerchantInformationModal={(order) => {
                  setMerchantInformationModalVisible(true);
                  setSelectedOrder(order);
                }}
                openDriverInformationModal={(order) => {
                  setDriverInformationModalVisible(true);
                  setSelectedOrder(order);
                }}
                openEditOrderModal={(order) => {
                  setSelectedOrder(order);
                  setEditOrderModalVisible(true);
                }}
                openOrderAmountDetailsModal={(order) => {
                  setSelectedOrder(order);
                  setOrderAmountDetailsModalVisible(true);
                }}
                openOrderRefundModal={(order) => {
                  setSelectedOrder(order);
                  setOrderRefundModalVisible(true);
                }}
                openOrderEvaluationModal={(order) => {
                  setSelectedOrder(order);
                  setOrderEvaluationModalVisible(true);
                }}
                openOrderChatModal={(order) => {
                  setSelectedOrder(order);
                  setChatSectionOpen(true);
                }}
                setInvoiceModalVisible={(order) => {
                  setSelectedOrder(order);
                  setInvoiceModalVisible(true);
                }}
                openCustomerChatModal={(order) => {
                  setSelectedOrder(order);
                  // setCustomerChatVisible(true);
                }}
              />
            </Col>

            {/* --- Modals --- */}
            {orderCartModalVisible && (
              <OrderCartModalComponent
                status={orderCartModalVisible}
                setStatus={setOrderCartModalVisible}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            )}
            {changeOrderStatusModalVisible && (
              <ChangeOrderStatusModalComponent
                status={changeOrderStatusModalVisible}
                setStatus={setChangeOrderStatusModalVisible}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            )}
            {orderMapModalVisible && (
              <OrderMapModalComponent
                status={orderMapModalVisible}
                setStatus={setOrderMapModalVisible}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
                selectedOrder={selectedOrder}
              />
            )}
            {customerInformationModalVisible && (
              <CustomerInformationModal
                status={customerInformationModalVisible}
                setStatus={setCustomerInformationModalVisible}
              />
            )}
            {merchantInformationModalVisible && (
              <MerchantInformationModal
                status={merchantInformationModalVisible}
                setStatus={setMerchantInformationModalVisible}
                selectedOrder={selectedOrder}
              />
            )}
            {driverInformationModalVisible && (
              <DriverInformationModal
                status={driverInformationModalVisible}
                setStatus={setDriverInformationModalVisible}
              />
            )}
            {editOrderModalVisible && (
              <EditOrderModal
                status={editOrderModalVisible}
                setStatus={setEditOrderModalVisible}
                selectedOrder={selectedOrder}
                getAllOrders={getAllOrders}
                ordersSearchFilters={ordersSearchFilters}
              />
            )}
            {orderAmountDetailsModalVisible && (
              <OrderAmountDetailsComponent
                status={orderAmountDetailsModalVisible}
                setStatus={setOrderAmountDetailsModalVisible}
              />
            )}
            {orderRefundModalVisible && (
              <OrderRefundModal
                status={orderRefundModalVisible}
                setStatus={setOrderRefundModalVisible}
                selectedOrder={selectedOrder}
              />
            )}
            {orderEvaluationModalVisible && (
              <OrderEvaluationModalComponent
                status={orderEvaluationModalVisible}
                selectedOrder={selectedOrder}
                setStatus={setOrderEvaluationModalVisible}
              />
            )}
            {chatSectionOpen && (
              <ChatModalComponent
                selectedOrder={selectedOrder}
                status={chatSectionOpen}
                setStatus={setChatSectionOpen}
              />
            )}
            {invoiceModalVisible && (
              <ShowInvoiceOrder
                visible={invoiceModalVisible}
                setVisible={setInvoiceModalVisible}
                selectedOrder={selectedOrder}
              />
            )}
            {/* {customerChatVisible && (
              <CustomerChatModalComponent
                status={customerChatVisible}
                setStatus={setCustomerChatVisible}
                order={selectedOrder}
              />
            )} */}
          </Row>
        </div>
      </OrderContext.Provider>
    </>
  );
};

export default Home;
