import React, { memo, useContext, useRef, useState } from 'react';
import { Avatar, Typography, Badge, Tag, Row, Col, List, ConfigProvider, Tooltip } from 'antd';
import {
  ClockCircleOutlined,
  UserOutlined,
  TeamOutlined,
  MessageOutlined,
  TagOutlined,
  CustomerServiceOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  chatRoomItemStyle,
  chatRoomItemSelectedStyle,
  darkChatRoomItemStyle,
  darkChatRoomItemSelectedStyle,
  lightColors,
  darkColors,
} from './ChatRoomListItemStyles';
import _ from 'lodash';
const { Text } = Typography;

const getTime = (time) => {
  const timeDiff = moment().diff(moment(time), 'seconds');
  // now, 10sec, 1min, 1hr, 1day, 1week, 1month, 1year
  //short text
  const timeDiffText =
    timeDiff < 10
      ? 'now'
      : timeDiff < 60
      ? `${timeDiff}s`
      : timeDiff < 3600
      ? `${Math.floor(timeDiff / 60)}m`
      : timeDiff < 86400
      ? `${Math.floor(timeDiff / 3600)}h`
      : timeDiff < 604800
      ? `${Math.floor(timeDiff / 86400)}d`
      : timeDiff < 2592000
      ? `${Math.floor(timeDiff / 604800)}w`
      : timeDiff < 31536000
      ? `${Math.floor(timeDiff / 2592000)}mo`
      : `${Math.floor(timeDiff / 31536000)}y`;
  return timeDiffText;
};
const getchatId = (roomType, refId) => {
  const color = roomType === 'customer_general' ? '#DE9494' : '#868AAD';
  const text = roomType === 'customer_general' ? 'Help' : refId;
  return {
    color: color,
    text: text,
  };
};

const getTags = (
  roomType,
  roomAssignedToName,
  roomLanguage,
  issueTags,
  refId,
) => {
  const tags = [];
  if (issueTags && issueTags?.length > 0) {
    const length = issueTags?.length || 0;
    //return max 3, the rest +length
    issueTags.slice(0, 3).forEach((tag) => {
      tags.push({
        color: tag.color,
        text: tag.name,
      });
    });
  }
  return tags;
};

const getRecepient = (recepient) => {
  if (recepient === 'customer') {
    return {
      color: '#40425F',
      text: 'C',
    };
  }
  if (recepient === 'agent') {
    return {
      color: '#868AAD',
      text: 'A',
    };
  } else {
    return {
      color: 'black',
      text: 'N/A',
    };
  }
};

const getLanguageColor = (language) => {
  switch (language) {
    case 'kr':
      return { bg: '#FDD7D5', txt: '#D17070' };
    case 'bd':
      return { bg: '#FDF4D5', txt: '#B1A748' };
    case 'ar':
      return { bg: '#EED5FD', txt: '#9A70D1' };
    default:
      return { bg: '#D5DDFD', txt: '#7091D1' };
  }
};

const getLanguageName = (language) => {
  switch (language) {
    case 'kr':
      return 'Sorani';
    case 'bd':
      return 'Badini';
    case 'ar':
      return 'Arabic';
    default:
      return 'English';
  }
};

const ChatRoomListItem = memo(
  ({ item, onSelect, isSelected }) => {
    //a pure component that renders a chat room list item with row and column layout of Ant Design components
    // the component receives everything as props to not have any logic inside the component

    const renderCount = useRef(0); // Use useRef for persistent count

    const {theme} = useContext(ConfigProvider.ConfigContext);

    const isDarkMode = theme.algorithm === 'dark';


   

    const RenderChatRoom = ({
      avatar,
      title,
      subtitle,
      time,
      agentTime,
      count,
      message,
      refId,
      type,
      onSelect,
      isSelected,
      roomType,
      roomAssignedToName,
      roomLanguage,
      issueTags,
      recepient,
    }) => {
      const timeDiffText = getTime(time);
      const timeAgentDiffText = agentTime?getTime(agentTime):undefined;
      const tags = getTags(
        roomType,
        roomAssignedToName,
        roomLanguage,
        issueTags,
        refId,
      );

      const roomId = getchatId(roomType, refId);

      const light = {
        container: chatRoomItemStyle,
        selectedContainer: chatRoomItemSelectedStyle,
        colors: lightColors,

      };

      const dark = {
        container: darkChatRoomItemStyle,
        selectedContainer: darkChatRoomItemSelectedStyle,
        colors: darkColors,

      };

      const style = isDarkMode ? dark : light;

      renderCount.current++; // Increment the count on each render
      return (
        <div
          style={isSelected ? style.selectedContainer : style.container}
          onClick={() => onSelect()}
        >
          <div
            style={{
              position: 'absolute',
              top: '-10px',
              left: 15,
              zIndex: 9,
            }}
          >
            <Tag
              color={roomId.color}
              icon={
                roomType === 'customer_general' ? (
                  <CustomerServiceOutlined />
                ) : (
                  <TagOutlined />
                )
              }
            >
              {' '}
              {roomId.text}
            </Tag>

            <Tag
              color="#E3E5F5"
              style={{
                color: '#8085A4',
              }}
              icon={<TeamOutlined />}
            >
              {' '}
              {roomAssignedToName}
            </Tag>
            {!agentTime ? <Tag
            color='red'>Not Answered</Tag> : null}
          </div>
          {count > 0 ? (
            <div
              style={{
                position: 'absolute',
                top: '1px',
                right: '1px',
                color: 'white',
              }}
            >
              <Badge
                color="#868AAD"
                style={{
                  borderRadius: '20%',
                }}
                count={count}
              />
            </div>
          ) : null}
          <Row
            style={{
              marginBottom: '5px',
              borderBottom: '1px solid ' + style.colors.separator,
              fontFamily: 'Roboto, sans-serif', // Example of a modern font
            }}
          >
            <Col span={3} xl={3} xs={12}>
              <Avatar
                src={avatar}
                style={{ border: '1px solid #d9d9d9', borderRadius: '15px' }} // Changes to avatar
                size={40}
              />
            </Col>
            <Col xs={0} xl={18} span={18}>
              <div
                style={{
                  marginLeft: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    color: style.colors.title,
                  }}
                  strong
                >
                  {title}
                 { timeDiffText? <Tooltip title='Last Message time'>
                   {' - '+timeDiffText}
                  </Tooltip>:null}
                </Text>
                <Text
                  type="secondary"
                  style={{ fontSize: '14px', color: style.colors.content }}
                >
                  {type == 'customer_general' ? `C_ID: ${refId}` : subtitle}
                  {roomLanguage ? ' ' : ''}
                  {roomLanguage ? (
                    <Tag
                      style={{
                        color: getLanguageColor(roomLanguage).txt,
                      }}
                      color={getLanguageColor(roomLanguage).bg}
                    >
                      {getLanguageName(roomLanguage)}
                    </Tag>
                  ) : null}
                </Text>
              </div>
            </Col>
            <Col xs={12} xl={3} span={2}>
              <div>
                <Text
                  style={{
                    color: style.colors.content,
                  }}
                >
                  <Tooltip
                  title="Last agent message time"
                  >

                  {timeAgentDiffText}
                  </Tooltip>
                </Text>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              borderBottom: '1px solid ' + style.colors.separator,
              paddingBottom: '5px',
            }}
          >
            {tags?.slice(0, 3).map((tag, index) => (
              <Tag key={index} color={tag.color}>
                {tag.text}
              </Tag>
            ))}
            {tags?.length > 3 ? (
              <Tag color="blue">{tags.length - 3}</Tag>
            ) : null}
          </Row>
          <Row style={{ paddingRight: '5px' }}>
            <Tag
              color={getRecepient(recepient).color}
              style={{
                flex: 1,
              }}
              icon={<MessageOutlined />}
            >
              {getRecepient(recepient).text}
            </Tag>
            <Text
              style={{
                flex: 35,
                color: count > 0 ? getRecepient(recepient).color : style.colors.content,
                fontWeight:
                  count > 0 && recepient == 'customer' ? 'bold' : 'normal',
              }}
              numberoflines={1}
              ellipsis={true}
            >
              {message}
            </Text>
          </Row>
        </div>
      );

      //style
    };

    return (
      <RenderChatRoom
        key={item?._id}
        avatar={item.chatRoomImage}
        title={item.roomName}
        subtitle={item.roomSubtitle}
        time={item.lastMessageTime}
        agentTime={item.agentLastMessageAt}
        count={item.countMessages}
        message={item.lastMessage}
        refId={item.roomRef}
        type={item.roomType}
        onSelect={onSelect}
        isSelected={isSelected}
        roomType={item.roomType}
        roomAssignedToName={item.roomAssignedToName}
        roomLanguage={item.roomLanguage}
        issueTags={item.issueTags}
        recepient={item.lastMessageSource}
      />
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps?.isSelected === nextProps?.isSelected &&
      _.isEqual(prevProps?.item, nextProps?.item)
    );
  },
);

export default ChatRoomListItem;
