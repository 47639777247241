import { useRef, useState } from 'react';
import useLocalStorage from '../../configs/localStorage';
import { Drawer, Input, Tabs, Typography } from 'antd';
import { initSocket } from '../../store/socket/socketGetter';
import ChatComponentV2 from './ChatComponentV2';
import ChatScript from './ChatScript';

const { Search } = Input;
const format = 'hh:mm A';
const { Text } = Typography;
function DrawerChat({ height, roomRef, roomType, roomName, onClose ,doNotKillSocket}) {
  const [selectedTab, setSelectedTab] = useState('1');
  const [auth] = useLocalStorage('auth', null);
  const searchRef = useRef(null);
  const socketRef = useRef(initSocket(auth));
  
  function onCloseDrawer() {
    
    if(!doNotKillSocket){
      socketRef.current?.disconnect();
      socketRef.current = null;
      
    }
    
    
    onClose();
  }
  const tabs = [
    {
      label: `Chat`,
      key: '1',
      children: (
        <ChatComponentV2
          ref={searchRef}
          height={window.innerHeight - 150}
          roomRef={roomRef}
          roomType={roomType}
          roomName={roomName}
          onClose={onClose}
          socketRef={socketRef}
          onCloseDrawer={onCloseDrawer}
        />
      ),
    },
    {
      key: '2',
      label: 'Info',
      children: (
        <ChatScript
          ref={searchRef}
          searchRef={searchRef}
          setSelectedTab={setSelectedTab}
          source={roomType}
        />
      ),
    },
  ];

  return (
    <Drawer
      title="Chat"
      placement="right"
      closable={false}
      width={650}
      open={true}
      onClose={onCloseDrawer}
    >
      <Tabs
        onChange={(key) => setSelectedTab(key)}
        activeKey={selectedTab}
        defaultActiveKey="1"
        style={{
          width: '100%',
        }}
        items={tabs}
      ></Tabs>
    </Drawer>
  );
}

export default DrawerChat;
