import { Button, Image, Tooltip } from 'antd';
import classes from './style/task-board.module.css';
import Phone from './icons/Phone';
import dayjs from 'dayjs';
import ClockIcon from './icons/ClockIcon';
import VipIcon from './icons/VipIcon';
import Chat from './icons/Chat';
var updateLocale = require('dayjs/plugin/updateLocale');

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1m',
    m: '1m',
    mm: '%dMs',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
});
const RenderGroupedTasks = ({
  task,
  onOpenSubTaskModal,
  copyToClipboard,
  onOpenDrawerChat,
  statusText,
  statusCode,
}) => {
  return (
    <dev
      className={classes.expandableHeader}
      onClick={() =>
        onOpenSubTaskModal(task.tasks, {
          nextStatusText: statusText,
          currentTask: statusCode,
        })
      }
    >
      <div className={classes.expandableButtonContainer}>
        <div className={classes.idsContainer}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(task.group_id);
            }}
            className={classes.id}
          >
            M#{task.group_id}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(task.group_id);
            }}
            className={classes.tickets}
          >
            {task.tasks?.length} tickets
          </div>
        </div>
        <div className={classes.timerAndCounterDev}>
          <div className={classes.lateTime}>
            <ClockIcon />
            {dayjs().from(dayjs(task.created_at), true)}
          </div>
        </div>
      </div>

      <div>
        <div className={`${classes.merchantContainer} ${classes.hasChat}`}>
          <div className={classes.nameAndIcon}>
            {/* <Merchant /> */}
            {/* <Image
              src={task?.merchant?.source_image}
              alt="Merchant"
              width={35}
              className={classes.imageStyle}
            /> */}
            <div className={classes.merchantTextContainer}>
              <div
                className={`${classes.row} ${classes.merchantNamecontainer}`}
              >
                <VipIcon width={25} height={20} />

                <span
                  className={classes.merchantName}
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard('355');
                  }}
                >
                  <Image
                    src={task?.group_image}
                    alt="Merchant"
                    width={35}
                    className={classes.imageStyle}
                  />
                  <span
                    className={classes.merchantName}
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(task.merchant.source_id);
                    }}
                  >
                    {task?.group_name}{' '}
                  </span>
                </span>
              </div>
              <span className={classes.merchantAccountManager}>
                AM:{task?.source_subtitle}
              </span>
            </div>
          </div>
          <div>
            <Button
              type="ghost"
              icon={<Chat fill={'#919191'} />}
              onClick={(e) => {
                e.stopPropagation();
                onOpenDrawerChat(task, 'merchant');
              }}
            />
            <Tooltip placement="top" title={task.merchant?.source_phone}>
              <Button
                type="ghost"
                icon={<Phone fill={'#919191'} />}
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(task.merchant?.source_phone);
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={classes.expandableButtonContainer}>
        <h4>
          {task.group_city}{' '}
        </h4>
        <Button
          type="ghost"
          icon={<Phone />}
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(task.phone);
          }}
        />
      </div>
    </dev>
  );
};

export default RenderGroupedTasks;
