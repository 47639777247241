import { Avatar, Modal, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import CreateTicketComponent from '../home/CreateTicketComponent';
const DriverInformationModal = ({driver ,open, onCloseDriverInfo}) => {
  return (
    <Modal
      title="Driver Information"
      destroyOnClose
      getContainer={false}
      open={open}
      footer={null}
      onClose={onCloseDriverInfo}
      onCancel={onCloseDriverInfo}
      centered
      width={600}
      height={600}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Avatar size={90} icon={<UserOutlined />} />
        <h2
          style={{
            textAlign: 'center',
          }}
        >
          {driver?.source_name}

        </h2>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <b>Phone Number</b>
            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(driver?.source_phone);
                message.success('Copied to clipboard');
              }}
            >
              {driver?.source_phone}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <b>City</b>
            <p>{driver?.source_city}</p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
              marginBottom:10,
            }}
          >


          </div>
        </div>
        <CreateTicketComponent
          issue_source_type={'driver'}
          issue_source_id={driver?.source_id}
          issue_type="driver"
          />
      </div>
    </Modal>
  );
};

export default DriverInformationModal;
