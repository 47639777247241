/* eslint-disable react/no-array-index-key */
import {
  Button,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Popconfirm,
  Tag,
  Tooltip,
  Image,
} from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  UilPlus,
  UilMinus,
  UilMultiply,
  UilX,
  UilRedo,
} from '@iconscout/react-unicons';
import { useAxiosGet, useAxiosPut } from '../../../configs/axios';
import AddNewItemsModalComponent from './AddNewItemsModalComponent';
import { CopyOutlined } from '@ant-design/icons';
import CreateTicketForProduct from './CreateTicketForProduct';

const OrderCartModalComponent = (props) => {
  const {
    status,
    setStatus,
    selectedOrder,
    getAllOrders,
    ordersSearchFilters,
  } = props;

  const [selectedOrderReplica, setSelectedOrderReplica] = useState(null);
  const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(false);
  const {
    data: allProducts,
    error: allProductsError,
    request: requestAllProducts,
    setData: setDataAllProducts,
    loading: allProductsLoading,
  } = useAxiosGet('/products/groupedByCategory', {
    autoRun: true,
    merchantId: selectedOrder.merchant.merchant_id,
    customerId: selectedOrder.customer.customer_id,
    pageSize: 25,
  });

  const { loading: updateCartLoading, request: updateCartRequest } =
    useAxiosPut('/orders/status');

  const onEditCartQuantity = (index, type) => {
    const newItems = selectedOrderReplica.cart[index];

    if (!newItems) return;
    if (type === 'decrement' && newItems.quantity === 1) return;
    newItems.quantity =
      type === 'increment' ? newItems.quantity + 1 : newItems.quantity - 1;
    setSelectedOrderReplica({
      ...selectedOrderReplica,
      cart: selectedOrderReplica.cart.map((cartItem, i) =>
        index === i ? newItems : cartItem,
      ),
      altered: true,
    });
  };

  const onToggleCart = (index, type) => {
    const newItems = selectedOrderReplica.cart[index];

    if (!newItems) return;
    newItems.quantity = type === 'remove' ? 0 : 1;
    setSelectedOrderReplica({
      ...selectedOrderReplica,
      cart: selectedOrderReplica.cart.map((cartItem, i) =>
        index === i ? newItems : cartItem,
      ),
      altered: true,
    });
  };
  const { data: productLinks, request: requestProductLink } = useAxiosGet(
    'products/getTags',
    { autoRun: false },
    {},
  );

  const onUpdateCart = async () => {
    try {
      const requestCarts = selectedOrderReplica.cart.filter(
        (cart) => cart.quantity > 0,
      );
      await updateCartRequest({
        api_version: 'react_1',
        statusId: 24,
        orderId: selectedOrderReplica.orderId,
        orderCart: requestCarts,
      });

      message.success('Cart updated successfully');
      setSelectedOrderReplica(null);
      setStatus(false);
      await getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  const onResolveMerchantComment = async () => {
    try {
      updateCartRequest({
        statusId: 30,
        orderId: selectedOrderReplica.orderId,
      });

      message.success('Comment resolved successfully');
      setSelectedOrderReplica(null);
      setStatus(false);
      await getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  useEffect(() => {
    const newSelectedOrderReplica = _.cloneDeep(selectedOrder);
    setSelectedOrderReplica({ ...newSelectedOrderReplica, altered: false });
  }, []);
  useEffect(() => {
    ////  4003 is dubai food court and 4002 is lezzoo dubai mall
    const merhcantId = selectedOrder?.merchant?.merchant_id;
    if (merhcantId == 4002 || merhcantId == 4003) {
      const productIds = selectedOrderReplica?.cart?.map((el) => el.product_id);
      if (productIds?.length > 0) {
        requestProductLink({
          productIds: `${productIds}`,
        });
      }
    }
  }, [selectedOrderReplica?.cart?.length]);

  const renderMerchantProductComment = (item) => {
    if (item.product_merchant_comment?.length > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            fontSize: '12px',
          }}
        >
          {item.product_merchant_comment.map((el, index) => (
            <p
              key={index}
              style={{
                textTransform: 'capitalize',
                color: '#c0392b',
              }}
            >
              {el.merchant_order_comment_code.split('_').join(' ')}
            </p>
          ))}
          {selectedOrderReplica.orderMerchantCommentResolved ? (
            <p
              style={{
                color: '#27ae60',
              }}
            >
              Resolved
            </p>
          ) : (
            <Popconfirm
              title="Are you sure?"
              onConfirm={onResolveMerchantComment}
            >
              <a href="">Resolve</a>
            </Popconfirm>
          )}
        </div>
      );
    }
    if (item.quantity_status === false) {
      return <Tag color="red">Item is unavailable</Tag>;
    }
    if (item.quantity_status === true) {
      return <Tag color="green">Item is available</Tag>;
    }

    return <div>Merchant has no comments</div>;
  };

  return (
    <Modal
      title="Order Cart"
      destroyOnClose
      open={status}
      getContainer={false}
      onOk={onUpdateCart}
      okText="Update Cart"
      okButtonProps={{
        loading: updateCartLoading,
      }}
      onCancel={() => {
        setStatus(false);
      }}
      centered
      width="100%"
    >
      {allProductsError ? (
        <Empty description="Error loading products" />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              gap: '30px',
              height: '70vh',
            }}
          >
            <div
              style={{
                width: '80%',
                height: '100%',
                overflowY: 'scroll',
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '20px',
                paddingInline: '20px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: '4%',
                }}
              >
                <Input
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    borderRadius: '3px',
                  }}
                  type="text"
                  readOnly
                  placeholder="Order Instruction"
                  value={selectedOrderReplica?.orderComment}
                />
                <Input
                  style={{ flex: 1, borderRadius: '3px' }}
                  type="text"
                  readOnly
                  // disabled
                  placeholder="Order Delivery Instruction"
                  value={selectedOrderReplica?.orderDeliveryInstruction}
                />
              </div>

              {selectedOrderReplica?.cart.length > 0 ? (
                selectedOrderReplica?.cart.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      gap: '20px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        // backgroundColor: '#e7eff7',
                        borderRadius: '10px',
                        gap: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: '130px',
                        paddingInline: '20px',
                        position: 'relative',
                      }}
                    >
                      {item.quantity === 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <UilX size="90" color="#c0392b" />
                        </div>
                      )}

                      <Image
                        src={item.product_image}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Tooltip
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <span>{item.name.ar}</span>
                              <span>{item.name.kr}</span>
                              <span>{item.name.ba}</span>
                            </div>
                          }
                        >
                          <p
                            style={{
                              textDecoration: item.replacement_product
                                ? 'line-through'
                                : 'none',
                            }}
                          >
                            {item.name.en}
                          </p>
                        </Tooltip>

                        {item.replacement_product && (
                          <Tooltip title="Replacement Product">
                            <strong>Replacement: </strong>
                            {item.replacement_product.product_id}
                            {item.replacement_product?.name?.en ||
                              item.replacement_product?.text?.en ||
                              item.replacement_product
                                ?.merchant_order_comment_text ||
                              ''}
                          </Tooltip>
                        )}
                      </div>
                      <div>
                        {renderMerchantProductComment(item)}
                        {productLinks?.[item.product_id] && (
                          <Tooltip
                            title={
                              <div>
                                <span>{productLinks[item.product_id]}</span>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      productLinks[item.product_id],
                                    );
                                    message.success('Copied to clipboard');
                                  }}
                                >
                                  <CopyOutlined />
                                </Button>
                              </div>
                            }
                          >
                            <Tag
                              color="blue"
                              style={{
                                maxWidth: '150px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              <a
                                onClick={() => {
                                  if (
                                    productLinks[item.product_id].includes(
                                      'http',
                                    )
                                  ) {
                                    window.open(
                                      productLinks[item.product_id],
                                      '_blank',
                                    );
                                  }
                                }}
                                style={{
                                  color: ' #0958d9',
                                }}
                              >
                                {productLinks[item.product_id]}
                              </a>
                            </Tag>
                          </Tooltip>
                        )}
                      </div>
                      <ul
                        style={{
                          width: '200px',
                          fontSize: '12px',
                          padding: 0,
                        }}
                      >
                        {item.addons.length > 0 ? (
                          item.addons.map((addon, index2) => (
                            <li
                              key={index2}
                              style={{
                                marginBottom: '5px',
                              }}
                            >
                              <b>{addon.name.en}</b>
                              {` - ${addon.value.name.en} / `}
                              <b>{`${addon.value.price || 0} IQD`}</b>
                            </li>
                          ))
                        ) : (
                          <div
                            style={{
                              fontSize: '14px',
                              transform: 'translateY(5px)',
                            }}
                          >
                            No addons
                          </div>
                        )}
                      </ul>
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <span
                          style={{
                            textDecoration: item.replacement_product
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {Number(
                            item.product_discount || item.product_price,
                          )?.toLocaleString('en-US')}{' '}
                          IQD
                        </span>

                        {item.replacement_product && (
                          <p
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <strong>Replacement: </strong>
                            {Number(
                              item.replacement_product.price,
                            )?.toLocaleString('en-US')}{' '}
                            IQD
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p
                          style={{
                            transform: 'translateY(5px)',
                          }}
                        >
                          {item.quantity}
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px',
                          }}
                        >
                          <Button
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            icon={<UilPlus size="16" />}
                            onClick={() =>
                              onEditCartQuantity(index, 'increment')
                            }
                            type="primary"
                            shape="circle"
                            size="small"
                          />
                          <Button
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() =>
                              onEditCartQuantity(index, 'decrement')
                            }
                            size="small"
                            icon={<UilMinus size="16" />}
                            type="primary"
                            shape="circle"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          onClick={() => {
                            setSelectedProductId(item.product_id);
                          }}
                        >
                          Product Issue{' '}
                        </Button>
                        <p
                          style={{
                            textAlign: 'center',
                            textDecoration: item.product_discount
                              ? 'line-through'
                              : '',
                            color: item.product_discount ? 'red' : 'inherit',
                          }}
                        >
                          {Number(
                            (item.single_product_subtotal_original
                              ? item.single_product_subtotal_original
                              : item.product_price) * item.quantity,
                          )?.toLocaleString('en-US')}{' '}
                          IQD
                        </p>

                        {item.product_discount ? (
                          <span>
                            {item.product_discount * item.quantity} IQD
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <Button
                      type={item.quantity === 0 ? 'primary' : 'danger'}
                      shape="circle"
                      size="small"
                      onClick={() =>
                        item.quantity === 0
                          ? onToggleCart(index, 'add')
                          : onToggleCart(index, 'remove')
                      }
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      icon={
                        item.quantity === 0 ? (
                          <UilRedo size="16" />
                        ) : (
                          <UilMultiply size="16" />
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <Empty description="No Items in Cart" />
              )}
            </div>
            <div
              style={{
                width: '20%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Button
                type="danger"
                block
                disabled={!selectedOrderReplica?.altered}
                onClick={() => {
                  const newSelectedOrderReplica = _.cloneDeep(selectedOrder);
                  setSelectedOrderReplica({
                    ...newSelectedOrderReplica,
                    altered: false,
                  });
                }}
              >
                Reset Card Changes
              </Button>
              <Button
                type="primary"
                block
                onClick={() => {
                  setAddToCartModalVisible(true);
                }}
              >
                Add New Item
              </Button>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Order Id</p>
                <p>
                  <b>{selectedOrderReplica?.orderId}</b>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Items No.</p>
                <p>
                  <b>
                    {selectedOrderReplica?.cart?.reduce(
                      (acc, item) => acc + item.quantity,
                      0,
                    )}
                  </b>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Delivery Price</p>
                <p>
                  <b>{selectedOrderReplica?.orderDeliveryPrice}</b>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Discount</p>
                <p>
                  <b>{selectedOrderReplica?.orderDiscount}</b>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Subtotal</p>
                <p>
                  <b>
                    {selectedOrderReplica?.cart?.reduce(
                      (acc, item) =>
                        acc +
                        item.quantity *
                          (item.product_discount
                            ? item.product_discount
                            : item.product_price),
                      0,
                    )}
                  </b>
                </p>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>Total</p>
                <p>
                  <b>
                    {(
                      selectedOrderReplica?.cart?.reduce(
                        (acc, item) =>
                          acc +
                          item.quantity *
                            (item.product_discount
                              ? item.product_discount
                              : item.product_price),
                        0,
                      ) + selectedOrderReplica?.orderDeliveryPrice
                    ).toString()}
                  </b>
                </p>
              </div>
            </div>
          </div>
          {selectedProductId ? (
            <CreateTicketForProduct
              open={selectedProductId}
              setOpen={setSelectedProductId}
              order_id={selectedOrder.orderId}
            />
          ) : null}
          <AddNewItemsModalComponent
            addToCartModalVisible={addToCartModalVisible}
            setAddToCartModalVisible={setAddToCartModalVisible}
            selectedOrder={selectedOrderReplica}
            setSelectedOrder={setSelectedOrderReplica}
            merchantId={selectedOrder.merchant.merchant_id}
            oldAllProduct={allProducts}
            requestAllProducts={requestAllProducts}
            setDataAllProducts={setDataAllProducts}
            loading={allProductsLoading}
          />
        </>
      )}
    </Modal>
  );
};

export default OrderCartModalComponent;
