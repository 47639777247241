import { hookstate } from '@hookstate/core';

const selectedChatRoomState = hookstate({
  data: {},
  loading: false,
  error: null,
  selected_id: null,
  selected_chatroom: {
    roomType: '',
    roomRef: '',
    _id: '',
  },
});

export const chatStates = {
  selectedChatRoomState,
};

export default chatStates;
