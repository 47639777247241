import { Button, Popconfirm, Tabs, Tooltip, message as antMessage } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  UilPrescriptionBottle,
  UilCommentMessage,
  UilCommentImage,
} from '@iconscout/react-unicons';
import useLocalStorage from './../../configs/localStorage';

import _ from 'lodash';
import ChatMessageTabs from './ChatMessageTab';
import ChatScriptTab from './ChatScriptTab';
import ChatMediaTab from './ChatMediaTab';
import { useAxiosChatPut } from '../../configs/axios';
import { RetweetOutlined } from '@ant-design/icons';
import { darkModeLocalConst } from '../../configs/constants';

function ChatroomComponent(props) {
  const {
    selectedChatroom,
    setSelectedChatroom,
    chatroomMessagesLoading,
    messages,
    source,
    isDrawer,
    chatrooms,
    setChatroomData,
    disableActions,
    onLoadMoreMessages,
    setMessages,

    canNotLoadMore,
  } = props;

  const [pusherChannel, setPusherChannel] = useState(null);
  const [selectTab, setSelectedTab] = useState('1');

  const [message, setMessage] = useState('');
  const [auth] = useLocalStorage('auth', null);
  const [colorLocalStorage] = useLocalStorage(
    darkModeLocalConst,
    null
  );
  useEffect(() => {
    const chat = document.getElementById('chat-content');
    if (chat) {
      chat.scrollTop = chat.scrollHeight;
    }
  }, [source]);
  const { request: assignToMeRequest, loading: assignToMeLoading } =
    useAxiosChatPut('/chats/reassignChatAgent', {});

  const onAssignToMe = async (e) => {
    e.stopPropagation();
    const body = {
      roomRef: selectedChatroom.roomRef,
      agentId: auth?.user._id,
    };
    try {
      await assignToMeRequest(body);
      antMessage.success('Chat assigned to you successfully');
    } catch (error) {
      antMessage.error('Something went wrong');
    }
  };

  const tabs = [
    {
      key: '1',
      style: {
        width: '100%',
        height: 'calc(100vh - 200px)',
      },
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Tooltip title="Chats">
            <UilCommentMessage />
          </Tooltip>
        </div>
      ),
      children: (
        <ChatMessageTabs
          selectedChatroom={selectedChatroom}
          setSelectedChatroom={setSelectedChatroom}
          isDrawer={isDrawer}
          disableActions={disableActions}
          chatrooms={chatrooms}
          setChatroomData={setChatroomData}
          source={source}
          message={message}
          setMessages={setMessages}
          setMessage={setMessage}
          chatroomMessagesLoading={chatroomMessagesLoading}
          messages={messages}
          pusherChannel={pusherChannel}
          onLoadMoreMessages={onLoadMoreMessages}
          canNotLoadMore={canNotLoadMore}
        />
      ),
    },
    {
      key: '2',
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tooltip title="Scripts">
            <UilPrescriptionBottle />
          </Tooltip>
        </div>
      ),

      children: (
        <ChatScriptTab
          setMessage={setMessage}
          setSelectedTab={setSelectedTab}
          source={selectedChatroom?.roomType}
          selectedChatroom={selectedChatroom}
          colorLocalStorage={colorLocalStorage}
        />
      ),
    },
    {
      key: '3',
      style: {
        width: 10,
        height: 'calc(100vh - 100px)',
      },
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Tooltip title="Media">
            <UilCommentImage />
            <Popconfirm
              title="Are you sure to assign to yourself ?"
              onConfirm={onAssignToMe}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              cancelText="No"
              okText="Yes"
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                type="ghost"
                style={{
                  position: 'absolute',
                  top: 3,
                  right: -35,
                }}
              >
                <RetweetOutlined
                  color="red"
                  style={{
                    color: 'red',
                  }}
                />
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
      children: (
        <ChatMediaTab
          selectedChatroom={selectedChatroom}
          source={source}
          selectTab={selectTab}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        paddingTop: '10px',
        marginLeft: '10px',
      }}
    >
      <Tabs
        style={{
          width: '100%',
          height: '100%',
        }}
        onChange={(key) => {
          setSelectedTab(key);
        }}
        activeKey={selectTab}
        tabBarStyle={{
          backgroundColor: colorLocalStorage?.darkMode?'#ECEEF633':'#ECEEF6',
          height: '40px',
          width: '100%',
        }}
        defaultActiveKey="1"
        items={tabs}
      />
    </div>
  );
}

export default ChatroomComponent;
