import { io } from 'socket.io-client';
import { CHAT_URL } from '../../configs/constants';

var socket = null;

const getSocket = () => {
  return socket;
};

const initSocket = (auth) => {
  if (getSocket()) return getSocket();

  socket = io(CHAT_URL, {
    query: {
      userId: auth?.user?._id,
      type: 'agent',
      userName: auth?.user?.displayname_en,
      userPhoto:
        'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    },
    reconnection: true,
    autoConnect: true,
    reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
    reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts
    reconnectionAttempts: Infinity,
    transports: ['websocket', 'polling'],
  });
  socket.on('connect', (data) => {
    // });
    // customer  ///customer_general  ///customer_agent->> selected by default
    // Merchant  /// Merchant_Agent
    // Driver /// orders  ->> selected by default /// couriers
    // join room
  });

  return socket;
};

const disconnect = () => {
  socket.disconnect();
  console.log('Socket disconnect');
  //socket = null;
};
export { initSocket, getSocket, disconnect };
