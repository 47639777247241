

const RenderMenuTicketWithChat =({removeOrderWith20MinsRest})=>[
  // {
  //   key: '1',
  //   label: (
  //     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
  //       Remove Order from driver
  //     </a>
  //   ),
  // },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" onClick={(e) => {
        e.stopPropagation();
        removeOrderWith20MinsRest();
      }}>
        Remove Order from driver and give 20m break
      </a>
    ),
  },
  // {
  //   key: '3',
  //   label: (
  //     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
  //       remove riders today shift
  //     </a>
  //   ),
  // },
]

export default RenderMenuTicketWithChat;
