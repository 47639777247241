/* eslint-disable no-restricted-globals */
import { Button, Form, Input } from 'antd';
import Wave from 'react-wavify';
import React, { useRef } from 'react';

import useLocalStorage from '../configs/localStorage';
import { useAxiosPost } from '../configs/axios';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../configs/firebase';

let firebaseApp;
let messaging;
try {
  // Initialize Firebase
  firebaseApp = initializeApp(firebaseConfig);

  // Get Firebase Messaging instance
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.log('====================================');
  console.log('Error initializing Firebase:', error);
  console.log('====================================');
}
navigator.serviceWorker
  .register('/firebase-messaging-sw.js')
  .then((registration) => {
    getToken(messaging, { serviceWorkerRegistration: registration })
      .then((token) => {
        console.log('FCM Token:', token);
      })
      .catch((error) => {
        console.error('Error retrieving FCM token:', error);
      });
  })
  .catch((error) => {
    console.error('Service worker registration failed:', error);
  });

const Login = () => {
  const fcmTokenRef = useRef();
  const [auth, setAuth] = useLocalStorage('auth', null);
  const {
    request: loginRequest,
    loading: loginLoading,
    error: loginError,
  } = useAxiosPost('/auth/login');

  const getFCMToken = async () => {
    try {
      if (fcmTokenRef.current) return fcmTokenRef.current;

      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        try {
          fcmTokenRef.current = await getToken(messaging);
        } catch (error) {
          console.error('Error getting FCM token:', error);
        }
      }
      return fcmTokenRef.current;
    } catch (error) {
      console.error('Error getting FCM token:', error);
      return null; // Return null if token generation fails
    }
  };

  const onLogin = async (values) => {
    const fcmToken = await getFCMToken();
    try {
      const loginData = await loginRequest({
        username: values.username,
        password: values.password,
        fcmToken,
      });
      setAuth({
        token: loginData.data.token,
        user: loginData.data.user,
        fcmToken,
      });
      console.log('tokengenerate', fcmToken);
      location.replace('/');
    } catch (error) {
      console.log('🚀 ~ file: Login.jsx:27 ~ onLogin ~ error', error);
    }
  };

  if (auth) {
    return location.replace('/');
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
      }}
    >
      <div
        style={{
          background: 'rgb(0,100,192)',
          width: '100%',
          position: 'absolute',
          zIndex: -1,
          height: '100vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
        }}
      >
        <img
          alt="lezzoo"
          src="https://assets-global.website-files.com/63cfc6ce2af1e25afcbf098c/6475c9e73a99deaf4e9536fb_Lezzoo%20Logo.png"
          style={{
            position: 'absolute',
            top: '60px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '350px',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '40px',
            borderRadius: '20px',
            height: '450px',
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '40px',
              fontWeight: 'bold',
              lineHeight: '0.8',
            }}
          >
            Login
          </h1>
          <p
            style={{
              fontWeight: 'bold',
              opacity: 0.5,
              fontSize: '20px',
            }}
          >
            Enter your credentials
          </p>
          <Form
            layout="vertical"
            style={{
              width: '100%',
              marginTop: '50px',
              overflow: 'visible',
              position: 'relative',
              zIndex: 5,
            }}
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input size="large" placeholder="Username" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              name="password"
            >
              <Input size="large" placeholder="Password" type="password" />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              style={{
                width: '100%',
                position: 'relative',
                zIndex: 10, 
                willChange: 'transform', 
              }}
              loading={loginLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
          <div
            style={{
              color: 'red',
              marginTop: '10px',
            }}
          >
            {loginError && loginError.response.data.msg}
          </div>
        </div> 
          

        <Wave   // This is the wave effect at the bottom of the login page Warning : this wave for multiple screen sizes may not work properly and may also prevent the form from being clicked be aware of how to use Z index and styling on it and use pointer events to prevent it from being clicked or to focus on the form!
          fill="white" // PointerEvents: is a CSS Property that allows for control over how an element can be interacted with by the user from Mouse (hover, touch, click) and so on.
          style={{
            height: '20%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            zIndex: -1,
          }}
          options={{
            height: 0,
            amplitude: 40,
            speed: 0.2,
            points: 8,
          }}
        />
        <Wave
          fill="white"
          style={{
            height: '25%',
            opacity: 0.5,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            zIndex: -2,
          }}
          options={{
            height: 0,
            amplitude: 40,
            speed: 0.2,
            points: 6,
          }}
        />
        <Wave
          fill="white"
          style={{
            height: '29%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0.2,
            pointerEvents: 'none',
            zIndex: -3,
          }}
          options={{
            height: 0,
            amplitude: 30,
            speed: 0.2,
            points: 4,
          }}
        />
      </div>
    </div>
  );
};

export default Login;
