const Phone = ({fill="#313131"}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.595095 4.68798C1.96724 7.67606 4.40536 10.0443 7.43206 11.3291L7.91806 11.5457C8.45626 11.7853 9.06178 11.8273 9.62792 11.6643C10.1941 11.5012 10.6845 11.1436 11.0128 10.6544L11.6482 9.70813C11.7476 9.55973 11.7878 9.37947 11.761 9.20287C11.7341 9.02627 11.6421 8.86613 11.5031 8.75399L9.35035 7.01723C9.27538 6.95679 9.18897 6.91212 9.0963 6.88591C9.00363 6.85969 8.90663 6.85248 8.8111 6.86469C8.71558 6.87691 8.62351 6.90831 8.54042 6.95699C8.45733 7.00568 8.38494 7.07066 8.32759 7.14802L7.66148 8.04642C5.9516 7.20194 4.56757 5.81766 3.7234 4.10763L4.62108 3.44151C4.69845 3.38417 4.76343 3.31177 4.81211 3.22869C4.8608 3.1456 4.8922 3.05353 4.90441 2.958C4.91663 2.86248 4.90941 2.76547 4.8832 2.67281C4.85699 2.58014 4.81232 2.49373 4.75188 2.41876L3.01512 0.266036C2.90298 0.126991 2.74283 0.0349784 2.56623 0.00812444C2.38963 -0.0187295 2.20938 0.0215216 2.06097 0.120949L1.10826 0.759904C0.616108 1.08994 0.257099 1.58398 0.0951889 2.154C-0.066721 2.72402 -0.0210174 3.33301 0.224158 3.87249L0.595095 4.68798Z"
        fill={fill}
      />
    </svg>
  );
};

export default Phone;
