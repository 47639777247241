import { Modal } from 'antd';
import React, { useContext } from 'react';
import { OrderContext } from '../../pages/Home';

function OrderAmountDetailsComponent(props) {
  const { status, setStatus } = props;
  const { selectedOrder } = useContext(OrderContext);
  return (
    <Modal
      title="Order Amount Details"
      destroyOnClose
      getContainer={false}
      open={status}
      onOk={() => {
        setStatus(false);
      }}
      onCancel={() => {
        setStatus(false);
      }}
      centered
      footer={null}
      width={400}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Price:</strong>
          <span>{selectedOrder.orderSubtotal}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Subscription Total:</strong>
          <span>{selectedOrder.orderSubscriptionTotal}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Discount:</strong>
          <span>{selectedOrder.orderDiscount}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Customer Delivery:</strong>
          <span>{selectedOrder.orderDeliveryPrice}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Service Price:</strong>
          <span>{selectedOrder.orderServicePrice}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Remaining cash:</strong>
          <span>{selectedOrder.orderRemainingCash}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Tax:</strong>
          <span>{selectedOrder.orderTax}</span>
        </div>
      </div>
    </Modal>
  );
}

export default OrderAmountDetailsComponent;
