import React, { useContext } from "react";
import { Button, Form, Input } from "antd";
import { useAxiosPost } from "../../../configs/axios";

const ManualOrderForm = (props) => {
  const { getAllOrders, selectedOrder } = props;
  const { closeModal } = props;
  const { data, loading, error, request } = useAxiosPost("/orders/manual");

  const onManualOrderSubmit = async (values) => {
    await request({ ...values, orderId: selectedOrder.orderId });
    closeModal();
    getAllOrders();
  };
  return (
    <Form onFinish={onManualOrderSubmit} layout="vertical">
      <Form.Item label="Total Price" name="orderPrice">
        <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
      </Form.Item>
      <Form.Item label="Customer Delivery Fee" name="orderDeliveryPrice">
        <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
      </Form.Item>
      <Form.Item label="Merchant Delivery Fee" name="orderMerchantDelivery">
        <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
      </Form.Item>

      <Button loading={loading} type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};

export default ManualOrderForm;
