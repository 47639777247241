/*eslint-disable*/

const AgentIcon = ({ width = 10, height = 10 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 11" fill="none">
      <path
        d="M3.57143 0.158203C2.19448 0.158203 1.07143 1.28125 1.07143 2.6582C1.07143 3.51899 1.51088 4.28349 2.17634 4.7341C0.902621 5.28099 0 6.54492 0 8.01535H0.714286C0.714286 6.43331 1.9894 5.1582 3.57143 5.1582C4.0625 5.1582 4.52007 5.28656 4.92186 5.5042C4.52568 5.99527 4.28571 6.62306 4.28571 7.30106C4.28571 8.8747 5.56921 10.1582 7.14286 10.1582C8.7165 10.1582 10 8.8747 10 7.30106C10 5.72742 8.7165 4.44392 7.14286 4.44392C6.51925 4.44392 5.93889 4.64899 5.46875 4.99077C5.31111 4.89174 5.1395 4.80802 4.9665 4.7341C5.63196 4.28349 6.07143 3.51899 6.07143 2.6582C6.07143 1.28125 4.94839 0.158203 3.57143 0.158203ZM3.57143 0.872489C4.56193 0.872489 5.35714 1.66769 5.35714 2.6582C5.35714 3.6487 4.56193 4.44392 3.57143 4.44392C2.58091 4.44392 1.78571 3.6487 1.78571 2.6582C1.78571 1.66769 2.58091 0.872489 3.57143 0.872489ZM7.14286 5.1582C8.33007 5.1582 9.28571 6.11385 9.28571 7.30106C9.28571 8.48827 8.33007 9.44392 7.14286 9.44392C5.95564 9.44392 5 8.48827 5 7.30106C5 6.11385 5.95564 5.1582 7.14286 5.1582ZM8.31471 6.33006L7.14286 7.50195L6.32814 6.6872L5.81471 7.20063L6.88614 8.27206L7.14286 8.51756L7.39957 8.27206L8.82814 6.84349L8.31471 6.33006Z"
        fill="white"
      />
    </svg>
  );
};

export default AgentIcon;
