import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Modal,
  Select,
  Space,
  Spin,
  Tag,
  message,
  Typography,
} from 'antd';
import { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { OrderContext } from '../../pages/Home';
import { useAxiosGet } from '../../configs/axios';
import moment from 'moment/moment';
import CreateTicketComponent from './CreateTicketComponent';
import VipCustomerInformationModal from './VipCustomerInformationModal';
const { Title, Paragraph } = Typography;

const CustomerInformationModal = (props) => {
  const { status, setStatus } = props;
  const { selectedOrder } = props.selectedOrder
    ? props.selectedOrder
    : useContext(OrderContext);
  const [visible, setVisible] = useState(false);
  const [vipVisible, setVipVisible] = useState(false);

  const {
    data: customerData,
    loading: customerLoading,
    request: getCustomerInfoRequest,
  } = useAxiosGet('/orders/customerOrderHistory', {
    autoRun: false,
    customerId: selectedOrder.customer?.customer_id,
    orderId: selectedOrder.orderId,




  });









  const [filter, setFilter] = useState('all');
  return (
    <Modal
      title="Customer Information"
      destroyOnClose
      getContainer={false}
      open={status}
      onCancel={() => {
        setStatus(false);
      }}
      footer={null}
      centered
      width={600}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          width: '100%',
          marginBottom: 10,
        }}
      >
        <Avatar size={90} icon={<UserOutlined />} />
        <h2
          style={{
            textAlign: 'center',
          }}
        >
          {selectedOrder.customer.customer_name +
            ' ' +
            selectedOrder.customer.customer_last_name}
          <small
            style={{
              fontSize: '13px',
              color: 'gray',
              marginLeft: '10px',
            }}
          >
            ({selectedOrder.customer.customer_id})
          </small>
        </h2>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <b>Phone Number</b>
            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                  selectedOrder.customer.customer_phone,
                );
                message.success('Copied to clipboard');
              }}
            >
              {selectedOrder.customer.customer_phone}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <b>Secondary Phone Number</b>
            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                  selectedOrder.customer.customer_secondary_phone,
                );
                message.success('Copied to clipboard');
              }}
            >
              {selectedOrder.customer.customer_secondary_phone}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <b>City</b>
            <p>{selectedOrder.customer.customer_city}</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <b>Language</b>
            <p>
              {selectedOrder.customer.customer_language === 'en'
                ? 'English'
                : selectedOrder.customer.customer_language === 'ar'
                  ? 'Arabic'
                  : 'Kurdish'}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <b>Gender</b>
            <p>{selectedOrder?.customer?.customer_gender}</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              height: 40,
              paddingTop: 'auto',
              paddingBottom: 'auto',
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',

              // marginTop:-40
            }}
          >
            <b>Date Joined</b>
            <p>{selectedOrder.customer.customer_date_added.split('T')[0]}</p>
          </div>

          <div>
            <Button onClick={() => setVipVisible(true)}>Show VIP</Button>
            {vipVisible && (
              <VipCustomerInformationModal
                customer_id={selectedOrder?.customer?.customer_id}
                vipVisible={vipVisible}
                setVipVisible={setVipVisible}

              />
            )}
          </div>

        </div>
      </div>
      <CreateTicketComponent
        issue_source_type={'order'}
        issue_source_id={selectedOrder.orderId}
        issue_type="customer"
      />
      <Button
        type="primary"
        block
        style={{
          marginTop: '10px',
        }}
        onClick={() => {
          getCustomerInfoRequest();
          setVisible(true);
        }}
      >
        Check Orders
      </Button>
      <Drawer
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h2>Past Orders</h2>
            <Space>
              <Space direction="vertical" align="center">
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Completed
                </p>
                <Tag color="green">
                  {
                    customerData?.filter(
                      (order) => order.order_status === 'completed',
                    ).length
                  }
                </Tag>
              </Space>
              <Space direction="vertical" align="center">
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Canceled
                </p>

                <Tag color="red">
                  {
                    customerData?.filter(
                      (order) => order.order_status === 'canceled',
                    ).length
                  }
                </Tag>
              </Space>
              <Space direction="vertical" align="center">
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Rejected
                </p>

                <Tag color="orange">
                  {
                    customerData?.filter(
                      (order) =>
                        order.order_rejection_reason_id ||
                        order.order_rejected_on_id,
                    ).length
                  }
                </Tag>
              </Space>
            </Space>
          </div>
        }
        closable={false}
        open={visible}
        key={'1'}
        onClose={() => {
          setVisible(false);
        }}
      >
        <Select
          style={{
            width: '100%',
            marginBottom: '30px',
          }}
          defaultValue="all"
          onChange={(value) => setFilter(value)}
          options={[
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Completed',
              value: 'completed',
            },
            {
              label: 'Canceled',
              value: 'canceled',
            },
            {
              label: 'Rejected',
              value: 'rejected',
            },
          ]}
        />

        {customerLoading ? (
          <Spin size="large" />
        ) : customerData ? (
          customerData
            .filter((order) => {
              if (filter === 'all') return true;
              if (filter === 'completed')
                return order.order_status === 'completed';
              if (filter === 'canceled')
                return order.order_status === 'canceled';
              if (filter === 'rejected')
                return (
                  order.order_rejection_reason_id || order.order_rejected_on_id
                );
            })
            .map((order) => (
              <div
                key={order.order_id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Order Id
                    </p>
                    <p>{order.order_id}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Price
                    </p>
                    <p>
                      {
                        // format order.order_price with currency and commas
                        order.order_total.toLocaleString('en-US') + ' IQD'
                      }
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Status
                    </p>
                    <Tag
                      style={{
                        fontSize: '10px',
                        textTransform: 'capitalize',
                      }}
                      color={
                        order.order_rejection_reason_id ||
                          order.order_rejected_on_id
                          ? 'yellow'
                          : order.order_status === 'completed'
                            ? 'green'
                            : 'red'
                      }
                    >
                      {order.order_rejection_reason_id ||
                        order.order_rejected_on_id
                        ? 'Rejected'
                        : order.order_status}
                    </Tag>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Merchant
                    </p>
                    <p>{order.merchant_name}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Date
                    </p>
                    <p>
                      {moment(order.order_date_added).format(
                        'DD/MM/YYYY hh:mm A',
                      )}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {order.value ? (
                    <>
                      <p
                        style={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Cancelation Reason
                      </p>
                      <p>{order.value}</p>
                    </>
                  ) : null}
                  {order.order_rejection_reason ? (
                    <>
                      <p
                        style={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          color: 'red',
                        }}
                      >
                        Rejected Reason
                      </p>
                      <p>{order.order_rejection_reason}</p>
                    </>
                  ) : null}
                  <p
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      color: 'gray',
                    }}
                  >
                    Address
                  </p>
                  <p>{order.merchant_city + ' - ' + order.order_address}</p>
                </div>
                {order.locationStatus !== 'Same' && (
                  <div>
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Different Location
                    </p>
                    <Tag
                      color={'red'}
                      style={{
                        display: 'inline',
                      }}
                    >
                      {order.distance} KM
                    </Tag>
                  </div>
                )}

                <Divider />
              </div>
            ))
        ) : null}
      </Drawer>
    </Modal>
  );
};


export default CustomerInformationModal;
