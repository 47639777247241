import { Modal } from 'antd';
import React from 'react';

function OrderEvaluationModalComponent(props) {
  const { status, setStatus, selectedOrder } = props;

  return (
    <Modal
      title="Order Evaluation Information"
      destroyOnClose
      open={status}
      getContainer={false}
      footer={null}
      onCancel={() => {
        setStatus(false);
      }}
      centered
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {selectedOrder?.orderEvaluationFlags.map((item, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}+${item?.message}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <span
              style={{
                height: '15px',
                width: '15px',
                backgroundColor: item?.flag,
                borderRadius: '50%',
              }}
            />
            <p>{item?.message}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default OrderEvaluationModalComponent;
