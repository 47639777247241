import { Row, Col, Image, Tooltip } from 'antd';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { DownloadOutlined } from '@ant-design/icons';
import classes from './style/masonry-grid.module.css';
import application from '../../assets/images/application.png'; // Fallback image

const MasonryGrid = ({ mediaData, preview = false }) => {
  const columnCount = 3;
  const columns = Array.from({ length: columnCount }, () => []);

  // Function to create a source name from the source_id URL
  const extractSourceName = (url) => {
    const start = url.lastIndexOf('/video/') + '/video/'.length;
    return url.substring(start).split('_')[0]; // Get the name before the underscore
  };

  const downloadImage = async (url, name) => {
    try {
      const response = await fetch(url, {
        mode: 'cors',
        headers: {
          "Cache-Control": "no-cache", // needs to request to the s3 bucket in order to get the permission to download the file ( !read not write)
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Free up memory (clear cache)
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  

  // Distribute media items across columns
  mediaData?.forEach((item, idx) => {
    const columnIndex = idx % columnCount;
    columns[columnIndex].push(item);
  });

  return (
    <Row gutter={[10, 10]} className={classes.MasonryGrid}>
      {columns.map((col, colIdx) => (
        <Col
          span={24 / columnCount}
          key={colIdx}
          className={classes.MasonryColumn}
        >
          {col.map((data, idx) => {
            const url = data.source_id;
            const mediaType = data.source_type;
            const isLarge = data.isLarge;
            const itemClasses = `${classes.MasonryItem} ${
              isLarge ? classes.LargeItem : ''
            }`;
            const sourceName =
              data.source_name || extractSourceName(data.source_id);

            if (mediaType === 'application') {
              const fileExtension = url.split('.').pop();
              return (
                <div
                  key={idx}
                  className={itemClasses}
                  onClick={() => window.open(url)}
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                >
                  <FileIcon
                    extension={fileExtension}
                    {...(defaultStyles[fileExtension] || defaultStyles.default)}
                  />
                  <p>{sourceName}</p>
                </div>
              );
            }

            if (mediaType === 'image') {
              return (
                <div
                  key={idx}
                  className={itemClasses}
                  style={{ position: 'relative' }}
                >
                  <Tooltip title={sourceName}>
                    <Image
                      alt="media"
                      src={url}
                      preview={preview}
                      className={classes.MasonryImage}
                      onError={() => console.error('Error loading image')}
                    />
                  </Tooltip>

                  <DownloadOutlined
                    onClick={() => downloadImage(url, `${sourceName}.jpg`)}
                    className={classes.DownloadIcon}
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      fontSize: 24,
                      color: 'white',
                      cursor: 'pointer',
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      padding: '6px',
                      borderRadius: '50%',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                      transition: 'transform 0.2s',
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = 'scale(1.2)')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = 'scale(1)')
                    }
                  />
                </div>
              );
            }

            if (mediaType === 'video') {
              return (
                <div key={idx} className={itemClasses}>
                  <Tooltip title={sourceName}>
                    <video
                      className={classes.MasonryVideo}
                      controls
                      src={url}
                      onError={() => console.error('Error loading video')}
                    />
                  </Tooltip>
                </div>
              );
            }

            return (
              <div key={idx} className={itemClasses}>
                <Image
                  src={application} // Fallback image
                  preview={false}
                  onClick={() => window.open(url)}
                  style={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  download={sourceName}
                />
              </div>
            );
          })}
        </Col>
      ))}
    </Row>
  );
};

export default MasonryGrid;
