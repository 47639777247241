import React, { useEffect, useState } from 'react';
import { Drawer, Row, Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

/*
filters={[
          {
            name: 'Merchant',
            count: merchantChatRooms.length,
            data: merchantChatRooms,
            filterKey: 'roomName',
          },
          {
            name: 'Language',
            count: languageChatRooms.length,
            filterKey: 'roomLanguage',
          },
          {
            name: 'Issue',
            count: issueTagChatRooms.length,
            filterKey: 'issueTags',
          },
          {
            name: 'Agent',
            count: agentChatRooms.length,
            filterKey: 'roomAssignedToName',
          },
        ]}
*/
const ChatFilterDrawer = ({
  visible,
  onClose,
  filters,
  onSelect,
  activeFilters,
}) => {
  const [activeFiltersData, setActiveFiltersData] = useState({});
  const [filtersData, setFiltersData] = useState([]);
  useEffect(() => {
    setActiveFiltersData(activeFilters);
    setFiltersData(filters);
  }, [activeFilters, filters]);
  return (
    <Drawer
      title="Filters"
      placement="top"
      closable={true}
      onClose={onClose}
      open={visible}
    >
      {filtersData?.map((filter, index) => (
        <div key={index}>
          <h3>{filter.name}</h3>
          <Row>
            {filter.data?.map((data, index) => {
              const isActive = activeFiltersData[filter.filterKey] == data.name;
              return (
                <Tag
                  key={index}
                  color={isActive ? 'blue' : 'default'}
                  onClick={() => onSelect(data)}
                >
                  {data.name} ({data.count})
                </Tag>
              );
            })}
          </Row>
        </div>
      ))}
    </Drawer>
  );
};

export default ChatFilterDrawer;
