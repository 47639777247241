import { Card } from 'antd';
import React from 'react';

function ItemCard({ setSelectedItem, item }) {
    return (
      <Card
        onClick={() => {
              setSelectedItem(item);
            }}
        title={item.product_id}
        hoverable
        style={{ width: '45%' }}
        key={item.product_id * Math.random()}
      >
        <div
          style={{
                width: '100%',
                height: '100%',
                display: 'flex',
              }}
        >
          <img
            style={{
                  width: '15%',
                  height: '100px',
                  objectFit: 'cover',
                }}
            alt="example"
            src={item.product_image}
          />

          <div
            style={{
                  width: '85%',
                  paddingInline: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
          >
            <h2>
              {`${item.category_name.en
                  } - ${
                    item.product_name.en}`}
            </h2>
            <p>
              {item.product_discount
                    ? `${Number(item.product_discount).toLocaleString(
                      'en-US',
                    )} IQD`
                    : `${Number(item.product_price).toLocaleString(
                      'en-US',
                    )} IQD`}
            </p>
          </div>
        </div>
      </Card>
    );
}

export default React.memo(ItemCard);
