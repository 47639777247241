import { useState } from 'react';
import {
  Input,
  Button,
  Spin,
  Divider,
  Checkbox,
  Modal,
  Select,
  Form,
  message,
} from 'antd';

import { Tree } from 'antd';
// import { axiosGet, axiosPost } from "../../../actions/Axios";
import { useEffect } from 'react';
import { useAxiosGet, useAxiosPost } from '../../../configs/axios';

function RefundTab({ selectedOrder }) {
  const [loading, setLoading] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [originalOrderPrice, setOriginalOrderPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [selectedDeliveryPrice, setSelectedDeliveryPrice] = useState(false);
  const [refundReasons, setRefundReasons] = useState([]);
  const [extraNotes, setExtraNotes] = useState('');
  const [customerRefundData, setCustomerRefundData] = useState(null);
  const [selectedRefundReason, setSelectedRefundReason] = useState(null);
  const [disableRefundTypes, setDisableRefundTypes] = useState([]);
  const [fullRefundWithDeliveryModal, setFullRefundWithDeliveryModal] =
    useState(false);
  const [refundPrice, setRefundPrice] = useState(0);
  const [error, setError] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedRefundType, setSelectedRefundType] = useState(null);
  const [previousRefunds, setPreviousRefunds] = useState([]);
  const [defaultOrderId, setDefaultOrderId] = useState(null);
  const [fetch, setFetch] = useState(true);
  const [compensatedAmount, setCompensatedAmount] = useState(0);
  const [refundedAmount, setRefundedAmount] = useState(0);
  const [refundLoading, setRefundLoading] = useState(false);

  const { request: refundRequest } = useAxiosPost('/compensate');
  const { request: getRefundDataRequest } = useAxiosGet('/compensate/search');

  const reset = () => {
    setLoading(false);
    setError(false);
    setTreeData(null);
    setExpandedKeys([]);
    setAutoExpandParent(true);
    setCheckedKeys([]);
    setSelectedKeys([]);
    setSelectedDeliveryPrice(false);
    setRefundPrice(0);
    setDeliveryPrice(0);
    setOriginalOrderPrice(0);
    setSelectedRefundReason(null);
    setSelectedPaymentType(null);
    setSelectedTarget(null);
    setCustomerRefundData(null);
    setExtraNotes('');
    setSelectedRefundType(null);
    setPreviousRefunds([]);
    setRefundLoading(false);
    setAllOrders([]);
    setFullRefundWithDeliveryModal(false);
    setDisableRefundTypes([]);
  };
  const onRefundCustomer = async (refundType) => {
    try {
      setRefundLoading(true);
      const data = {
        orderId: selectedOrder.orderId,
        customerId: selectedOrder.customer.customer_id,
        reason: selectedRefundReason,
        method: selectedPaymentType,
        target: selectedTarget,
        type: refundType,
        selectedItems: [],
        extraNotes: extraNotes,
        delivery: false,
      };
      if (refundType == 'item_level_refund') {
        data.selectedItems = customerRefundData;
      }

      if (
        (refundType == 'item_level_refund' && selectedDeliveryPrice) ||
        refundType == 'full_refund_with_delivery' ||
        refundType == 'delivery_refund'
      ) {
        data.delivery = true;
      }

      await refundRequest(data);
      message.success('Order Refunded Successfully');
      reset();
    } catch (error) {
      message.error(error.response.data?.message || 'Something went wrong');
    } finally {
      setRefundLoading(false);
    }
  };

  const searchHandler = async () => {
    try {
      reset();
      setLoading(true);
      const res = await getRefundDataRequest({
        orderId: selectedOrder.orderId,
      });

      const disableRefundTypes = res.disabledRefundTypes;
      setDisableRefundTypes(disableRefundTypes);
      setAllOrders(res.order);
      setOriginalOrderPrice(res.order[0].order_total);
      setDeliveryPrice(res.order[0].order_delivery_price);
      const previousRefunds = res.order.filter(
        (item) => item.customer_refund_id,
      );

      if (res.compensationAmount && res.compensationAmount > 0) {
        setCompensatedAmount(res.compensationAmount);
      }

      if (res.totalRefundAmount && res.totalRefundAmount > 0) {
        setRefundedAmount(res.totalRefundAmount);
      }

      setPreviousRefunds(previousRefunds);
      let order_cart = [];
      // ! fix the quantity issue here
      JSON.parse(res.order[0].cart_data).forEach((item) => {
        // push the item to the new array according to the quantity

        for (let i = 0; i < item.quantity; i++) {
          order_cart.push(item);
        }
      });
      const newTreeData = order_cart.map((product, index) => {
        const treeHead = {
          title: `(${product.product_id}) ${product.product_name.en} ${
            disableTreeHeadCheck(
              previousRefunds,
              product,
              index,
              disableRefundTypes,
            )
              ? ' - (Already Refunded)'
              : ''
          }`,
          key:
            product.product_id +
            '-' +
            index +
            '__' +
            'main' +
            '__' +
            Math.random(),
          disabled: disableTreeHeadCheck(
            previousRefunds,
            product,
            index,
            disableRefundTypes,
          ),
          children: [
            {
              disabled: disableTreeHeadCheck(
                previousRefunds,
                product,
                index,
                disableRefundTypes,
              ),
              title: `${product.product_name.en} (${
                product.product_price
              } IQD) ${
                disableTreeHeadCheck(
                  previousRefunds,
                  product,
                  index,
                  disableRefundTypes,
                )
                  ? ' - (Already Refunded)'
                  : ''
              }`,
              key:
                product.product_id +
                '-' +
                index +
                '__' +
                product.product_price +
                '__' +
                Math.random(),
              price: product.product_price,
            },
          ],
        };

        const addons = product.addons.map((addon) => {
          return {
            disabled:
              disableTreeHeadCheck(
                previousRefunds,
                product,
                index,
                disableRefundTypes,
              ) ||
              disabledTreeChildrenCheck(
                previousRefunds,
                product.product_id,
                addon,
                index,
              ),
            title: `${addon.name.en || addon.name} (${addon.value.price} IQD) ${
              disableTreeHeadCheck(
                previousRefunds,
                product,
                index,
                disableRefundTypes,
              ) ||
              disabledTreeChildrenCheck(
                previousRefunds,
                product.product_id,
                addon,
                index,
              )
                ? ' - (Already Refunded)'
                : ''
            }`,
            key:
              product.product_id +
              '-' +
              index +
              '__' +
              addon.id +
              '__' +
              addon.value.price +
              '__' +
              Math.random(),
            price: addon.value.price,
          };
        });

        const filteredAddons = addons.filter((addon) => addon.price > 0);
        treeHead.children.push(...filteredAddons);
        return treeHead;
      });

      setTreeData(newTreeData);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const disableTreeHeadCheck = (
    previousRefunds,
    currentProduct,
    index,
    manualRefundTypeDisbales = [],
  ) => {
    return previousRefunds.find((item) => {
      if (
        item.customer_refund_type == 'full_refund_with_delivery' ||
        item.customer_refund_type == 'full_refund_without_delivery' ||
        manualRefundTypeDisbales.includes('full_refund_with_delivery') ||
        manualRefundTypeDisbales.includes('full_refund_without_delivery')
      ) {
        return true;
      }

      if (item.customer_refund_type == 'item_level_refund') {
        const refundedItems = JSON.parse(item.customer_refund_data);
        return refundedItems.find(
          (refundedItem) =>
            refundedItem.mainIncluded &&
            refundedItem.product_id == currentProduct.product_id &&
            refundedItem.index == index,
        );
      }

      return false;
    })
      ? true
      : false;
  };

  const disabledTreeChildrenCheck = (
    previousRefunds,
    currentProductId,
    currentAddon,
    index,
  ) => {
    return previousRefunds.find((item) => {
      if (
        item.customer_refund_type == 'full_refund_with_delivery' ||
        item.customer_refund_type == 'full_refund_without_delivery'
      ) {
        return true;
      }

      if (item.customer_refund_type == 'item_level_refund') {
        const refundedItems = JSON.parse(item.customer_refund_data);
        const selectedProduct = refundedItems.find(
          (refundedItem) =>
            refundedItem.product_id == currentProductId &&
            refundedItem.index == index,
        );

        return (
          selectedProduct &&
          selectedProduct.product_addons.find(
            (addonId) => addonId == currentAddon.id,
          )
        );
      }

      return false;
    })
      ? true
      : false;
  };
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeys) => {
    // remove all checked keys with "main" in the key
    const filteredCheckedKeys = checkedKeys.filter(
      (key) => !key.includes('main'),
    );

    const newRefundPrice = filteredCheckedKeys.reduce((acc, curr) => {
      if (curr.split('__').length == 3) {
        return acc + Number(curr.split('__')[1]);
      }
      return acc + Number(curr.split('__')[2]);
    }, 0);

    setRefundPrice(newRefundPrice);
    const customerRefundData = [];
    filteredCheckedKeys.forEach((item) => {
      if (item.split('__').length == 3) {
        customerRefundData.push({
          product_id: item.split('__')[0].split('-')[0],
          mainIncluded: true,
          product_addons: [],
          index: item.split('__')[0].split('-')[1],
        });
      }

      if (item.split('__').length == 4) {
        if (
          !customerRefundData.find((x) => x.product_id == item.split('__')[0])
        ) {
          customerRefundData.push({
            product_id: item.split('__')[0].split('-')[0],
            mainIncluded: false,
            product_addons: [],
            index: item.split('__')[0].split('-')[1],
          });
        }

        customerRefundData
          .find((x) => x.product_id == item.split('__')[0].split('-')[0])
          .product_addons.push(item.split('__')[1]);
      }
    });

    setCustomerRefundData(customerRefundData);
    setCheckedKeys(checkedKeys);
  };

  useEffect(() => {
    searchHandler();
  }, []);

  // const renderTreeNodes = (data) =>{
  //   // alert(JSON.stringify(data))

  //   if(!data || data.length == 0){
  //     return null
  //   }

  //   if (data[0].treeData) {
  //     return (<TreeNode
  //       title={data[0].title}
  //       key={data[0].key}
  //       dataRef={data[0]}
  //       disabled={data[0].disabled}
  //     >
  //       {renderTreeNodes(data[0].treeData)}
  //     </TreeNode>)
  //   }

  //   return <TreeNode key={data[0].key} {...data[0]} />
  // //   alert(JSON.stringify(data[0]))
  // //  return  data.map((item) => {
  // //     if (item.treeData) {
  // //       return (
  // //         <TreeNode
  // //           title={item.title}
  // //           key={item.key}
  // //           dataRef={item}
  // //           disabled={item.disabled}
  // //         >
  // //           {renderTreeNodes(item.treeData)}
  // //         </TreeNode>
  // //       );
  // //     }

  // //     return <TreeNode key={item.key} {...item} />
  // //   })
  // }

  const { request: getResonsRequest } = useAxiosGet('/compensate/reasons');
  useEffect(() => {
    const res = getResonsRequest();
    res.then((res) => {
      setRefundReasons(res.reasons);
    });
  }, []);

  return (
    <div
      style={{
        // backgroundColor: 'white',
      }}
    >
      {error || (treeData && treeData.length == 0) ? (
        <h3
          style={{
            color: 'red',
          }}
        >
          This order is not completed yet
        </h3>
      ) : loading ? (
        <Spin size="large" spinning={loading}></Spin>
      ) : treeData && treeData.length > 0 ? (
        <>
          <br />
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: '#16a085',
                border: 'none',
              }}
              onClick={() => {
                setFullRefundWithDeliveryModal(true);
              }}
            >
              Direct Refund
            </Button>
            <Modal
              open={fullRefundWithDeliveryModal}
              onCancel={() => {
                setFullRefundWithDeliveryModal(false);
              }}
              onOk={() => {
                onRefundCustomer(selectedRefundType);
              }}
              confirmLoading={refundLoading}
            >
              <div>
                <h3>Direct Refund</h3>
                <Form layout="vertical">
                  <Form.Item label="Refund Type">
                    <Select
                      placeholder="Select Refund Type"
                      style={{
                        width: 350,
                      }}
                      onChange={(e) => setSelectedRefundType(e)}
                    >
                      <Select.Option
                        value="full_refund_with_delivery"
                        disabled={
                          allOrders?.find((order) => order.customer_refund_id)
                            ? true
                            : false
                        }
                      >
                        Full refund with delivery
                      </Select.Option>
                      <Select.Option
                        value="full_refund_without_delivery"
                        disabled={
                          allOrders?.find(
                            (order) =>
                              (order.customer_refund_id &&
                                (order.customer_refund_type ==
                                  'full_refund_with_delivery' ||
                                  order.customer_refund_type ==
                                    'item_level_refund' ||
                                  order.customer_refund_type ==
                                    'full_refund_without_delivery')) ||
                              disableRefundTypes.includes(
                                'full_refund_with_delivery',
                              ) ||
                              disableRefundTypes.includes(
                                'full_refund_without_delivery',
                              ) ||
                              disableRefundTypes.includes('item_level_refund'),
                          )
                            ? true
                            : false
                        }
                      >
                        Full refund without delivery
                      </Select.Option>
                      <Select.Option
                        value="delivery_refund"
                        disabled={
                          allOrders?.find(
                            (order) =>
                              order.customer_refund_id &&
                              (order.customer_refund_type ==
                                'delivery_refund' ||
                                order.customer_refund_delivery == 1),
                          ) ||
                          selectedOrder.order_delivery_price == 0 ||
                          disableRefundTypes.includes('delivery_refund')
                            ? true
                            : false
                        }
                      >
                        Delivery refund
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Refund Reason">
                    <Select
                      placeholder="Select Reason"
                      style={{
                        width: 350,
                      }}
                      onChange={(e) => setSelectedRefundReason(e)}
                    >
                      {refundReasons.map((reason) => (
                        <Select.Option
                          key={reason.compensation_reason_id}
                          value={reason.compensation_reason_id}
                        >
                          {reason.compensation_reason_value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Payment Type">
                    <Select
                      placeholder="Select Payment Type"
                      style={{
                        width: 350,
                      }}
                      onChange={(e) => setSelectedPaymentType(e)}
                    >
                      <Select.Option value="lezzoopay">
                        Lezzoo Pay
                      </Select.Option>
                      <Select.Option value="visa/mastercard" disabled>
                        Visa / Credit Card
                      </Select.Option>
                      <Select.Option value="cash" disabled>
                        Cash
                      </Select.Option>
                      <Select.Option value="other">Others</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Target">
                    <Select
                      onChange={(e) => setSelectedTarget(e)}
                      style={{
                        width: 350,
                      }}
                      placeholder="Target"
                    >
                      <Select.Option key={'lezzoo'} value={'lezzoo'}>
                        Lezzoo
                      </Select.Option>
                      <Select.Option key={'customer'} value={'customer'}>
                        Customer
                      </Select.Option>
                      <Select.Option key={'merchant'} value={'merchant'}>
                        Merchant
                      </Select.Option>
                      <Select.Option key={'driver'} value={'driver'}>
                        Driver
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Refund Notes">
                    <Input.TextArea
                      onChange={(e) => setExtraNotes(e.target.value)}
                      rows={4}
                      placeholder="Extra Notes"
                      style={{
                        borderRadius: '0px !important',
                        width: 350,
                      }}
                    />
                  </Form.Item>
                </Form>
                {/* 
                    <h3>Refund Amount: {refundPrice} IQD</h3> */}
              </div>
            </Modal>
          </div>
          <Divider />

          <h2>Item Based Refund</h2>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKeys}
            loading={true}
            treeData={treeData}
          >
            {/* {renderTreeNodes(treeData)} */}
          </Tree>
          <Checkbox
            disabled={
              allOrders?.find(
                (order) =>
                  order.customer_refund_id &&
                  (order.customer_refund_type == 'delivery_refund' ||
                    order.customer_refund_delivery == 1),
              ) || selectedOrder.order_delivery_price == 0
                ? true
                : false
            }
            onChange={(e) => setSelectedDeliveryPrice(e.target.checked)}
          >
            Include Delivery Price ({deliveryPrice} IQD){' '}
            {allOrders?.find(
              (order) =>
                order.customer_refund_id &&
                (order.customer_refund_type == 'delivery_refund' ||
                  order.customer_refund_delivery == 1),
            ) && selectedOrder.order_delivery_price != 0
              ? ' (Already Refunded)'
              : selectedOrder.order_delivery_price == 0
              ? 'No Delivery Price'
              : ''}
          </Checkbox>
          <br />
          <br />
          <h4>
            Original Order price: <strong>{originalOrderPrice} IQD</strong>
          </h4>
          <h4>
            Previous Compensations Amount:{' '}
            <strong>{compensatedAmount} IQD</strong>
          </h4>
          <h4>
            Previous Refunds Amount: <strong>{refundedAmount} IQD</strong>
          </h4>
          <h4>
            Refund Amount:
            <strong style={{ color: 'red' }}>
              {' '}
              {refundPrice + (selectedDeliveryPrice ? deliveryPrice : 0)} IQD
            </strong>
          </h4>
          <Select
            placeholder="Select Reason"
            style={{
              width: 350,
            }}
            onChange={(e) => setSelectedRefundReason(e)}
          >
            {refundReasons.map((reason) => (
              <Select.Option
                key={reason.compensation_reason_id}
                value={reason.compensation_reason_id}
              >
                {reason.compensation_reason_value}
              </Select.Option>
            ))}
          </Select>
          <br />
          <br />
          <Select
            placeholder="Select Payment Type"
            style={{
              width: 350,
            }}
            onChange={(e) => setSelectedPaymentType(e)}
          >
            <Select.Option value="lezzoopay">Lezzoo Pay</Select.Option>
            <Select.Option value="visa/mastercard" disabled>
              Visa / Credit Card
            </Select.Option>
            <Select.Option value="cash" disabled>
              Cash
            </Select.Option>
            <Select.Option value="other">Others</Select.Option>
          </Select>
          <br />
          <br />

          <Select
            onChange={(e) => setSelectedTarget(e)}
            style={{
              width: 350,
            }}
            placeholder="Target"
          >
            <Select.Option key={'lezzoo'} value={'lezzoo'}>
              Lezzoo
            </Select.Option>
            <Select.Option key={'customer'} value={'customer'}>
              Customer
            </Select.Option>
            <Select.Option key={'merchant'} value={'merchant'}>
              Merchant
            </Select.Option>
            <Select.Option key={'driver'} value={'driver'}>
              Driver
            </Select.Option>
          </Select>
          <br />
          <br />
          <Input.TextArea
            onChange={(e) => setExtraNotes(e.target.value)}
            rows={4}
            placeholder="Extra Notes"
            style={{
              width: 350,
            }}
          />
          <br />
          <br />
          <Button
            disabled={
              !selectedRefundReason ||
              !selectedPaymentType ||
              !selectedTarget ||
              refundPrice == 0 ||
              !extraNotes ||
              refundPrice + compensatedAmount + refundedAmount >
                originalOrderPrice
            }
            loading={refundLoading}
            type="primary"
            onClick={() => onRefundCustomer('item_level_refund')}
          >
            Refund Customer
          </Button>

          <h3
            style={{
              color: 'red',
              marginTop: '20px',
            }}
          >
            {refundPrice + compensatedAmount + refundedAmount >
            originalOrderPrice
              ? 'Refund Amount with compensation is greater than order total'
              : ''}
          </h3>
        </>
      ) : (
        <h3>Search for an order above to refund</h3>
      )}
    </div>
  );
}

export default RefundTab;
