import React, { useState, useEffect } from 'react';
import { useAxiosGet } from './../../configs/axios';
import { Select, Spin, Empty } from 'antd';
import debounceInput from './../../functions/debounceFx';
const RemoteSelect = ({
  placeholder,
  disabled,
  className,
  rtl,
  endpoint,
  mode,
  optionText,
  optionValue,
  exclude,
  onValueChange, //onChange function
  allowClear,
  valueOption, //Pass specific value for <Option value='ID'></Option>
  labelOption, // pass just label for <Option>{ label }</Option>
  filterFromServer,
  style,
  addExtraData,
  query, //* we using query while we need some params. depend backend developer.
  dependency, //* we need while we want re fetch and rebuilding UI
  selectedValue,
  onClear,
  useFetch = useAxiosGet
}) => {
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [value, setValue] = useState(selectedValue);

  const { request, loading } = useFetch(endpoint, {
    limit: 100,
    offset: 0
  });

  useEffect(() => {
    if (endpoint) {
      loadData();
    }
  }, dependency || []);

  const onChange = (object) => {
    setValue(object);
    try {
      const key = object ? object.value : undefined;
      const objectLabel = apiData.find((d) => d[valueOption] == key);
      onValueChange({ key, object, objectLabel });
    } catch (e) {
      //
    }
  };

  const onSearch = (searchQuery) => {
    loadData(searchQuery);
  };

  const loadData = async (searchQuery) => {
    await request({
      limit: 100,
      offset: 0,
      search: searchQuery,
      ...query
    })
      .then((res) => {
        let array = [];
        if (exclude) {
          const filtered = res.filter(
            (value) => value[valueOption] !== exclude
          );
          array = filtered;
        } else {
          array = res;
          if (!Array.isArray(array)) {
            array = res;
          }
        }

        const options = [];
        array.forEach((element) => {
          options.push({
            label:
              typeof optionText == 'function'
                ? optionText(element)
                : element[labelOption],
            value:
              typeof optionValue == 'function'
                ? optionValue(element)
                : element[valueOption]
          });
        });
        setData(options);
        setApiData(array);
      })
      .catch(() => {
        setData([]);
      });
  };

  const onSearchDebounce = debounceInput((e) => onSearch(e));
  if (addExtraData && data.length > 0 && data[0].value !== 0) {
    try {
      data.unshift(...addExtraData);
    } catch (e) {
      //
      console.log(e);
    }
  }
  return (
    <Select
      allowClear={allowClear}
      mode={mode || null}
      showSearch
      onClear={onClear}
      labelInValue
      disabled={disabled}
      value={value}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      filterOption={
        !filterFromServer
          ? (input, option) => {
            return (option?.children ?? '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }
          : false
      }
      onSearch={filterFromServer ? onSearchDebounce : undefined}
      onChange={onChange}
      style={style || {}}
      placeholder={placeholder || ''}
      className={className || undefined}
    >
      {data.map((val) => (
        <Select.Option
          key={val.value}
          value={val.value}
          style={{ fontFamily: rtl ? 'kurdishFont' : undefined }}
        >
          {val.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RemoteSelect;
