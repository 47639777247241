import {
    Button,
    Col,
    DatePicker,
    Form,
    Modal,
    Row,
    Select,
    TimePicker
  } from 'antd';
import { useAxiosGetTaskManagement, useAxiosPostTaskManagement } from '../../configs/axios';
import dayjs from 'dayjs';
import { merchantStatusOptions } from '../../configs/constants';
const { RangePicker } = DatePicker;

function EditMerchantStatusModal({
  merchant,
  visible,
  setVisible,
}) {


  const { request: requestChangeMerchantStatus } = useAxiosPostTaskManagement(
    `/merchants/change_merchant_status`,
  
  );
  const {
    data: merchantStatusEditReason,

    loading: loadingMerchantStatusEditReason
  } = useAxiosGetTaskManagement(`/merchants/merchant_closure_reasons`, {
    autoRun: true
  });

  const [form] = Form.useForm();
  const merchantStatus = Form.useWatch('merchant_status', form);
  const onSubmit = () => {
    form.validateFields().then(async (values) => {
      const range = values.temp_date;
      // range can not be more than 7 days
      if (range && range?.[1]?.diff(range?.[0], 'days') > 6) {
        form.setFields([
          {
            name: 'temp_date',
            errors: ['Range can not be more than 7 days']
          }
        ]);
        return;
      } else {
        form.setFields([
          {
            name: 'temp_date',
            errors: []
          }
        ]);

        const data = {
          merchant_id: merchant.merchant_id,
          working_status: values.merchant_status,
          reason: values.reason
        };
        if (values.temp_date) {
          data.start = dayjs(values.temp_date[0])?.format(
            'YYYY-MM-DD HH:mm:ss'
          );
          data.end = dayjs(values.temp_date[1])?.format('YYYY-MM-DD HH:mm:ss');
        }
        const res = await requestChangeMerchantStatus(data);
     
        if (res) {
          setVisible();
        }
      }
    });
  };

  return (
    <Modal
      onCancel={() =>
        setVisible()
      }
      footer={[
        <Button
          key="close"
          type="default"
          onClick={() => {
            setVisible();
          }}
        >
         Close
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          Submit
        </Button>
      ]}
      open={visible}
      title={'Edit Merchant Working Status'}
    >
      <Form
        initialValues={{
          merchant_status: merchant.merchant_status
        }}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message:'Please select a status'
                }
              ]}
              name={'merchant_status'}
              label={'Merchant Status'}
            >
              <Select
                options={merchantStatusOptions.map((status) => {
                  return {
                    value: status.value,
                    label: status.label
                  };
                })}
                width="100%"
                // make it large
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select a reason'
                }
              ]}
              name={'reason'}
              label={'Reason'}
            >
              <Select
                loading={loadingMerchantStatusEditReason}
                options={merchantStatusEditReason?.map((item) => ({
                  value: item.reason_title,
                  label: item.reason_title
                }))}
              />
            </Form.Item>
          </Col>
          {/* // 'closed','busy','additional','additional','specific','default','temp_closed' */}

          {merchantStatus === 'temp_closed' ||
          merchantStatus === 'closed' ||
          merchantStatus === 'busy' ||
          merchantStatus === 'additional' ||
          merchantStatus === 'specific' ||
          merchantStatus === 'temp_closed' ? (
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select a date'
                  }
                ]}
                name={'temp_date'}
                label={'Date'}
              >
                {merchantStatus === 'additional' ||
                merchantStatus === 'specific' ? (
                  <TimePicker.RangePicker
                    /// can not pick a date and before today
                    disabledDate={(current) => {
                      return current && current < dayjs().endOf('day');
                    }}
                    changeOnBlur={true}
                  />
                ) : (
                  <RangePicker
                    /// can not pick a date and before today
                    disabledDate={(current) => {
                      return current && current < dayjs().endOf('day');
                    }}
                    changeOnBlur={true}
                    showTime
                  />
                )}
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>
  );
}

export default EditMerchantStatusModal;
  