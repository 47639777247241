import React, { useEffect, useState } from 'react';
import { Card, Col, Drawer, Row, Statistic, Tag, Typography } from 'antd';
import { ArrowUpOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ChatStatistics = ({ statistics, height }) => {
  const [statisticsData, setStatisticsData] = useState([]);
  useEffect(() => {
    const data = [];
    if (statistics) {
      Object.keys(statistics).forEach((key) => {
        data.push({
          key,
          title: statistics[key].title,
          value: statistics[key].count,
          time: statistics[key].time,
          color: statistics[key].color,

        });
      });
    }
    setStatisticsData(data);
  }, [statistics]);
  return (
    <Row
      style={{

        width: '100%',
        height: height,
        maxHeight: height,
        minHeight: height,
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: '10px',
      }}
    >
      {statisticsData.map((statistic) => (
        <Col
          key={statistic.key}
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'left',
            alignContent: 'left',
            textAlign: 'left',
            flexDirection: 'column',
            border: '#7AB2D3 1px solid',
            borderRadius: '10px',
            paddingLeft: '10px',
            marginRight: '10px',
          }}
          span={2}
        >
          <p
            style={{
              fontSize: '10px',
              margin: 0,
            }}
          >
            {statistic.title}
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 'bold',
              fontSize: '15px',
              color: statistic.color,
            }}
          >
            {statistic.value}
            {statistic.time > 0 ? <span style={{
              fontSize: '10px',
            }}> ({statistic.time}m)</span> : null}
          </p>
        </Col>
      ))}
    </Row>
  );
};

export default ChatStatistics;
