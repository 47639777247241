//Rekar's Functions:

// a function to tell the timing of each status and the time passed since the status has been changed with the previous one
//needed order time columns
/*
order_date_added,
order_confirmedtime,
order_processed_time,
order_isreadeytime,
order_est_readytime,
order_forwardedtime,
order_driver_to_merchant,
order_driver_at_merchant,
order_driver_picked_up,
order_ontheway_time,
order_driver_at_customer,
order_completed_time,
order_declined_time,
order_rejected_time,
order_rejected_on_type,
*/

import {
  UilCheck,
  UilClipboardNotes,
  UilClock,
  UilCommentAltLines,
  UilEdit,
  UilEnvelopeSend,
  UilEye,
  UilEyeSlash,
  UilMap,
  UilMapMarker,
  UilShoppingBag,
  UilShoppingCartAlt,
  UilSpinnerAlt,
  UilStore,
  UilUsdCircle,
  UilUtensils,
  UilVolumeMute,
  UilVolumeUp,
} from '@iconscout/react-unicons';
import moment from 'moment';

const orderStatusConfig = {
  pending: {
    timestamp: ['order_date_added'],
    order_status: 'pending',
    time_column: 'order_date_added',
    shouldNotExists: ['order_confirmedtime'],
    title: 'Pending',
    icon: <UilSpinnerAlt />,
    bgColor: '#E4343E',
    textColor: 'white',
    threshold: 2,
    target: ['main'],
  },
  accepting: {
    timestamp: ['order_confirmedtime'],
    duration: {
      from: ['order_date_added'],
      to: ['order_confirmedtime'],
    },
    order_status: 'pending',
    time_column: 'order_confirmedtime',
    shouldExists: ['order_confirmedtime'],
    title: 'Accepting',
    icon: <UilEnvelopeSend />,
    bgColor: '#F8922E',
    textColor: 'white',
    threshold: 2,
    target: ['main', 'merchant'],
  },
  preparing: {
    timestamp: ['order_processed_time'],
    duration: {
      from: ['order_confirmedtime'],
      to: ['order_processed_time'],
    },
    order_status: 'processing',
    time_column: 'order_processed_time',
    shouldNotExists: ['order_isreadeytime'],
    title: 'Preparing',
    icon: <UilStore />,
    bgColor: '#1C71D8',
    textColor: 'white',
    threshold: 15,
    type: 'timeline',
    target: ['main', 'merchant', 'timeline'],
  },
  estPrepared: {
    timestamp: ['order_est_readytime'],
    duration: {
      from: ['order_processed_time'],
      to: ['order_est_readytime'],
    },
    order_status: 'processing',
    time_column: 'order_est_readytime',
    shouldExists: ['order_est_readytime'],
    shouldNotExists: ['order_isreadeytime'],
    title: 'Est. Prepared',
    icon: <UilClock />,
    bgColor: '#2EC27E',
    textColor: 'white',
    threshold: 15,
    target: ['merchant'],
  },
  prepared: {
    timestamp: ['order_isreadeytime'],
    duration: {
      from: ['order_processed_time'],
      to: ['order_isreadeytime'],
    },
    order_status: 'processing',
    time_column: 'order_isreadeytime',
    shouldExists: ['order_isreadeytime'],
    shouldNotExists: ['order_driver_picked_up'],
    title: 'Ready',
    icon: <UilUtensils />,
    bgColor: '#2EC27E',
    textColor: 'white',
    threshold: 15,
    type: 'timeline',
    isMain: true,
    target: ['main', 'merchant', 'timeline'],
  },
  toPickup: {
    timestamp: ['order_driver_to_merchant'],
    duration: {
      from: ['order_forwardedtime'],
      to: ['order_driver_to_merchant'],
    },
    order_status: 'processing',
    time_column: 'order_driver_to_merchant',
    shouldExists: ['order_driver_to_merchant'],
    shouldNotExists: ['order_driver_picked_up', 'order_driver_at_merchant'],
    title: 'To Pickup',
    icon: <UilShoppingBag />,
    bgColor: '#F8922E',
    textColor: 'white',
    threshold: 2,
    target: ['driver'],
  },
  atPickup: {
    timestamp: ['order_driver_at_merchant'],
    duration: {
      from: ['order_driver_to_merchant'],
      to: ['order_driver_at_merchant'],
    },
    order_status: 'processing',
    time_column: 'order_driver_at_merchant',
    shouldExists: ['order_driver_at_merchant'],
    shouldNotExists: ['order_driver_picked_up'],
    title: 'At Pickup',
    icon: <UilShoppingBag />,
    bgColor: '#F8922E',
    textColor: 'white',
    threshold: 10,
    target: ['driver', 'timeline'],
  },
  pickedUp: {
    timestamp: ['order_driver_picked_up'],
    duration: {
      from: ['order_driver_at_merchant'],
      to: ['order_driver_picked_up'],
    },
    order_status: 'processing',
    time_column: 'order_driver_picked_up',
    shouldExists: ['order_driver_picked_up'],
    title: 'Picked Up',
    icon: <UilShoppingBag />,
    bgColor: '#F8922E',
    textColor: 'white',
    threshold: 5,
    target: ['main', 'driver'],
  },
  toDropOff: {
    timestamp: ['order_ontheway_time'],
    duration: {
      from: ['order_driver_picked_up'],
      to: ['order_ontheway_time'],
    },
    order_status: 'ontheway',
    time_column: 'order_ontheway_time',
    shouldExists: ['order_ontheway_time'],
    shouldNotExists: ['order_driver_at_customer'],
    title: 'To Drop Off',
    icon: <UilShoppingBag />,
    bgColor: '#1C71D8',
    textColor: 'white',
    threshold: 5,
    type: 'timeline',
    target: ['main', 'driver', 'timeline'],
  },
  atDropOff: {
    timestamp: ['order_driver_at_customer'],
    duration: {
      from: ['order_ontheway_time'],
      to: ['order_driver_at_customer'],
    },
    order_status: 'ontheway',
    time_column: 'order_driver_at_customer',
    shouldExists: ['order_driver_at_customer'],
    title: 'At Drop Off',
    icon: <UilMapMarker />,
    bgColor: '#2EC27E',
    textColor: 'white',
    threshold: 14,
    type: 'timeline',
    target: ['main', 'driver', 'timeline'],
  },
  completed: {
    timestamp: ['order_completed_time'],
    duration: {
      from: ['order_date_added'],
      to: ['order_completed_time'],
    },
    order_status: 'completed',
    time_column: 'order_completed_time',
    shouldExists: ['order_completed_time'],
    shouldNotExists: ['order_rejected_on_type', 'order_rejected_time'],
    title: 'Completed',
    icon: <UilCheck />,
    bgColor: '#1C211D',
    textColor: 'white',
    threshold: 35,
    target: ['main', 'driver','timeline'],
  },
  canceled: {
    timestamp: ['order_declined_time'],
    duration: {
      from: ['order_date_added'],
      to: ['order_declined_time'],
    },
    order_status: 'canceled',
    time_column: 'order_declined_time',
    shouldExists: ['order_declined_time'],
    title: 'Canceled',
    icon: null,
    bgColor: '#ED333B',
    textColor: 'white',
    threshold: 0,
    target: ['main','timeline'],
  },
  rejected: {
    timestamp: ['order_completed_time', 'order_date_added'],
    duration: {
      from: ['order_date_added'],
      to: ['order_completed_time', 'order_date_added'],
    },
    order_status: 'completed',
    time_column: 'order_completed_time',
    shouldExists: ['order_rejected_on_type'],
    title: 'Rejected',
    icon: null,
    bgColor: '#ED333B',
    textColor: 'white',
    threshold: 0,
    target: ['main'],
  },
};

const getCurrentStatuses = (record) => {
  const currentStatus = [];
  for (const status in orderStatusConfig) {
    const statusData = orderStatusConfig?.[status];
    let exists = true;
    if (statusData && statusData?.shouldExists) {
      exists = statusData?.shouldExists?.every(
        (column) =>
          record?.[column] &&
          record?.[column] !== '0000-00-00 00:00:00' &&
          record?.[column] !== null &&
          record?.[column] !== '' &&
          record?.[column]?.length > 0,
      );
    }
    let notExists = true;
    if (statusData?.shouldNotExists) {
      notExists = statusData?.shouldNotExists?.every(
        (column) =>
          !record?.[column] ||
          record?.[column] === '0000-00-00 00:00:00' ||
          record?.[column] === null ||
          record?.[column] === '' ||
          record?.[column]?.length > 0,
      );
    }
    if (
      exists &&
      notExists &&
      record?.orderStatus === statusData?.order_status &&
      record[statusData?.time_column] &&
      record[statusData?.time_column] !== '0000-00-00 00:00:00' &&
      record[statusData?.time_column] !== null &&
      record[statusData?.time_column] !== ''
    ) {
      const timestamp = record[statusData?.time_column];
      currentStatus.push({
        status,
        time: timestamp,
        title: statusData?.title,
        icon: statusData?.icon,
        bgColor: statusData?.bgColor,
        textColor: statusData?.textColor,
        threshold: statusData?.threshold,
        target: statusData?.target,
      });
    }
  }

  return currentStatus;
};

const statusTiming = (record) => {
  // for array of timestamps, get the first one that is not null
  // so for each status, set the timestamp to the first not null value
  // and then duration is the difference between from and to
  // check all statuses
  // the data in timestamp and duration should not be '0000-00-00 00:00:00'
  // if it is, then it should be null
  const statusesData = [];
  for (const status in orderStatusConfig) {
    if (
      orderStatusConfig?.[status]?.target &&
      orderStatusConfig[status].target.includes('timeline')
    ) {
      const statusData = orderStatusConfig?.[status];
      const timestamp = statusData?.timestamp?.find(
        (timestamp) =>
          record?.[timestamp] &&
          record?.[timestamp] !== '0000-00-00 00:00:00' &&
          record?.[timestamp] !== null &&
          record?.[timestamp] !== '',
      );
      const from = statusData?.duration?.from?.find(
        (timestamp) =>
          record?.[timestamp] &&
          record?.[timestamp] !== '0000-00-00 00:00:00' &&
          record?.[timestamp] !== null &&
          record?.[timestamp] !== '',
      );
      const to = statusData?.duration?.to?.find(
        (timestamp) =>
          record?.[timestamp] &&
          record?.[timestamp] !== '0000-00-00 00:00:00' &&
          record?.[timestamp] !== null &&
          record?.[timestamp] !== '',
      );

      const toData = record?.[to];
      const fromData = record?.[from];

      const toTime = moment(toData).format('YYYY-MM-DD HH:mm:ss').toString();
      const fromTime = moment(fromData)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString();

      if (timestamp) {
        const duration =
          from && to
            ? Math.ceil(moment(toTime).diff(moment(fromTime), 'minutes', true))
            : null;

        statusesData.push({
          status,
          time: timestamp || null,
          duration: duration || null,
          fromData,
          toData,
          timestampData: record?.[timestamp],
          type: statusData?.type,
          title: statusData?.title,
          bgColor: statusData?.bgColor,
          textColor: statusData?.textColor,
          threshold: statusData?.threshold,
        });
      }
    }
  }

  return statusesData;
};

//get last current status by target
const getLastCurrentStatus = (record, target = 'main') => {
  const currentStatuses = getCurrentStatuses(record);
  // console.log('🚀 ~ getLastCurrentStatus ~ currentStatuses:', currentStatuses);
  //latest status of the array with target includes main
  const targetStatus = currentStatuses.filter((status) =>
    status?.target?.includes(target),
  );
  const lastStatus = targetStatus[targetStatus.length - 1];
  return lastStatus;
};

// a function to decide the color based on two threshold, first is orange then red, else grey
const getColor = (th1, th2, value) => {
  if (value < th1) {
    return 'grey';
  } else if (value < th2) {
    return 'orange';
  } else {
    return 'red';
  }
};

const showOrderPaymentType = (orderTypeId) => {
  const paymentTypeId = parseInt(orderTypeId);
  switch (paymentTypeId) {
    case 0:
    case 2:
      return 'Cash';
    case 1:
      return 'Fastpay';
    case 3:
      return 'LezzooPay';
    case 4:
      return 'Credit Card';
    case 5:
      return 'Zain Cash';
    case 6:
      return 'Asia Hawala';
    case 7:
      return 'NassPay';
    case 11:
      return 'FIB';
    default:
      return null;
  }
};

export {
  orderStatusConfig,
  getCurrentStatuses,
  statusTiming,
  getLastCurrentStatus,
  getColor,
  showOrderPaymentType,
};
