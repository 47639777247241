import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAxiosPost } from '../../configs/axios';
import { CheckCircleOutlined } from '@ant-design/icons';
import useLocalStorage from '../../configs/localStorage';

const ResolveOrderModal = ({ setOpen, open }) => {
  const { request: requestSolveIssue, loading } = useAxiosPost(
    '/orders/solve_issue',
    { autoRun: false },
  );
  const [auth] = useLocalStorage('auth', null);

  const onClose = () => {
    setOpen(null);
  };
  const onFinish = async (values) => {
    try {
      // const body = {
      //   orderId: open.orderId,
      //   issueCode: values.issue,
      //   description: values.description,
      // };
      // await requestSolveIssue(body);
      message.success('Issue Solved');
    } catch {
      message.error('Failed to Solve the issue');
    }
  };
  const { TextArea } = Input;
  const columns = [
    {
      title: 'User',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Date',
      dataIndex: 'create_at',
      key: 'create_at',
      render: (text) => dayjs(text).format('YYYY-MMM-DD'),
    },
    {
      title: 'Issue',
      dataIndex: 'issue',
      key: 'issue',
    },
    {
      title: 'Solve',
      dataIndex: 'solve',
      key: 'solve',
      render: (text) =>
        text ? (
          <Tag color={'green'}>Solved</Tag>
        ) : (
          <Tag color={'red'}>Not Solved</Tag>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        return (
          <Form onFinish={onFinish} initialValues={{ issue: record.issue }}>
            <Row gutter={[10]}>
              <Col>
                <Form.Item name={'description'}>
                  <TextArea
                    className="textarea-border"
                    style={{
                      borderRadius: '5px !important',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={'issue'} hidden>
                  <Input className="textarea-border" hidden={true} />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  loading={loading}
                  icon={<CheckCircleOutlined />}
                  type="primary"
                  style={{
                    backgroundColor: '#5cb85c	',
                  }}
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
        );
      },
    },
  ];
  const isSuperAdmin = auth.user.isSuperAdmin;
  const isSupervisorAgent = auth.user.isSupervisorAgent;
  // if the user is super admin to supervisor the they can see everything else they can only see their issues
  const data =
    isSuperAdmin || isSupervisorAgent
      ? open?.orderAssignedToAgents
      : open?.orderAssignedToAgents.filter(
          (item) => item._id == auth?.user?._id,
        );
  return (
    <Modal width={900} footer={null} open={open} onCancel={onClose}>
      <Table columns={columns} dataSource={data} pagination={false} />
    </Modal>
  );
};
export default ResolveOrderModal;
