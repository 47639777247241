import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import React from 'react';
import { useAxiosGet, useAxiosPost } from '../../configs/axios';
import useLocalStorage from '../../configs/localStorage';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import CreateTicketComponent from './CreateTicketComponent'
const MerchantInformationModal = (props) => {
  const { status, setStatus, selectedOrder } = props;
    const [open, setOpen] = React.useState(false);
  const [workingStatus, setWorkingStatus] = React.useState();
  const [closeType, setCloseType] = React.useState();
  const [closeDateType, setCloseDateType] = React.useState();
  const [auth, setAuth] = useLocalStorage('auth', null);
  const canMerchantClosure = auth?.user?.canMerchantClosure;

  const { request: closeMerchantRequestP, loading: merchantCloseLoading } =
    useAxiosPost('/merchants/merchantClosure');

  const { data: reasons, loading: reasonLoading } = useAxiosGet(
    '/merchants/closeReasons',
    {
      autoRun: true,
    },
  );

  const { data: dataAccountManager } = useAxiosGet(
    '/merchants/getAccountManager',
    {
      merchantId: selectedOrder?.merchant?.merchant_id,
      autoRun: true,
    },
  );
  const onDrawerClose = () => {
    setOpen(false);
  };
  const onDrawerOpen = () => {
    setOpen(true);
  };
  const workingStatusOptions = [
    <Select.Option value="busy">Busy</Select.Option>,
    <Select.Option value="closed">Closed</Select.Option>,
  ];

  const onWorkingStatusChange = (value) => {
    setWorkingStatus(value);
  };
  const onCloseTypeChange = (value) => {
    setCloseType(value);
  };
  const onCloseDateTypeChange = (value) => {
    setCloseDateType(value);
  };
  const isMins = (value, dateValue) => {
  try {
    const min = parseInt(value, 10);
    return {value:min,type:'numOfMins'};
  } catch (error) {
    return {value:dateValue,type:closeDateType}
  }
}
  const onFormSubmit = async (values) => {
    //   {
    //     "workingStatus": "closed",
    //     "closingType": "temporarily",
    //     "type": "numOfHours",
    //     "numOfHours": 4,
    //     "reason": "Vendor Behaviour",
    //     "params": {
    //         "numOfHours": 4,
    //         "numOfMins": null,
    //         "type": "numOfHours"
    //     }
    // }
    const formData = {
      merchantId: selectedOrder.merchant.merchant_id,
      workingStatus: values.workingStatus,
      closingType: values.closeType,
      // type: closeDateType,
      reason: values.reason,
      ...isMins(closeDateType,values.closeDate),
    };
    try {
      const value = await closeMerchantRequestP(formData);
      message.success(value?.message);
      setOpen(false);
    } catch (err) {
      message.error(
        'Something went wrong! Please check your access and try again later!',
      );
    }
  };

  return (
    <>
      <Modal
        title="Merchant Information"
        destroyOnClose
        getContainer={false}
        open={status}
        footer={null}
        onCancel={() => {
          setStatus(false);
        }}
        centered
        width={600}
        height={600}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
          
          }}
        >
          <Avatar
            size={90}
            src={<Image src={selectedOrder.merchant.merchant_logo} />}
          />
          <h2
            style={{
              textAlign: 'center',
            }}
          >
            {selectedOrder.merchant.merchant_name.en}
            <small
              style={{
                fontSize: '13px',
                color: 'gray',
                marginLeft: '10px',
              }}
            >
              ({selectedOrder.merchant.merchant_id})
            </small>
          </h2>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                fontSize: '16px',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
              }}
            >
              <b>Phone Number</b>
              <p
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(
                    selectedOrder.merchant.merchant_phone,
                  );
                  message.success('Copied to clipboard');
                }}
              >
                {selectedOrder.merchant.merchant_phone}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                fontSize: '16px',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                marginBottom:10
              }}
            >
              <b>City</b>
              <p>{selectedOrder.merchant.merchant_city}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                fontSize: '16px',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                marginBottom:10
              }}
            >
              <b>Order Manager</b>
              <p>{dataAccountManager?.email}</p>
            </div>
          </div>
          
        <CreateTicketComponent
            issue_source_type={'order'}
            issue_source_id={selectedOrder.orderId}
            issue_type='merchant'
          />
         
          {canMerchantClosure && (
            <Button onClick={onDrawerOpen} type="primary" block>
              Close merchant
            </Button>
          )}
        </div>
      </Modal>
      <Drawer
        title="Close Merchant"
        placement={'right'}
        width={500}
        closable={true}
        onClose={onDrawerClose}
        open={open}
        key={'left'}
      >
        <Form
          onFinish={onFormSubmit}
          size="large"
          wrapperCol={{ span: 24 }}
          layout="vertical"
        >
          <Row>
            <Col span={11}>
              <Form.Item
                name="workingStatus"
                label="Working Status"
                rules={[
                  {
                    required: true,
                    message: 'Please select working status',
                  },
                ]}
              >
                <Select
                  placeholder="Select working status"
                  onChange={onWorkingStatusChange}
                  style={{ width: '100%' }}
                >
                  {workingStatusOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={11}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select close type',
                  },
                ]}
                name="closeType"
                label="Close Type"
              >
                <Select
                  placeholder="Select close type"
                  onChange={onCloseTypeChange}
                  style={{ width: '100%' }}
                >
                  <Select.Option value="temporary">Temporary</Select.Option>
                  {workingStatus == 'closed' && (
                    <Select.Option value="permanent">Permanent</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            {closeType == 'temporary' && (
              <Col span={11} offset={1} pull={1}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please select close date type',
                    },
                  ]}
                  name="closeDateType"
                  label="Close Date Type"
                >
                  <Select
                    placeholder="Select close date type"
                    onChange={onCloseDateTypeChange}
                    style={{ width: '100%' }}
                  >
                    {workingStatus == 'busy' && (
                      <>
                      <Select.Option value="10">10 mins</Select.Option>
                      <Select.Option value="15">15 mins</Select.Option>
                      <Select.Option value="20">20 mins</Select.Option>
                      <Select.Option value="25">25 mins</Select.Option>
                      <Select.Option value="30">30 mins</Select.Option>
                      </>
                    )}
                    <Select.Option value="today">Today</Select.Option>
                    <Select.Option value="numOfHours">Hours</Select.Option>
                    <Select.Option value="numOfDays">days</Select.Option>
                    {workingStatus == 'closed' && (
                      <Select.Option value="custom">custom date</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {closeType == 'temporary' &&
              (closeDateType == 'numOfHours' ||
                closeDateType == 'numOfDays' ||
                closeDateType == 'custom') && (
                <Col span={11}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a number',
                      },
                    ]}
                    label="Close Date"
                    name="closeDate"
                  >
                    <Input
                      type={closeDateType == 'custom' ? 'date' : 'number'}
                    />
                  </Form.Item>
                </Col>
              )}
            {workingStatus == 'closed' ? (
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please select a reason',
                    },
                  ]}
                  name="reason"
                  label="Reason"
                >
                  <Select
                    placeholder="Select a reason"
                    loading={reasonLoading}
                    style={{ width: '100%' }}
                  >
                    {reasons.map((reason) => (
                      <Select.Option value={reason.reason_title}>
                        {reason.reason_title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ):(
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please select a reason',
                    },
                  ]}
                  name="reason"
                  label="Reason"
                >
                 <Input/>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Button
            // disabled={merchantCloseLoading}
            loading={merchantCloseLoading}
            htmlType="submit"
            type="primary"
            block
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default MerchantInformationModal;
