import { Modal, Row, Col, List, Tabs, Drawer } from 'antd';
import VirtualList from 'rc-virtual-list';
import classes from './style/task-board.module.css';
import { useAxiosGetChat, useAxiosTaskManagementGetV2 } from '../../configs/axios';
import ChatRoomList from '../Chatv2/ChatRoomList';
import ResolvedChatRoomList from '../Chatv2/ResolvedChatRoomList';

const CompletedTickets = ({
  isOpen,
  onClose,
  renderDone,

  
}) => {
  const {
    data: dataTasks,
    request: getTasks,
    loading: getTaskLoading,
  } = useAxiosTaskManagementGetV2(`/OperationTasks/v2`, {
    autoRun: true,
    status: 'done',
  }); // Define the tab content using the items prop


  const tabItems = [
    {
      key: '1',
      label: 'Done Tickets',
      children: (
        <div
          style={{ maxWidth: '700px', margin: '0 auto' }}
          className={classes.taskColumn}
        >
          <div className={`${classes.stickyHeader} ${classes.doneHeader}`}>
            <Row justify="space-between">
              <Col>
                <h3>Done</h3>
              </Col>
              <Col className={classes['done-count']}>
                {dataTasks?.done?.length}
              </Col>
            </Row>
          </div>
          <div className={classes.columnContainer}>
            <List>
              <VirtualList
                data={dataTasks?.done}
                height={window.innerHeight - 210}
                itemHeight={dataTasks?.isGrouped ? 100 : 230}
                itemKey={
                  dataTasks?.isGrouped ? 'group_id' : 'operation_task_id'
                }
              >
                {renderDone}
              </VirtualList>
            </List>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Chat',
      children: (
        <div>
          <h3>Chat Content</h3>
          <ResolvedChatRoomList
            height="calc(88vh)"
            activeFilters={{} }
        
          />
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title="Completed Tasks & Chat"
      open={isOpen}
      onCancel={onClose}
      onClose={onClose}
      footer={null}
      width="40%"
    >
      <Tabs items={tabItems} defaultActiveKey="1" />
    </Drawer>
  );
};

export default CompletedTickets;
