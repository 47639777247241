import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAxiosPostChat } from '../../configs/axios';
import useLocalStorage from '../../configs/localStorage';

const BookmarkChat = ({ roomId, setSelectedChatroom, selectedChatroom }) => {
  const [auth] = useLocalStorage('auth', null);
  const userID = auth?.user?._id;

  const { request: resolveChatroom } = useAxiosPostChat('/chats/markRoom');
  const isRoomBookmarked = () => {
    return selectedChatroom?.roomFavoriteBy?.includes(userID);
  };
  const [isMarked, setIsMarked] = useState(isRoomBookmarked());
 

  const handleBookmark = async () => {
    let updatedBookmarkedRooms = selectedChatroom?.roomFavoriteBy || [];
    if (isRoomBookmarked()) {
      updatedBookmarkedRooms = updatedBookmarkedRooms.filter(
        (id) => id !== userID,
      );
    } else {
      updatedBookmarkedRooms.push(userID);
    }

    setSelectedChatroom({
      ...selectedChatroom || {},
      roomFavoriteBy: updatedBookmarkedRooms || [],
    });

    setIsMarked(!isMarked);

    await resolveChatroom({
      roomId,
      chatSource: selectedChatroom?.roomType,
    });
  };

  return (
    <Tooltip
      title={isRoomBookmarked() ? 'Remove Bookmark' : 'Bookmark this Chat'}
    >
      <div
        style={{
          width: 23,
          height: 23,
          cursor: 'pointer',
        }}
        onClick={handleBookmark}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="bookmark-full"
        >
          <path
            fill={isRoomBookmarked() ? '#09B231' : '#000'}
            strokeWidth={isRoomBookmarked() ? '1' : ''}
            stroke={isRoomBookmarked() ? '#09B231' : '#000'}
            d="M18,2H6A1,1,0,0,0,5,3V21a1,1,0,0,0,1.65.76L12,17.27l5.29,4.44A1,1,0,0,0,18,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,19,21V3A1,1,0,0,0,18,2ZM17,18.86,12.64,15.2a1,1,0,0,0-1.28,0L7,18.86V4H17Z"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export default BookmarkChat;
