import { Avatar, Modal, message } from 'antd';
import React, { useContext } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { OrderContext } from '../../pages/Home';
import moment from 'moment';
import CreateTicketComponent from './CreateTicketComponent';

const DriverInformationModal = (props) => {
  const { status, setStatus } = props;
  const { selectedOrder } = useContext(OrderContext);
  return (
    <Modal
      title="Driver Information"
      destroyOnClose
      getContainer={false}
      open={status}
      footer={null}
      onCancel={() => {
        setStatus(false);
      }}
      centered
      width={600}
      height={600}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Avatar size={90} icon={<UserOutlined />} />
        <h2
          style={{
            textAlign: 'center',
          }}
        >
          {selectedOrder.driver.firstName} {selectedOrder.driver.lastName}
          {/* <br />
          <small
            style={{
              fontSize: "13px",
              color: "gray",
              marginLeft: "10px",
            }}
          >
            {selectedOrder.driver.courierId}
          </small> */}
        </h2>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <b>Phone Number</b>
            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(selectedOrder.driver.phone);
                message.success('Copied to clipboard');
              }}
            >
              {selectedOrder.driver.phone}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <b>City</b>
            <p>{selectedOrder.driver.cityName}</p>
          </div>
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
            }}
          >
            <b>Preffered Zone</b>
            <p>{selectedOrder.driver.preferredRange.split('_').join(' ')}</p>
          </div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              fontSize: '16px',
              alignItems: 'flex-end',
              alignContent: 'flex-end',
              marginBottom:10,
            }}
          >
            <b>Joined At</b>
            <p>{moment(selectedOrder.driver.createdAt).format('DD/MM/YYYY')}</p>
          </div>
        </div>
        <CreateTicketComponent
          issue_source_type={'order'}
          issue_source_id={selectedOrder.orderId}
          issue_type="driver"
          />
      </div>
    </Modal>
  );
};

export default DriverInformationModal;
