import React, { useState } from 'react';
import {
  Modal,
  Input,
  Card,
  Pagination,
  List,
  Avatar,
  Row,
  Tooltip,
  message,
  Tag,
  Button,
  notification,
  Col,
  Form,
  Select,
} from 'antd';
import style from './styles/drivers-modal.module.css';
import { useAxiosGet, useAxiosPost } from '../../configs/axios';

const { Search } = Input;

const tagsData = [
  { key: 'free', label: 'Free' },
  { key: 'moto', label: 'Moto' },
  { key: 'cars', label: 'Car' },
  { key: 'truck', label: 'Truck' },
  { key: 'electric', label: 'Electric' },
];

const TagItem = ({ tag, selectedTag, setSelectedTag }) => (
  <Tag
    color={selectedTag === tag.key ? 'blue' : 'default'}
    onClick={() => setSelectedTag(selectedTag === tag.key ? '' : tag.key)}
    style={{ cursor: 'pointer' }}
  >
    {tag.label}
  </Tag>
);

const AssignCourierModal = ({
  onCancel,
  cityCode,
  orderId,
  courierSystemOrderId,
  orderLatitude,
  orderLongitude,
  getAllOrders,
  assignedDriver,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [driversPerPage] = useState(4);
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null); // State to store selected driver
  const [reassignReason, setReassignReason] = useState();

  const { data: riders, loading: riderLoading } = useAxiosGet(
    '/couriers/assign/list',
    {
      autoRun: true,
      orderId,
      orderCityCode: cityCode,
      orderLatitude,
      orderLongitude,
    },
  );

  const { request: requestAssignDriver, loading: loadingAssignDriver } =
    useAxiosPost('/couriers/assign', {
      autoRun: true,
    });
  const filteredData = riders?.filter((rider) => {
    const riderNameMatches = rider.courierName
      .replaceAll(' ', '')
      .toLowerCase()
      .includes(searchTerm.replaceAll(' ', '').toLowerCase());
    const riderMatchPhoneNumber = rider.phone.includes(searchTerm);

    const tagMatch =
      !selectedTag ||
      (selectedTag === 'free' && rider.currentActiveOrders === 0) ||
      (selectedTag === 'moto' &&
        rider.vehicleTypeName.toLowerCase() === 'moto') ||
      (selectedTag === 'cars' &&
        rider.vehicleTypeName.toLowerCase() === 'cars') ||
      (selectedTag === '1order' && rider.currentActiveOrders === 1) ||
      (selectedTag === '2order' && rider.currentActiveOrders === 2) ||
      (selectedTag === 'truck' &&
        rider.vehicleTypeName.toLowerCase() === 'truck');

    return (riderNameMatches || riderMatchPhoneNumber) && tagMatch;
  });

  const indexOfLastDriver = currentPage * driversPerPage;
  const indexOfFirstDriver = indexOfLastDriver - driversPerPage;
  const currentDrivers = filteredData?.slice(
    indexOfFirstDriver,
    indexOfLastDriver,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const copyToClipboard = (phone) => {
    navigator.clipboard.writeText(phone).then(() => {
      message.success('Phone number copied to clipboard');
    });
  };

  const selectDriver = (driver) => {
    setSelectedDriver(driver);
  };

  const onFinish = () => {
    const body = {
      courierOrderId: courierSystemOrderId,
      courierId: selectedDriver.courierId,
      reason: reassignReason,
    };
    requestAssignDriver(body).then(() => {
      getAllOrders();
      setSelectedDriver(null);
      onCancel();
      notification.success({
        message: 'Successfully Assigned ',
        position: 'top-right',
      });
    });
  };

  const reassignReasons = [
    'Driver refused to take the order',
    "Driver can't delivery order",
    'More Suitable Driver.',
  ];

  const renderItem = (rider) => (
    <List.Item
      key={rider.courierId}
      onClick={() => selectDriver(rider)} // Call selectDriver function when clicked
    >
      <Card
        className={`${style.customCard} ${
          selectedDriver === rider ? style.selectedDriver : ''
        }`}
        style={{ width: '100%', padding: 0 }}
      >
        <Row align="middle">
          <Tooltip title="Copy phone number">
            <Avatar
              size={50}
              src={rider.avatarLink}
              shape="square"
              alt="Avatar"
              style={{
                marginRight: '10px',
                backgroundColor: '#FE6E4F',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(rider.phone);
              }}
            >
              {rider.avatarLink
                ? ''
                : `${rider.courierName
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}`}
            </Avatar>
          </Tooltip>
          <div>
            <h4>{rider.courierName}</h4>
            <p>
              {rider.currentActiveOrders > 0 ? (
                <span className={style.activeOrders}>
                  {rider.currentActiveOrders} active order(s)
                </span>
              ) : (
                <span className={style.free}>Free</span>
              )}
            </p>
            <p>{`${rider.vehicleTypeName} `}</p>
            <p>{`${rider.distance} km`}</p>
            <p> Last Online {rider.locationUpdatedAt}</p>

            {rider.break && (
              <div
                className={style.breakInfo}
                style={{
                  backgroundColor: '#f1f1f1',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  maxWidth: '200px',
                  margin: '5px 0',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#00796b',
                    margin: '0',
                    textAlign: 'center',
                    lineHeight: '1.5',
                  }}
                >
                  Break:
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#00796b',
                      fontWeight: '600',
                      margin: '0 5px',
                    }}
                  >
                    {new Date(rider.break.from).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#333',
                      fontWeight: '400',
                    }}
                  >
                    {' '}
                    -{' '}
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#00796b',
                      fontWeight: '600',
                      margin: '0 5px',
                    }}
                  >
                    {new Date(rider.break.to).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                  </span>
                </p>
              </div>
            )}
          </div>
        </Row>

        {assignedDriver && selectedDriver && selectedDriver === rider ? (
          <Row
            style={{
              width: '100%',
              padding: 0,
              justifyContent: 'center',
              margin: 0,
              height: 70,
            }}
          >
            <Form.Item
              name="reason"
              style={{ width: '100%', padding: 0 }}
              rules={[{ required: true, message: 'Please select a reason' }]}
            >
              <Select
                placeholder="Re-assign Reason"
                onChange={(value) => {
                  setReassignReason(value);
                }}
                value={reassignReason}
              >
                {reassignReasons.map((val) => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
        ) : null}
      </Card>
    </List.Item>
  );

  return (
    <Modal
      open={true}
      onCancel={onCancel}
      loading={loadingAssignDriver}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onFinish}>
          OK
        </Button>,
      ]}
      width={550}
      title="Assign Courier"
    >
      <Search
        placeholder="Search riders..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div style={{ margin: '10px 0' }}>
        {tagsData.map((tag) => (
          <TagItem
            key={tag.key}
            tag={tag}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
        ))}
      </div>
      {assignedDriver && (
        <div style={{ margin: '10px 0' }}>
          <h4>Assigned Driver</h4>
          <Card
            className={`${style.customCard}`}
            style={{ width: '100%', padding: 0, opacity: 0.5 }}
          >
            <Row align="middle">
              <Tooltip title="Copy phone number">
                <Avatar
                  size={50}
                  src={assignedDriver.avatarLink}
                  shape="square"
                  alt="Avatar"
                  style={{
                    marginRight: '10px',
                    backgroundColor: '#FE6E4F',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(assignedDriver.phone);
                  }}
                >
                  {assignedDriver.avatarLink
                    ? ''
                    : `${assignedDriver.fullName
                        .split(' ')
                        .map((n) => n[0])
                        .join('')}`}
                </Avatar>
              </Tooltip>
              <div>
                <h4>{assignedDriver.fullName}</h4>
              </div>
            </Row>
            {/* <Row 
        style={{ 
          width: '100%', 
          padding: 0,
          justifyContent: 'center',
          margin:0,
          height: 70,
           }} 
           >
            <Form.Item 
            name="reason" 
            style={{ width: '100%', padding: 0 }}
            rules={[{ required: true, message: 'Please select a reason' }]}
            >
              <Select
                placeholder="Re-assign Reason"
                onChange={(value) => {
                  setReassignReason(value);
                }}
                value={reassignReason}
              >
                {reassignReasons.map((group) => (
                  <Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        </Row> */}
          </Card>
        </div>
      )}
      <List
        dataSource={currentDrivers || []}
        renderItem={renderItem}
        loading={riderLoading}
        pagination={{
          pageSize: driversPerPage,
          current: currentPage,
          total: filteredData?.length,
          onChange: paginate,
          position: 'top',
        }}
      />
    </Modal>
  );
};

export default AssignCourierModal;
