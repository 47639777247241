
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
  useContext,
} from 'react';
import { Col, Row, Typography, ConfigProvider } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import useLocalStorage from '../configs/localStorage';
import { axiosChatInstance } from '../configs/axios';
import ChatRoomList from '../components/Chatv2/ChatRoomList';
import { useHookstate } from '@hookstate/core';
import chatStates from '../components/Chatv2/chatStates';
import MessageList from '../components/Chatv2/MessageList';
import SocketManager from '../configs/newSocket';
import { CHAT_URL } from '../configs/constants';
import { generateFiltersData } from '../components/Chatv2/ChatFilters';
import ChatFilterDrawer from '../components/Chatv2/ChatFilterDrawer';
import ChatStatistics from '../components/Chatv2/ChatStatistics';
import ChatSidePanel from '../components/Chatv2/ChatSidePanel';
import DrawerChat from '../components/Chatv2/DrawerChat';
import TicketOnChat from '../components/TaskBoard/TicketOnChat';
import CompletedTickets from '../components/TaskBoard/CompletedTicketsModal';
import RenderTaskWithChat from '../components/TaskBoard/RenderTaskWithChat';
import ToInProgress from '../components/TaskBoard/icons/ToInProgress';

const CUSTOMER_CHATS = 'customer_agent,customer_general';
const MERCHANT_CHATS = 'merchant_agent';
const DRIVER_CHATS = 'orders,couriers';

const { Text } = Typography;

function ChatAndTicketPage() {
  const [auth] = useLocalStorage('auth', null);
  const chatRooms = useRef([]);
  const updateQueue = useRef([]);
  const socket = useRef(null);
  const initValue = {
    merchantChatRooms: [],
    languageChatRooms: [],
    issueTagChatRooms: [],
    agentChatRooms: [],
    unreadCount: 0,
    lastAction: 0,
    renderCount: 0,
    source: CUSTOMER_CHATS,
    selectedOrder: null,
    customer: null,
    orderRefundModalVisible: false,
    orderCartModalVisible: false,
    editOrderModalVisible: false,
    changeOrderStatusModalVisible: false,
    orderMapModalVisible: false,
    selectedMessageTab: '1',
  };

  const { theme } = useContext(ConfigProvider.ConfigContext);

  const { token } = theme;

  const backgroundColor = token.colorBgBase || '#E9EDF5';

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );
  const selected_chatroom = useHookstate(
    chatStates.selectedChatRoomState.selected_chatroom,
  );

  const [activeFilters, setActiveFilters] = useState({
    roomType: null,
    roomName: null,
    roomLanguage: null,
    issueTags: null,
    roomAssignedToName: null,
  });


  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

  const toggleFilterDrawer = () => {
    setFilterDrawerVisible(!filterDrawerVisible);
  };



  const generateFilters = useCallback(() => {
    const data = generateFiltersData(chatRooms);
    setState({
      merchantChatRooms: data.merchantRooms,
      languageChatRooms: data.languageRooms,
      issueTagChatRooms: data.issueTagRooms,
      agentChatRooms: data.agentRooms,
    });
  }, [chatRooms.current]);

  const triggerLastAction = () => {
    const timestamp = new Date().getTime();
    setState((prev) => {
      return {
        ...prev,

        lastAction: timestamp,
        renderCount: prev.renderCount + 1,
      };
    });
  };

  const fetchData = async (search = null) => {
    try {
      const response = await axiosChatInstance.get('/chats/v2/agents/rooms', {
        params: {
          chatSources: state.source,
          search: search,
        },
      });
      if (Array.isArray(response.data)) {
        chatRooms.current = response.data;
        sortChatRooms();
        triggerLastAction();
      } else {
        console.error('Response data is not an array', response.data);
      }
    } catch (error) {
      console.error('There was an error fetching chat rooms!', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [state.source]);

  const processQueue = useCallback(() => {
    while (updateQueue.current.length > 0) {
      const update = updateQueue.current.shift();
      update();
      triggerLastAction();
    }
  }, [updateQueue.current, chatRooms.current, triggerLastAction]);

  const enqueueUpdate = useCallback(
    (updateFn) => {
      updateQueue.current.push(updateFn);
      processQueue();
    },
    [processQueue, updateQueue.current],
  );

  const updateRoomData = useCallback(
    (roomId, count, message) => {
      enqueueUpdate(() => {
        if (!Array.isArray(chatRooms.current)) {
          console.error('prevChatrooms is not an array', chatRooms.current);
          chatRooms.current = [];
          return [];
        }

        const d = chatRooms.current.map((room) => {
          if (room._id === roomId) {
            const newChat = {
              ...room,
              countMessages: count > 0 ? count : room.countMessages,
              lastMessage: message?.lastMessage || room.lastMessage,
              lastMessageTime: message?.lastMessageTime || room.lastMessageTime,
              agentLastMessageAt: message?.agentLastMessageAt || room.agentLastMessageAt,
              lastMessageSource:
                message?.lastMessageSource || room.lastMessageSource,
              lastMessageType: message?.lastMessageType || room.lastMessageType,
              issueTags: message?.issueTags || room.issueTags,
            };
            return newChat;
          }
          return room;
        });

        chatRooms.current = d;
        sortChatRooms();
      });
    },
    [chatRooms.current, enqueueUpdate],
  );

  const updateCounter = useCallback(
    (room) => {
      const counter = room.messageSource !== 'agent' ? room.counter : 0;
      if (
        !room.chatRoomId ||
        !room?.isForMessageSeen ||
        !state.source.includes(room.chatSource)
      )
        return;

      //console.log('updateCounter:', room.chatRoomId, counter, room);

      updateRoomData(room.chatRoomId, counter, room);
    },
    [updateRoomData],
  );

  const sortChatRooms = useCallback(() => {
    enqueueUpdate(() => {
      if (!Array.isArray(chatRooms.current)) {
        console.error('prevChatrooms is not an array', chatRooms.current);
        chatRooms.current = [];
        return [];
      }
      //sort by last message is customer and last message time, so agent answered will be after customer
      const d = _.orderBy(
        chatRooms.current,
        [
          (room) => (room.lastMessageSource === 'customer' ? 1 : 0),
          (room) => room.lastMessageTime,
        ],
        ['desc', 'desc'],
      );
      //check for duplicates
      const unique = d.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i,
      );
      chatRooms.current = unique;

      const unreadCount =
        unique.filter((room) => room.countMessages > 0).length || 0;
      setState({ unreadCount: unreadCount });
      generateFilters();
      //console.log('chatRooms:', chatRooms.current.length);
    });
  }, [chatRooms.current, enqueueUpdate]);

  const hiddenMessageRecepients = ['system', 'bot'];

  const onMessageReceived = (message) => {
  const userID = auth?.user?._id;

    const shouldUserSeeMessage =
    auth.user.isMonitorAgent ||
    auth.user.isSuperAdmin ||
    auth.user.isSupervisorAgent;
  const isRoomAssignedToUser =
  message?.chatRoom?.roomAssignedTo?.toString() ===
    userID?.toString();
  if (!(isRoomAssignedToUser || shouldUserSeeMessage)) {
    return;
  }
    // if(auth?.user?._id !== message.roomAssignedTo) return;
    if (
      !message.chatRoomId ||
      !state.source.includes(message.chatSource) ||
      hiddenMessageRecepients.includes(message.messageSource)
    )
      return;
    const chatRoom = chatRooms.current.find((room) => {
      return room._id === message.chatRoomId;
    });
    if (
      chatRoom &&
      chatRoom._id === message.chatRoomId &&
      chatRooms.current?.length > 0
    ) {
      //console.log('update:', message.chatRoomId, message.text);
      updateRoomData(message.chatRoomId, 0, message);
    } else {
      //console.log('new:', message.chatRoomId, message.text);

      enqueueUpdate(() => {
        if (!Array.isArray(chatRooms.current)) {
          console.error('prevChatrooms is not an array', chatRooms.current);
          chatRooms.current = [message.chatRoom];
        }
        chatRooms.current = [{ ...message.chatRoom }, ...chatRooms.current];
        sortChatRooms();
      });
    }
  };

  const resolveChatRoom = useCallback(
    (chatRoom) => {
      try {
        if (!chatRoom?.roomId) {
          console.error('resolveChatRoom chatRoom is undefined');
          return;
        }
        enqueueUpdate(() => {
          if (!Array.isArray(chatRooms.current)) {
            console.error('prevChatrooms is not an array', chatRooms.current);
            chatRooms.current = [];
            return [];
          }
          const toBeResolved = chatRooms.current.find(
            (room) => room._id == chatRoom.roomId,
          );

          if (!toBeResolved) return;

          const d = _.filter(chatRooms.current, (room) => {
            return room._id != chatRoom.roomId;
          });

          chatRooms.current = [...d];
          const selectedChatRoomId =
            chatStates.selectedChatRoomState.selected_id.get();
          if (selectedChatRoomId === chatRoom.roomId) {
            chatStates.selectedChatRoomState.merge({
              selected_id: null,
              selected_chatroom: null,
            });
          }
        });
      } catch (e) {
        console.log('resolve error', e);
      }
    },
    [chatRooms.current, enqueueUpdate, chatStates.selectedChatRoomState],
  );

  const attachSocketListeners = () => {
    if (!socket.current) return;
    socket.current.on('sent-message', onMessageReceived);
    socket.current.on('chat-room-resolved', resolveChatRoom);
    socket.current.on('counter-update', updateCounter);
    socket.current.emit('join', 'agent:chatChannel');
  };

  const detachSocketListeners = () => {
    socket.current.off('sent-message', onMessageReceived);
    socket.current.off('chat-room-resolved', resolveChatRoom);
    socket.current.off('counter-update', updateCounter);
    socket.current.emit('leave', 'agent:chatChannel');
  };

  const initializeSocket = async () => {
    socket.current = new SocketManager(CHAT_URL, {
      query: {
        userId: auth?.user?._id,
        type: 'agent',
        userName: auth?.user?.displayname_en,
        userPhoto:
          'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
      },
      reconnection: true,
      autoConnect: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket', 'polling'],
    });

    await socket.current.connect();

    socket.current.on('connect', () => {
      console.log('Socket connected in chat v2');
      attachSocketListeners();
    });

    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
      detachSocketListeners();
    });

    socket.current.on('error', (error) => {
      console.error('Socket error', error);
      detachSocketListeners();
    });

    return socket.current;
  };

  useEffect(() => {
    initializeSocket(); // Make sure initializeSocket sets socket.current

    return () => {
      if (socket.current) {
        detachSocketListeners();
        socket.current.disconnect();
      }
    };
  }, []);

  const clearFiltersAndSearch = () => {
    setActiveFilters({
      roomType: null,
      roomName: null,
      roomLanguage: null,
      issueTags: null,
      roomAssignedToName: null,
      isModalVisible:false
    });
  };

  const onSearch = (search) => {
    if (!search) fetchData();
    fetchData(search);
  };
  console.log({
  data:selected_chatroom?.get()
  })

  const onOpenTicketModal = () => {
    setState({ isModalVisible: true });
  };
  const setSelectedMessageTab = (value) => {
    setState({
      selectedMessageTab: value,
    });
  };
  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: backgroundColor,
        paddingTop: '20px',
      }}
    >
   

      <Row>
        <Col span={6} xs={12} xl={6}>
          <ChatRoomList
            height="calc(88vh)"
            chatSource={state.source}
            socketRef={socket.current}
            lastAction={state.lastAction}
            unreadCount={state.unreadCount}
            chatRooms={chatRooms.current}
            triggerLastAction={triggerLastAction}
            activeFilters={activeFilters}
            onSearch={onSearch}
            toggleFilterDrawer={toggleFilterDrawer}
            clearFiltersAndSearch={clearFiltersAndSearch}
            setSelectedMessageTab={setSelectedMessageTab}
          />
        </Col>
        <Col span={8} xs={12} xl={8}>
          <MessageList height="calc(88vh)" socketRef={socket.current}
          setSelectedMessageTab={setSelectedMessageTab}
          selectedMessageTab={state.selectedMessageTab}
          />
        </Col>

        <Col span={10} xs={12} xl={10}>
          <ShowTicketOrSideChatComponent selectRoom={selected_chatroom?.get()} onOpenTicketModal={onOpenTicketModal} />
        </Col>
      </Row>

    </div>
  );
}

export default ChatAndTicketPage;
const ShowTicketOrSideChatComponent = ({selectRoom={},onOpenTicketModal}) => { 

  const { roomType, roomRef, _id } = selectRoom || {}
  if (!_id && !roomRef) return <TicketOnChat onOpenTicketModal={onOpenTicketModal} />;
  // return <span>sad</span>
  return <ChatSidePanel chatRoom={selectRoom} />;

};