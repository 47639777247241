const VipIcon = ({width,height}) => { 
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"  
      width={width}
      height={height}
      viewBox="0 0 13 11"
      fill="none"
      // className="VipIcon"
    >
      <path
        d="M0.79476 7.73317C0.531624 6.02276 0.268488 4.3124 0.0053524 2.60199C-0.0530021 2.22284 0.378406 1.96425 0.685275 2.19442C1.50509 2.80928 2.32487 3.4241 3.14469 4.03897C3.41461 4.24141 3.79917 4.17553 3.98631 3.89479L6.03382 0.823499C6.25019 0.498938 6.72706 0.498938 6.94344 0.823499L8.99095 3.89479C9.17808 4.17553 9.56264 4.24137 9.83257 4.03897C10.6524 3.4241 11.4722 2.80928 12.292 2.19442C12.5989 1.96425 13.0303 2.22284 12.9719 2.60199C12.7088 4.3124 12.4457 6.02276 12.1825 7.73317H0.79476Z"
        fill="#FFB743"
      />
      <path
        d="M11.5889 10.5796H1.38863C1.06074 10.5796 0.794922 10.3137 0.794922 9.98586V8.68164H12.1827V9.98586C12.1827 10.3137 11.9168 10.5796 11.5889 10.5796Z"
        fill="#FFB743"
      />
    </svg>
  );
};
export default VipIcon;
