import React, { useState } from "react";
import { Button, Form, Image, Input, message } from "antd";
import { useAxiosGet, useAxiosPost } from "../../../configs/axios";

function EditOrderNotes(props) {
  const { selectedOrder, getAllOrders, ordersSearchFilters, closeModal } =
    props;

  const [edited, setEdited] = useState({
    accountNote: false,
    accountAddress: false,
    orderInstructions: false,
    deliveryInstructions: false,
    recieverName: false,
    recieverPhone: false,
  });

  const { request: updateNoteRequest, loading: updateNoteLoading } =
    useAxiosPost("/orders/updateNote");
  const onManualOrderSubmit = async (values) => {
    try {
      await updateNoteRequest({
        orderId: selectedOrder.orderId,
        accountNote: edited.accountNote ? values.accountNote : null,
        accountAddress: edited.accountAddress ? values.accountAddress : null,
        orderInstructions: edited.orderInstructions
          ? values.orderInstructions
          : null,
        deliveryInstructions: edited.deliveryInstructions
          ? values.deliveryInstructions
          : null,
        recieverName: edited.recieverName ? values.recieverName : null,
        recieverPhone: edited.recieverPhone ? values.recieverPhone : null,
      });
    } catch (error) {
      message.error("Error updating order");
    }
  };
  return (
    <Form onFinish={onManualOrderSubmit} layout="vertical">
      {selectedOrder.orderExtraImage && (
        <Image src={selectedOrder.orderExtraImage} width={300} />
      )}
      <Form.Item
        style={{
          width: "100%",
        }}
        initialValue={selectedOrder.customerAccountNote}
        label="Account Note"
        name="accountNote"
      >
        <Input
          onChange={(e) => {
            setEdited({ ...edited, accountNote: true });
          }}
        />
      </Form.Item>
      {/* <Form.Item
        style={{
          width: "100%",
        }}
        initialValue={selectedOrder.accountAddress}
        label="Account Address"
        name="accountAddress"
      >
        <Input
          onChange={(e) => {
            setEdited({ ...edited, accountAddress: true });
          }}
        />
      </Form.Item> */}
      <Form.Item
        style={{
          width: "100%",
        }}
        initialValue={selectedOrder.orderComment}
        label="Order Instructions"
        name="orderInstructions"
      >
        <Input
          onChange={(e) => {
            setEdited({ ...edited, orderInstructions: true });
          }}
        />
      </Form.Item>
      <Form.Item
        style={{
          width: "100%",
        }}
        initialValue={selectedOrder.orderDeliveryInstruction}
        label="Delivery Instructions"
        name="deliveryInstructions"
      >
        <Input
          onChange={(e) => {
            setEdited({ ...edited, deliveryInstructions: true });
          }}
        />
      </Form.Item>
      {selectedOrder.orderTypeId === 4 && (
        <>
          <Form.Item
            style={{
              width: "100%",
            }}
            initialValue={selectedOrder.orderReceiverName}
            label="Reciever Name"
            name="recieverName"
          >
            <Input
              onChange={(e) => {
                setEdited({ ...edited, recieverName: true });
              }}
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "100%",
            }}
            initialValue={selectedOrder.orderReceiverPhone}
            label="Receiver Phone"
            name="recieverPhone"
          >
            <Input
              onChange={(e) => {
                setEdited({ ...edited, recieverPhone: true });
              }}
            />
          </Form.Item>
        </>
      )}
      <Button
        style={{
          marginTop: "5px",
        }}
        loading={updateNoteLoading}
        type="primary"
        htmlType="submit"
      >
        Update Order
      </Button>
    </Form>
  );
}

EditOrderNotes.propTypes = {};

export default EditOrderNotes;
