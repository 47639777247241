// .chat-room-item {
//     padding: '15px';
//     border-bottom: '1px solid #e8e8e8';
//     cursor: 'pointer';
//     position: 'relative';
//     border-radius: '10px';
//     background-color: 'white';
//     margin-bottom: '5px';
//     margin-top: '5px';
//     max-width: '100%';
//     width: '100%';
//     overflow-anchor: 'none';
// }

// .chat-room-item.hovered {
//     background-color: '#f8f8f8';
// }

// .chat-room-item.selected {
//     background-color: '#e6f0ff';
//     border: '2px solid #1890ff';
//     box-shadow: '0px 2px 5px rgba(0, 0, 0, 0.1)';
// }

export const chatRoomItemStyle = {
  padding: '15px',
  border: '1px solid #D9DFF0',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: '#F1F4F9',
  marginBottom: '10px',
  marginTop: '5px',
  maxWidth: '100%',
  width: '100%',
  paddingTop: '20px',
  overflowAnchor: 'none',
  transition: 'background-color 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
};

export const lightColors = {
  title: '#40425F',
  content: '#828590',
  separator: '#ECEFF7',
  danger: '#FF4D4F',
};

export const chatRoomItemHoveredStyle = {
  backgroundColor: '#f8f8f8',
};

export const chatRoomItemSelectedStyle = {
  padding: '15px',
  borderBottom: '1px solid #e8e8e8',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: 'white',
  marginBottom: '5px',
  marginTop: '5px',
  maxWidth: '100%',
  width: '100%',
  overflowAnchor: 'none',
  transition: 'background-color 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
  backgroundColor: '#e6f0ff',
  border: '2px solid #1890ff',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
};

//Dark Mode
export const darkColors = {
  title: '#D6DBDF',
  content: '#C0C6CE',
  separator: '#1C344C',
  danger: '#FF4D4F',
};

export const darkChatRoomItemStyle = {
  padding: '15px',
  border: '1px solid #D9DFF0',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: '#243B53',
  marginBottom: '10px',
  marginTop: '5px',
  maxWidth: '100%',
  width: '100%',
  paddingTop: '20px',
  overflowAnchor: 'none',
  transition: 'background-color 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
};

export const darkChatRoomItemSelectedStyle = {
  padding: '15px',
  borderBottom: '1px solid #e8e8e8',
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: 'white',
  marginBottom: '5px',
  marginTop: '5px',
  maxWidth: '100%',
  width: '100%',
  overflowAnchor: 'none',
  transition: 'background-color 0.5s ease-in-out, box-shadow 0.2s ease-in-out',
  backgroundColor: '#e6f0ff',
  border: '2px solid #1890ff',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
};
