import React, { useEffect, useState } from 'react';
import { Button, Col, Input, message, Spin, Tooltip } from 'antd';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  UilShop,
  UilCarSideview,
  UilUser,
  UilArrowCircleDown,
} from '@iconscout/react-unicons';
import RemoteSelect from '../../../components/utility/RemoteSelect';
import { useAxiosPut } from '../../../configs/axios';
import { fixLatLngValues } from '../../../configs/constants';

function CSModeMap({ orderMapData, selectedOrder }) {
  const [changeDropOffLocationEnabled, setChangeDropOffLocationEnabled] =
    useState(false);
  const [changePickupLocationEnabled, setChangePickupLocationEnabled] =
    useState(false);
  const [newPickupLocation, setNewPickupLocation] = useState({
    latitude: selectedOrder?.pickup?.coordinates?.[1]||0,
    longitude: selectedOrder?.pickup?.coordinates?.[0]||0,
  });
  const [newDropOffLocation, setNewDropOffLocation] = useState({
    latitude: selectedOrder?.dropoff?.coordinates?.[1]||0,
    longitude: selectedOrder?.dropoff?.coordinates?.[0]||0,
  });
  const {
    request: updateDropoffLocationRequest,
    loading: updateDropoffLocationLoading,
  } = useAxiosPut('/orders/status');
  const [orderRecieverAddress, setOrderRecieverAddress] = useState(selectedOrder?.orderReceiverAddress||'');
  const { request: updateOrder, loading: updateOrderLoading } =
  useAxiosPut('/orders/status');
  const { request: updateAddressRequest, loading: updateAddressLodaing } =
  useAxiosPut('/orders/status');
  const [orderAddress, setOrderAddress] = useState(selectedOrder?.orderAddress);

  const [driver, setDriver] = React.useState(null);

  const onUpdateRecieverAddress = async () => {
    try {
      await updateOrder({
        statusId: 37,
        orderId: selectedOrder.orderId,
        orderAddress: orderRecieverAddress,
      });
      message.success('Address updated successfully');
    } catch (error) {
      message.error('Address update failed');
    }
  };

  const onUpdateSenderAddress = async () => {
    try {
      await updateOrder({
        statusId: 33,
        orderId: selectedOrder.orderId,
        orderAddress,
      });
      message.success('Address updated successfully');
    } catch (error) {
      message.error('Address update failed');
    }
  };

  const onChangeDropoffLocation = async (updateAddress) => {
    try {
      const requestBody = {
        statusId: 19,
        orderId: selectedOrder.orderId,
        orderLongitude: newDropOffLocation.longitude,
        orderLatitude: newDropOffLocation.latitude,
        latitude: selectedOrder.dropoff.coordinates[1],
        longitude: selectedOrder.dropoff.coordinates[0],
        customer_id: selectedOrder.customer.customer_id,
        shouldUpdateCustomerAddress: updateAddress,
      };

      await updateDropoffLocationRequest(requestBody);
      setChangeDropOffLocationEnabled(false);
      message.success('Dropoff location updated successfully');
    } catch (error) {
      message.error('Dropoff location update failed');
      setNewDropOffLocation({
        latitude: selectedOrder.dropoff.coordinates[1],
        longitude: selectedOrder.dropoff.coordinates[0],
      });
    }
  };

  const onUpdateAddress = async () => {
    try {
      const requestBody = {
        statusId: 18,
        orderId: selectedOrder.orderId,
        orderAddress: orderAddress,
      };

      await updateAddressRequest(requestBody);
      message.success('Address updated successfully');
    } catch (error) {
      message.error('Address update failed');
    }
  };

  useEffect(() => {
    if (selectedOrder.driver) {
      const driver = orderMapData?.find(
        (driver) => driver.userId === selectedOrder?.driver.userId,
      );
      setDriver(driver);
    }
  }, []);

  const onChangeSenderLocation = async () => {
    try {
      const requestBody = {
        statusId: 34,
        orderId: selectedOrder.orderId,
        orderLatitude: newPickupLocation.latitude,
        orderLongitude: newPickupLocation.longitude,
      };
      await updateOrder(requestBody);
      setChangePickupLocationEnabled(false);
      setChangeDropOffLocationEnabled(false);
      message.success('Sender location updated successfully');
    } catch (error) {
      message.error('Sender location update failed');
    }
  };

  const onChangeRecieverLocation = async () => {
    try {
      const requestBody = {
        statusId: 38,
        orderId: selectedOrder.orderId,
        orderLatitude: newDropOffLocation.latitude,
        orderLongitude: newDropOffLocation.longitude,
      };
      await updateOrder(requestBody);
      setChangeDropOffLocationEnabled(false);
      setChangePickupLocationEnabled(false);
      message.success('Reciever location updated successfully');
    } catch (error) {
      message.error('Reciever location update failed');
    }
  };

  return (
    <Col
      span={24}
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1,
          display: 'flex',
          gap: '10px',
        }}
      >
        <RemoteSelect
          style={{
            width: 200,
          }}
          allowClear={true}
          filterFromServer={false}
          endpoint="/addresses/list"
          valueOption="address_id"
          labelOption={'address_title'}
          placeholder={'Filter customer address'}
          query={{
            customerId: selectedOrder?.customer.customer_id,
            justList: 'yes',
          }}
          onValueChange={({ objectLabel }) => {
            setNewDropOffLocation({
              latitude: objectLabel.address_latitude,
              longitude: objectLabel.address_longitude,
            }); // setCitySearchValue(object.value === 'all' ? undefined : object.value);
          }}
        />
        <Input
          placeholder="Search"
          style={{
            width: '500px',
          }}
          defaultValue={selectedOrder?.orderAddress}
          onChange={(e) => setOrderAddress(e.target.value)}
        />

        <Button
          type="primary"
          style={{
            borderRadius: '20px',
          }}
          loading={updateAddressLodaing || updateOrderLoading}
          onClick={
            selectedOrder.orderTypeId === 4
              ? onUpdateSenderAddress
              : onUpdateAddress
          }
        >
          {selectedOrder.orderTypeId === 4 ? 'Update Sender' : 'Update Address'}
        </Button>
      </div>
      {selectedOrder.orderTypeId === 4 && (
        <div
          style={{
            position: 'absolute',
            top: '50px',
            left: '10px',
            zIndex: 1,
            display: 'flex',
            gap: '10px',
          }}
        >
          <Input
            placeholder="Search"
            style={{
              width: '500px',
            }}
            defaultValue={selectedOrder?.orderReceiverAddress}
            onChange={(e) => setOrderRecieverAddress(e.target.value)}
          />

          <Button
            type="primary"
            style={{
              borderRadius: '20px',
            }}
            loading={updateOrderLoading}
            onClick={
              selectedOrder.orderTypeId === 4
                ? onUpdateRecieverAddress
                : onUpdateAddress
            }
          >
            Update Reciever
          </Button>
        </div>
      )}
      <Map
        onClick={(event) => {
          if (changeDropOffLocationEnabled) {
            setNewDropOffLocation({
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat,
            });
          } else if (changePickupLocationEnabled) {
            setNewPickupLocation({
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat,
            });
          }
        }}
        willReadFrequently={true}
        initialViewState={{
          latitude:
            selectedOrder?.dropoff?.coordinates[1] ||
            selectedOrder?.pickup?.coordinates[1],
          longitude:
            selectedOrder?.dropoff?.coordinates[0] ||
            selectedOrder?.pickup?.coordinates[0],
          zoom: 12,
        }}
        style={{ width: '100%', minHeight: '600px' }}
        mapStyle={
          changeDropOffLocationEnabled || changePickupLocationEnabled
            ? 'mapbox://styles/mapbox/dark-v11'
            : 'mapbox://styles/mapbox/streets-v12'
        }
        mapboxAccessToken="pk.eyJ1IjoicmVrYXIiLCJhIjoiY2swYjI1czZzMG5xczNubXd4ODZ5ZmdvNyJ9.UUIH_qL1wzfGoFX2eG6Cyg"
      >
        <Marker
          longitude={fixLatLngValues(newPickupLocation?.longitude)}
          latitude={fixLatLngValues(newPickupLocation?.latitude)}
          anchor="bottom"
          draggable={
            selectedOrder.orderTypeId === 4 && changePickupLocationEnabled
          }
          onDragEnd={(event) => {
            setNewPickupLocation({
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat,
            });
          }}
        >
          <Tooltip
            title={
              selectedOrder.orderTypeId === 4 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0px',
                  }}
                >
                  {/* {updateDropoffLocationLoading ? ( */}
                  {false ? (
                    <Spin size="large" />
                  ) : (
                    <p>
                      {selectedOrder.orderTypeId === 4 ? 'Sender' : 'Pickup'}
                      <b
                        style={{
                          marginLeft: '5px',
                        }}
                      >
                        {changePickupLocationEnabled ? (
                          <a
                            onClick={
                              selectedOrder.orderTypeId === 4
                                ? onChangeSenderLocation
                                : onChangeDropoffLocation
                            }
                          >
                            (Confirm Location)
                          </a>
                        ) : (
                          !changeDropOffLocationEnabled &&
                          !changePickupLocationEnabled && (
                            <a
                              onClick={() => {
                                setChangePickupLocationEnabled(true);
                              }}
                            >
                              (Change Location)
                            </a>
                          )
                        )}
                      </b>
                    </p>
                  )}
                </div>
              ) : (
                'Merchant'
              )
            }
          >
            <Button
              type="primary"
              shape="circle"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<UilShop size="20px" />}
            />
          </Tooltip>
        </Marker>
        {driver && (
          <Marker
            longitude={fixLatLngValues(driver.position.coordinates[0])}
            latitude={fixLatLngValues(driver.position.coordinates[1])}
            anchor="bottom"
          >
            <Tooltip title="Driver">
              <Button
                type="primary"
                shape="circle"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#c0392b',
                  borderColor: '#c0392b',
                }}
                icon={<UilCarSideview size="25px" />}
              />
            </Tooltip>
          </Marker>
        )}

        <Marker
          longitude={fixLatLngValues(selectedOrder.currentCustomerLocation.coordinates[0])}
          latitude={fixLatLngValues(selectedOrder.currentCustomerLocation.coordinates[1])}
          anchor="bottom"
        >
          <Tooltip title="Customer">
            <Button
              type="primary"
              shape="circle"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#8e44ad',
                borderColor: '#8e44ad',
              }}
              icon={<UilUser size="20px" />}
            />
          </Tooltip>
        </Marker>
        <Marker
          longitude={fixLatLngValues(newDropOffLocation.longitude)}
          latitude={fixLatLngValues(newDropOffLocation.latitude)}
          anchor="bottom"
          draggable={changeDropOffLocationEnabled}
          onDragEnd={(event) => {
            setNewDropOffLocation({
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat,
            });
          }}
        >
          <Tooltip
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '0px',
                }}
              >
                {updateDropoffLocationLoading ? (
                  <Spin size="large" />
                ) : (
                  <p>
                    {selectedOrder.orderTypeId === 4 ? 'Reciever' : 'Drop off'}
                    <b
                      style={{
                        marginLeft: '5px',
                      }}
                    >
                      {changeDropOffLocationEnabled ? (
                        <>
                          <a
                            onClick={() => {
                              selectedOrder.orderTypeId === 4
                                ? onChangeRecieverLocation()
                                : onChangeDropoffLocation();
                            }}
                          >
                            (Confirm Location)
                          </a>
                          {selectedOrder.orderTypeId !== 4 ? (
                            <a
                              onClick={() => {
                                selectedOrder.orderTypeId === 4
                                  ? onChangeRecieverLocation()
                                  : onChangeDropoffLocation(true);
                              }}
                            >
                              (Change Location and update)
                            </a>
                          ) : null}
                        </>
                      ) : (
                        !changeDropOffLocationEnabled &&
                        !changePickupLocationEnabled && (
                          <a
                            onClick={() => {
                              setChangeDropOffLocationEnabled(true);
                            }}
                          >
                            (Change Location)
                          </a>
                        )
                      )}
                    </b>
                  </p>
                )}
              </div>
            }
          >
            <Button
              type="primary"
              shape="circle"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#16a085',
                borderColor: '#16a085',
              }}
              icon={<UilArrowCircleDown size="20px" />}
            />
          </Tooltip>
        </Marker>
      </Map>
    </Col>
  );
}

export default CSModeMap;
