import { Tooltip } from 'antd';
import React from 'react';
import Map, { Marker } from 'react-map-gl';
import { UilLocationPoint } from '@iconscout/react-unicons';
import { fixLatLngValues } from '../../configs/constants';
export default function ChatMap({ chat }) {
  return (
    <Map
      attributionControl={false}
      interactive={false}
      initialViewState={{
        latitude: chat.chatMessage.split(',')[0],
        longitude: chat.chatMessage.split(',')[1],
        zoom: 12,
      }}
      style={{ flex: 1, borderRadius: 10 }}
      mapStyle={'mapbox://styles/mapbox/streets-v12'}
      mapboxAccessToken="pk.eyJ1IjoicmVrYXIiLCJhIjoiY2swYjI1czZzMG5xczNubXd4ODZ5ZmdvNyJ9.UUIH_qL1wzfGoFX2eG6Cyg"
    >
      <Marker
        latitude={fixLatLngValues(chat.chatMessage.split(',')[0])}
        longitude={fixLatLngValues(chat.chatMessage.split(',')[1])}
        anchor="bottom"
        draggable={false}
      >
        <Tooltip title="Shared Location">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${chat.chatMessage}`}
            target="_blank"
            rel="noreferrer"
          >
            <UilLocationPoint
              style={{
                color: 'rgb(0,100,192)',
              }}
              size="40px"
            />{' '}
          </a>
        </Tooltip>
      </Marker>
    </Map>
  );
}
