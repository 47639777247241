import { Button, Col, message, Modal, Popconfirm, Tooltip } from 'antd';
import React, { useContext } from 'react';
import Map, { Marker } from 'react-map-gl';
import {
  UilShop,
  UilCarSideview,
  UilUser,
  UilArrowCircleDown,
} from '@iconscout/react-unicons';
import moment from 'moment';
import { useAxiosPost } from '../../../configs/axios';
import { fixLatLngValues } from '../../../configs/constants';

function DispatchModeMap(props) {
  const { orderMapData, closeModal, getAllOrders, ordersSearchFilters,selectedOrder } = props;

  const { request: assignDriverRequest, loading: assignDriverLoading } =
    useAxiosPost('/orders/assign');

  const onAssignDriver = async (courierId) => {
    try {
      await assignDriverRequest({
        courierId,
        orderId: selectedOrder.courierSystemOrderId,
      });
      message.success('Driver assigned successfully');
      closeModal();
      getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginTop: '5px',
          marginLeft: '50px',
        }}
      >
        <span
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: 'red',
            borderRadius: '10px',
          }}
        ></span>
        <span>Cars</span>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginTop: '5px',
          marginLeft: '20px',
        }}
      >
        <span
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: 'rgb(0,100,192)',
            borderRadius: '10px',
          }}
        ></span>
        <span>Motors</span>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginTop: '5px',
          marginLeft: '20px',
        }}
      >
        <span
          style={{
            height: '20px',
            width: '20px',
            backgroundColor: '#7f8c8d',
            borderRadius: '10px',
          }}
        ></span>
        <span>Gps is off</span>
      </div>
      <Col span={24}>
        <Map
          willReadFrequently={true}
          initialViewState={{
            latitude:fixLatLngValues(
              selectedOrder?.dropoff?.coordinates[1] ||
              selectedOrder?.pickup?.coordinates[1]),
            longitude:
            fixLatLngValues(selectedOrder?.dropoff?.coordinates[0] ||
              selectedOrder?.pickup?.coordinates[0]),
            zoom: 12,
          }}
          style={{ width: '100%', minHeight: '600px' }}
          mapStyle={'mapbox://styles/mapbox/streets-v12'}
          mapboxAccessToken="pk.eyJ1IjoicmVrYXIiLCJhIjoiY2swYjI1czZzMG5xczNubXd4ODZ5ZmdvNyJ9.UUIH_qL1wzfGoFX2eG6Cyg"
        >
          <Marker
            longitude={fixLatLngValues(selectedOrder.merchant.merchant_longitude)}
            latitude={fixLatLngValues(selectedOrder.merchant.merchant_latitude)}
            anchor="bottom"
            style={{
              zIndex: 100,
            }}
          >
            <Tooltip title="Merchant">
              <Button
                type="primary"
                shape="circle"
                size="small"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                icon={<UilShop size="14px" />}
              />
            </Tooltip>
          </Marker>
          <Marker
            longitude={fixLatLngValues(selectedOrder.currentCustomerLocation.coordinates[0])}
            latitude={fixLatLngValues(selectedOrder.currentCustomerLocation.coordinates[1])}
            anchor="bottom"
            style={{
              zIndex: 100,
            }}
          >
            <Tooltip title="Customer">
              <Button
                type="primary"
                shape="circle"
                size="small"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#8e44ad',
                  borderColor: '#8e44ad',
                }}
                icon={<UilUser size="14px" />}
              />
            </Tooltip>
          </Marker>
          <Marker
            longitude={fixLatLngValues(selectedOrder.dropoff.coordinates[0])}
            latitude={ fixLatLngValues(selectedOrder.dropoff.coordinates[1])}
            anchor="bottom"
            style={{
              zIndex: 100,
            }}
          >
            <Tooltip title={'Drop off'}>
              <Button
                type="primary"
                shape="circle"
                size="small"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#16a085',
                  borderColor: '#16a085',
                }}
                icon={<UilArrowCircleDown size="20px" />}
              />
            </Tooltip>
          </Marker>

          {orderMapData.map((driverLocation, index) => (
            <Popconfirm
              key={index}
              title={
                moment(driverLocation.locationUpdatedAt) <
                moment().subtract(10, 'minutes')
                  ? "You can't assign this driver"
                  : 'Do you want to assign this driver?'
              }
              okText="Yes"
              cancelText="No"
              cancelButtonProps={{
                disabled:
                  moment(driverLocation.locationUpdatedAt) <
                  moment().subtract(10, 'minutes'),
              }}
              okButtonProps={{
                loading: assignDriverLoading,
                disabled:
                  moment(driverLocation.locationUpdatedAt) <
                  moment().subtract(10, 'minutes'),
              }}
              onConfirm={() => onAssignDriver(driverLocation.courierId)}
            >
              <Marker
                longitude={fixLatLngValues(driverLocation.position.coordinates[0])}
                latitude={fixLatLngValues(driverLocation.position.coordinates[1])}
                anchor="bottom"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '2px',
                    flexDirection: 'column',
                  }}
                >
                  <p
                    style={{
                      fontSize: '9px',
                      backgroundColor:
                        moment(driverLocation.locationUpdatedAt) >
                        moment().subtract(10, 'minutes')
                          ? driverLocation.driverColor || 'rgb(0,100,192)'
                          : '#7f8c8d',
                      padding: '0px 3px',
                      color: 'white',
                    }}
                  >
                    {driverLocation.firstName} {driverLocation.lastName}
                  </p>
                  <div
                    type="primary"
                    shape="circle"
                    size="small"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '12px',
                      width: '12px',
                      borderRadius: '50%',
                      fontSize: '10px',
                      color: 'white',
                      backgroundColor:
                        driverLocation.currentActiveOrders === 0
                          ? '#7f8c8d'
                          : driverLocation.currentActiveOrders === 1
                          ? 'rgb(0,100,192)'
                          : '#f1c40f', // if more than 1 order make it yellow
                      border: 'none',
                    }}
                  >
                    {driverLocation.currentActiveOrders !== 0 ? (
                      driverLocation.currentActiveOrders
                    ) : (
                      <UilCarSideview size="10px" />
                    )}
                  </div>
                </div>
              </Marker>
            </Popconfirm>
          ))}
        </Map>
      </Col>
    </>
  );
}

export default DispatchModeMap;
