import { Spin, message as alertMessage, Tabs, Card, Row, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useLocalStorage from '../../configs/localStorage';
import { useAxiosGet, useAxiosGetChat } from '../../configs/axios';
import debounce from 'lodash/debounce';
import { UilWrapText, UilCommentMessage } from '@iconscout/react-unicons';

import Pusher from 'pusher-js';
import _ from 'lodash';
import API_URL from '../../configs/constants';
function ChatScriptTab({ setMessage, setSelectedTab, source, selectedChatroom,colorLocalStorage }) {
  const [auth] = useLocalStorage('auth', null);
  const [scriptTabKey, setScriptTabKey] = useState('1');

  const { loading: getPredefinedMessageLoading, data: predefinedMessages } =
    useAxiosGetChat('/predefinedMessages/', {
      autoRun: true,
    });
    useEffect(() => {
      setScriptTabKey('1');
    }, [selectedChatroom?.roomRef]);
  const onPredefinedMessageClick = (predefinedMessage, lang) => {
    try {
      setSelectedTab('1');
      const editedMessage = replaceAgentNameInPredefinedMessage(
        predefinedMessage,
        lang,
      );
      setMessage(editedMessage);
    } catch (e) {
      alertMessage.success('something went wrong');
    }
  };
  const replaceAgentNameInPredefinedMessage = (message, lang) => {
    if (lang === 'KR') {
      return message.replaceAll('{agentName}', auth?.user?.displayname_kr);
    } else if (lang === 'EN') {
      return message.replaceAll('{agentName}', auth?.user?.displayname_en);
    } else if (lang === 'AR') {
      return message.replaceAll('{agentName}', auth?.user?.displayname_ar);
    }
    return message;
  };

  const chatTabLang = [
    {
      key: '1',
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
          }}
        >
          <Tooltip title="English">
            <span
              style={{
                fontSize: 20,
              }}
            >
              🇬🇧
            </span>
          </Tooltip>
        </div>
      ),

      children: (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            overflow: 'scroll',
            height: 'calc(100vh - 220px)',

          }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: 'rgba(74, 84, 134, 0.08)',
              //   paddingBlock: '10px',
              borderInline: '1px solid #ECEEF6',
              height: '100%',
              marginTop: -15,
            }}
          >
            {getPredefinedMessageLoading ? (
              <Spin />
            ) : (
              _.filter(
                predefinedMessages,
                (item) =>
                  item.chatSources.includes(source) &&
                  item.clients.includes('agent') &&
                  item?.message_kr !== '' &&
                  item?.message_en !== undefined,
              )?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      padding: 10,
                      marginBottom: 5,
                    }}
                  >
                    <Card
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>
                        onPredefinedMessageClick(item.message_en, 'EN')
                      }
                    >
                      <span> {item?.message_en}</span>
                    </Card>
                  </div>
                );
              })
            )}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
          }}
        >
          <Tooltip title="العربية">
            <span
              style={{
                fontSize: 20,
              }}
            >
              🇮🇶
            </span>
          </Tooltip>
        </div>
      ),

      children: (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            backgroundColor: 'rgba(74, 84, 134, 0.08)',
            width: '100%',
            marginTop: -15,
            overflow: 'scroll',
            height: 'calc(100vh - 220px)',
            

          }}
        >
          {getPredefinedMessageLoading ? (
            <Spin />
          ) : (
            _.filter(
              predefinedMessages,
              (item) =>
                item.chatSources.includes(source) &&
                item?.message_kr !== '' &&
                item?.message_ar !== undefined,
            )?.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    padding: 10,
                    marginBottom: 5,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Card
                    style={{ cursor: 'pointer', textAlign: 'right' }}
                    onClick={(e) =>
                      onPredefinedMessageClick(item.message_ar, 'AR')
                    }
                  >
                    <span> {item?.message_ar}</span>
                  </Card>
                </div>
              );
            })
          )}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          style={{
            textAlign: 'center',
            width: 100,
          }}
        >
          <Tooltip title="کوردی">
            <span
              style={{
                fontSize: 20,
              }}
            >
              🇹🇯
            </span>
          </Tooltip>
        </div>
      ),

      children: (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
            width: '100%',
            backgroundColor: 'rgba(74, 84, 134, 0.08)',
            marginTop: -15,
            overflow: 'scroll',
            height: 'calc(100vh - 220px)',
            
          }}
        >
          {getPredefinedMessageLoading ? (
            <Spin />
          ) : (
            _.filter(
              predefinedMessages,
              (item) =>
                item.chatSources.includes(source) &&
                item?.message_kr !== '' &&
                item?.message_kr !== undefined,
            )?.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    padding: 10,
                    marginBottom: 5,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Card
                    style={{
                      cursor: 'pointer',
                      textAlign: 'right',
                    }}
                    onClick={(e) =>
                      onPredefinedMessageClick(item.message_kr, 'KR')
                    }
                  >
                    <span> {item?.message_kr}</span>
                  </Card>
                </div>
              );
            })
          )}
        </div>
      ),
    },
  ];
  return (
    <Row
      style={{
        display: 'flex',
        height: 'calc(100vh - 100px)',
        width: '100%',
        marginTop: -15,

        // overflow: "auto",
      }}
    >
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{
          backgroundColor: colorLocalStorage?.darkMode?'#ECEEF633':'#ECEEF6',
          height: '40px',
          width: '100%',
        }}
        activeKey={scriptTabKey}
        items={chatTabLang}
        onChange={(e) => {
          setScriptTabKey(e);
        }}
      />
    </Row>
  );
}

export default ChatScriptTab;
