import { Button, Drawer, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAxiosGetChat } from '../../configs/axios';
import ChatroomComponent from '../Chat/ChatroomComponent';
import useLocalStorage from '../../configs/localStorage';
import { CHAT_URL } from '../../configs/constants';
import { io } from 'socket.io-client';
import { getSocket } from '../../store/socket/socketGetter';

function ChatModalComponent(props) {
  const { selectedOrder, screenView, disableActions, activeChatType } = props;
  const [selectedChatroom, setSelectedChatroom] = useState(null);
  const [selectedChatRoomMessages, setSelectedChatRoomMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [canNotLoadMore, setCanNotLoadMore] = useState(false);
  const messagePerPage = 25;
  const messagePage = useRef(0);
  const isLoadMore = useRef(false);
  const turnOffTyping = useRef();

  const [auth] = useLocalStorage('auth', null);
  const roomName = 'agent:chatChannel';
  const [socket, setSocket] = useState(getSocket());
  const [chatSource, setChatSource] = useState(
    activeChatType ? activeChatType : 'orders',
  );

  useEffect(() => {
    if (socket) {
      socket?.on('room-typing', (data) => {
        if (!isTyping) {
          setIsTyping({
            text: data?.text,
            userType: data?.userType || 'customer',
          });
        }
        // clear the timer if it has already been set.
        clearTimeout(turnOffTyping.current);
        // set the timer to turn off typing after 2 seconds
        turnOffTyping.current = setTimeout(() => {
          setIsTyping(false);
        }, 2000);
      });
    }
  }, [socket]);

  const { loading: chatroomMessagesLoading, request: chatroomMessagesRequest } =
    useAxiosGetChat('/chats/messages');

  const userID = auth?.user?._id;
  const onMessageReceived = (receivedMessage) => {
    const shouldUserSeeMessage =
      auth.user.isMonitorAgent ||
      auth.user.isSuperAdmin ||
      auth.user.isSupervisorAgent;
    const isRoomAssignedToUser =
      receivedMessage?.chatRoom?.roomAssignedTo?.toString() ===
      userID?.toString();
    if (!(isRoomAssignedToUser || shouldUserSeeMessage)) {
      return;
    }

    const newMessageChatroom = receivedMessage.chatRoom;
    if (receivedMessage?.createdById.toString() == userID.toString()) {
      return;
    }
    if (selectedChatroom?._id === newMessageChatroom._id) {
      const newMessage = {
        _id: Math.random() * 100000000000000,
        createdByName: receivedMessage.createdByName,
        createdById: receivedMessage.createdById,
        createdByAvatar: '',
        chatType: receivedMessage.chatType,
        chatMessage: receivedMessage.chatMessage,
        chatRoomId: receivedMessage.chatRoomId,
        lastMessage: '',
        messageSource: receivedMessage.messageSource,
        isDeleted: false,
        createdAt: moment().format(),
        __v: 0,
      };
      // if (selectedChatroom.status != 'opened') {
      //   setSelectedChatroom({ ...selectedChatroom, status: 'opened' });
      // }
      setSelectedChatRoomMessages((prev) => [newMessage, ...prev]);
    }
  };
  useEffect(() => {
    socket?.on('sent-message', onMessageReceived);
    if (selectedChatroom) {
      const messageRoomName = `${selectedChatroom?.roomType}:${selectedChatroom?.roomRef}`;
      socket?.emit('join', messageRoomName);
    }
    return () => {
      const messageRoomName = `${selectedChatroom?.roomType}:${selectedChatroom?.roomRef}`;
      socket?.removeListener('sent-message');
      socket?.emit('left_room', {
        room: messageRoomName,
        userId: auth?.user?._id,
      });
    };
  }, [selectedChatroom]);
  useEffect(() => {
    (async () => {
      try {
        const data = await chatroomMessagesRequest({
          chatSource,
          // screenView,
          roomType: chatSource,
          roomRef: selectedOrder.orderId
            ? selectedOrder.orderId
            : selectedOrder.order_id,
          roomId: selectedOrder.roomId ? selectedOrder.roomId : null,
          limit: messagePerPage,
          page: 0,
        });
        if (canNotLoadMore && data.messages.length == messagePerPage)
          setCanNotLoadMore(false);
        if (!canNotLoadMore && data.messages.length < messagePerPage)
          setCanNotLoadMore(true);
        const { messages, roomData } = data;

        setSelectedChatroom(roomData);
        setSelectedChatRoomMessages(messages);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [chatSource]);
  const onLoadMoreMessages = async (chatroom) => {
    try {
      messagePage.current = messagePage.current + 1;
      if (isLoadMore.current) return;
      isLoadMore.current = true;
      const data = await chatroomMessagesRequest({
        chatSource: chatSource,
        roomType: selectedChatroom.roomType,
        roomRef: selectedChatroom.roomRef,
        roomId: selectedChatroom._id,
        page: messagePage.current,
        limit: messagePerPage,
        messageId:
          selectedChatRoomMessages[selectedChatRoomMessages.length - 1]?._id,
      });
      if (data.messages.length === 0 && !canNotLoadMore) {
        setCanNotLoadMore(true);
      }

      setSelectedChatRoomMessages([
        ...selectedChatRoomMessages,
        ...data.messages,
      ]);

      isLoadMore.current = false;
    } catch (e) {
      isLoadMore.current = false;
    }
  };
  const { status, setStatus } = props;

  const formatter = (chatType) => {
    switch (chatType) {
      case 'merchant_agent':
        return 'Merchant';
      case 'orders':
        return 'Rider';
      case 'customer_agent':
        return 'Customer';

      default:
        return '';
    }
  };
  return (
    <Drawer
      width={450}
      extra={
        <div
          style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
            width:'387px',
            
          }}
        >
          {activeChatType ? (
            <p
              style={{
                fontSize: '20px',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              Chatting with {formatter(activeChatType)}
            </p>
          ) : (
            <>
              <Button
                type={chatSource === 'orders' ? 'primary' : 'default'}
                onClick={() => setChatSource('orders')}
              >
                Order
              </Button>
              <Button
                type={chatSource === 'customer_agent' ? 'primary' : 'default'}
                onClick={() => setChatSource('customer_agent')}
              >
                Customer
              </Button>
              <Button
                type={chatSource === 'merchant_agent' ? 'primary' : 'default'}
                onClick={() => setChatSource('merchant_agent')}
              >
                Merchant
              </Button>
            </>
          )}
        </div>
      }
      placement="right"
      style={{
        paddingRigth: '20px',
        height: '100%',
        zIndex: 1002,
      }}
      open={status}
      onClose={() => {
        setStatus(false);
      }}
    >
      {selectedChatroom && selectedChatRoomMessages ? (
        <div
          style={{
            height: '100%',
            marginTop: -30,
          }}
        >
          <ChatroomComponent
            selectedChatroom={selectedChatroom}
            setSelectedChatroom={setSelectedChatroom}
            chatroomMessagesLoading={chatroomMessagesLoading}
            messages={selectedChatRoomMessages}
            setMessages={setSelectedChatRoomMessages}
            source={chatSource}
            disableActions={disableActions}
            isDrawer={true}
            canNotLoadMore={canNotLoadMore}
            onLoadMoreMessages={onLoadMoreMessages}
          />
        </div>
      ) : chatroomMessagesLoading ? (
        <Spin />
      ) : (
        <div>Error {JSON.stringify(selectedChatRoomMessages)}</div>
      )}
    </Drawer>
  );
}

export default ChatModalComponent;
