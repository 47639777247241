import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAxiosGetChat, useAxiosPostChat } from '../../configs/axios';
import { Button, Input, Skeleton, Typography, message } from 'antd';
import ChatMessage from './Message';

const { Search } = Input;
const format = 'hh:mm A';
const { Text } = Typography;
const  ChatComponentV2=forwardRef((props, ref) => {
  
  
  
const {  height,
  roomRef,
  roomType,
  roomName,
  onClose,
  socketRef,onCloseDrawer} = props;


  const [searchValue,setSearchValue]=useState('')
  const [chatroomMessages, setChatroomMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const messagePerPage = 125;
  const turnOffTyping = useRef(null);
  const { loading: chatroomMessagesLoading, request: chatroomMessagesRequest } =
    useAxiosGetChat('/chats/messages');
  const { request: resolveChatroom } = useAxiosPostChat('/chats/resolveChat');

  const { request: sendMessageRequest, loading: sendMessageIsLoading } =
    useAxiosPostChat('/chats');
    const setInputChat = (text) => {
      setSearchValue(text);
    };
    useImperativeHandle(
      ref,
      () => {
        return {
          setInputChat,
        };
      },
      [],
    );
  
  const sendMessage = async (message, messageType = 'text') => {
    if (message.length == 0 || sendMessageIsLoading) {
      return;
    }
    try {
      const requestBody = {
        message,
        roomRef: roomRef,
        messageType,
        chatSource: roomType,
        messageSource: 'agent',
      };

      await sendMessageRequest(requestBody);

      // Scroll to bottom
      const chat = document.getElementById('chat-messages');
      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    } catch (error) {
      console.log('Error sending message', error);
    }
  };
  const onResolveChatroom = async () => {
    try {
      await resolveChatroom({
        roomType: roomType,
        roomRef: roomRef,
        chatSource: roomType,
      });

      onCloseDrawer();

      message.success('Chatroom resolved successfully');
    } catch (error) {
      console.log(
        '🚀 ~ file: ChatroomComponent.jsx:76 ~ onResolveChatroom ~ error',
        error,
      );
      message.error('Error resolving chatroom');
    }
  };
  
  ///// socket functions
  const onMessageReceived = useCallback((data) => {
    if (data.roomRef === roomRef) {
      const uniqueKey = Date.now();
      setChatroomMessages((prev) => [
        ...prev,

        { ...data, _id: uniqueKey },
       
      ]);
    }
  }, []);
  const resolveChatRoom = (data) => {
    const { roomId } = data;
    try {
      if (!roomId) {
        return true;
      }
      if (data.roomRef === roomRef) {
        onCloseDrawer();
      }
    } catch (error) {}
  };

  const onIsTyping = (data) => {
    if (!isTyping) {
      setIsTyping({
        text: data?.text,
        userType: data?.userType || 'customer',
      });
    }
    // clear the timer if it has already been set.
    clearTimeout(turnOffTyping.current);
    // set the timer to turn off typing after 2 seconds
    turnOffTyping.current = setTimeout(() => {
      setIsTyping(false);
    }, 2000);
  };

  const onSocketError = (error) => {
    console.error('Socket error:', error);
    // Handle the error as needed
  };
  const onConnectSocketError = (error) => {
    console.error('Socket connection error:', error);
    // Handle the error as needed
  };
  const onSocketConnect = () => {
 
  };
  /// socket functions end
  useEffect(() => {
    console.log(socketRef.current.connected);
    
    if (socketRef.current.connected) {
      const roomChannel = `${roomType}:${roomRef}`;
      // socketRef.current.on('connect', onSocketConnect);
      socketRef.current.on('error', onSocketError);
      socketRef.current.on('connect_error', onConnectSocketError);
      socketRef.current.on('sent-message', onMessageReceived);
      socketRef.current?.on('room-typing', onIsTyping);
      socketRef.current.on('chat-room-resolved', resolveChatRoom);
      socketRef.current.emit('join', 'agent:chatChannel');
      socketRef.current.emit('join', roomChannel);
   
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.off('error', onSocketError);
        socketRef.current.off('connect_error', onConnectSocketError);
        socketRef.current.off('sent-message', onMessageReceived);
        socketRef.current.off('room-typing', onIsTyping);
        socketRef.current.off('chat-room-resolved', resolveChatRoom);
     
      } 
    }
  }, [ socketRef.current.connected]);

  const fetchChatMessages = async () => {
    const data = await chatroomMessagesRequest({
      chatSource: roomType,
      roomType: roomType,
      roomRef: roomRef,
      page: 0,
      limit: messagePerPage,
    });

    const reversedMessages = _.reverse(data.messages);
    setChatroomMessages(reversedMessages);
  };

  useEffect(() => {
    fetchChatMessages();
  }, []);

  return (

      <div
        style={{
          width: '100%',
          height: height,
          maxHeight: height,
          minHeight: height,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            // backgroundColor: '#757575',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>{roomName}</h4>
          <Button type="primary" onClick={onResolveChatroom}>
            Resolve
          </Button>
        </div>
        <div
          style={{
            height: '100%',
            maxHeight: '100%',
            padding: '10px',
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            flexDirection: 'column',
            overflowY: 'auto',
            // backgroundColor: '#757575',
          }}
        >
          {chatroomMessagesLoading ? (
            <Skeleton active />
          ) : chatroomMessages?.length > 0 ? (
            <ChatMessage
              id="chat-messages"
              messages={chatroomMessages}
              isTyping={isTyping}
            />
          ) : null}
        </div>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#469',
          }}
        >
          <Search
            placeholder="Type a message"
            enterButton="Send"
            size="large"
            value={searchValue}
            onChange={(value) => {
            setSearchValue(value.target.value)
            
            }}
            onSearch={(value) => {
              setSearchValue('')
        sendMessage(value);
              const roomChannel = `${roomType}:${roomRef}`;
              socketRef.current?.emit('typing', {
                text: value,
                room: roomChannel,
                userType: 'agent',
              });
            }}
          />
        </div>
      </div>
    );
  });

export default ChatComponentV2;
