/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Space,
  Tag,
  Image,
  Col,
} from 'antd';
import { isArray } from 'lodash';
import ItemCard from './ItemCard';
import debounceFx from '../../../functions/debounceFx';

const { Panel } = Collapse;

const AddNewItemsModalComponent = (props) => {
  const {
    addToCartModalVisible,
    setAddToCartModalVisible,
    selectedOrder,
    setSelectedOrder,
    merchantId,
    oldAllProduct,
    setDataAllProducts,
    requestAllProducts,
    loading,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const page = useRef(2); // we start from one, when click 2 direct send request.

  const onSubmitNewProductToCart = (values) => {
    const valuesArray = Object.values(values);

    const addons = [];

    valuesArray.forEach((value) => {
      if (isArray(value)) {
        value.forEach((item) => {
          const parsedItem = JSON.parse(item);
          addons.push({
            id: parsedItem?.product_addon_category || 0,
            name: parsedItem.product_addon_name,
            type: parsedItem.product_addon_type,
            value: parsedItem,
          });
        });
      } else {
        const parsedItem = JSON.parse(value);
        addons.push({
          id: parsedItem?.product_addon_category || 0,
          name: parsedItem.product_addon_name,
          type: parsedItem.product_addon_type,
          value: parsedItem,
        });
      }
    });

    const newCartProduct = {
      ...selectedItem,
      category_name: selectedItem.product_category_name,
      name: selectedItem.product_name,
      image: selectedItem.product_image,
      quantity: 1,
      addons,
    };

    setSelectedOrder({
      ...selectedOrder,
      cart: [newCartProduct, ...selectedOrder.cart],
    });

    setSelectedItem(null);
    message.success('Product added to cart');
  };

  const inputSearchFx = (e) => {
    page.current = 2;
    requestAllProducts({
      search: e.target.value,
      pageSize: 25,
      merchantId,
      customerId: selectedOrder.customer.customer_id,
    });
  };

  const processChange = debounceFx((e) => inputSearchFx(e));

  return (
    <Modal
      title="Order Cart"
      destroyOnClose
      open={addToCartModalVisible}
      onCancel={() => {
        setAddToCartModalVisible(false);
      }}
      afterClose={() => {
        setSelectedItem(null);
      }}
      footer={null}
      centered
      width="90%"
    >
      <div
        style={{
          width: '73%',
          maxWidth: '73%',
          paddingRight: '10px',
        }}
      >
        <Input
          placeholder="Search"
          onChange={(e) => {
            processChange(e);
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxHeight: '80vh',
        }}
      >
        <div
          style={{
            width: '73%',
            maxWidth: '73%',
            overflowY: 'scroll',
            paddingRight: '10px',
          }}
        >
          <div
            style={{
              gap: '20px',
              padding: '20px',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {Object.keys(oldAllProduct || {}).map((eachKey) => {
              const products = oldAllProduct[eachKey] || [];
              if (!products || !products.length) {
                return null;
              }

              return (
                <div key={products.id}>
                  <Tag
                    color="blue"
                    style={{
                      width: '100%',
                      fontSize: '32px',
                      padding: '15px',
                      alignContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {products[0].category_name.en}
                  </Tag>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '5%',
                    }}
                  >
                    {products.map((item) => (
                      <ItemCard item={item} setSelectedItem={setSelectedItem} />
                    ))}
                  </div>
                </div>
              );
            })}

            <Col offset={20}>
              <Button
                type="primary"
                loading={loading}
                disabled={loading}
                onClick={async () => {
                  try {
                    const oldData = { ...oldAllProduct };
                    const newData = await requestAllProducts({
                      page: page.current,
                      pageSize: 25,
                      merchantId,
                      customerId: selectedOrder.customer.customer_id,
                    });
                    if (newData) {
                      const allKeys = [
                        ...new Set([
                          ...Object.keys(newData),
                          ...Object.keys(oldData),
                        ]),
                      ];
                      const data2 = {};
                      allKeys.forEach((key) => {
                        oldData[key] = oldData[key] || [];
                        newData[key] = newData[key] || [];
                        data2[key] = [...oldData[key], ...newData[key]];
                      });
                      setDataAllProducts(data2);

                      page.current += 1;
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                Load More
              </Button>
            </Col>
          </div>
        </div>
        <div
          style={{
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
          }}
        >
          <h2>Cart Details</h2>
          {selectedItem && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Image
                src={selectedItem.product_image}
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <p
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <b>Category:</b> {selectedItem.product_category_name.en}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <b>Name:</b> {selectedItem.product_name.en}
                </p>
                <p
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <b>Price:</b>{' '}
                  {selectedItem.product_discount
                    ? selectedItem.product_discount
                    : selectedItem.product_price}{' '}
                  IQD
                </p>
                <p
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <b>Description:</b> {selectedItem.product_description.en}
                </p>
              </div>

              <Form
                layout="vertical"
                style={{
                  width: '100%',
                }}
                onFinish={onSubmitNewProductToCart}
              >
                <Collapse>
                  {selectedItem.product_addons.map((addon) => (
                    <Panel
                      key={Math.random().toString(36).substring(7)}
                      header={addon.name.en}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: addon.type === 'radio',
                            message: 'Please select addon',
                          },
                        ]}
                        style={{
                          width: '100%',
                        }}
                        name={Math.random().toString(36).substring(7)}
                      >
                        {addon.values.length === 0 ? (
                          'No addons available'
                        ) : addon.type === 'checkbox' ||
                          addon.type === 'multicheckbox' ? (
                          <Checkbox.Group>
                            <Space direction="vertical">
                              {addon.values.map((option) => (
                                <Checkbox
                                  key={option.id}
                                  value={JSON.stringify({
                                    ...option,
                                    product_addon_name: addon.name.en,
                                    product_addon_type: addon.type,
                                  })}
                                >
                                  {option.name.en}
                                  {option.price ? ` - ${option.price} IQD` : ''}
                                </Checkbox>
                              ))}
                            </Space>
                          </Checkbox.Group>
                        ) : (
                          <Radio.Group>
                            <Space direction="vertical">
                              {addon.values.map((option) => (
                                <Radio
                                  key={option.id}
                                  value={JSON.stringify({
                                    ...option,
                                    product_addon_name: addon.name.en,
                                    product_addon_type: addon.type,
                                  })}
                                >
                                  {option.name.en}
                                  {option.price ? ` - ${option.price} IQD` : ''}
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Panel>
                  ))}
                </Collapse>
                <Button
                  style={{
                    marginTop: '10px',
                  }}
                  type="primary"
                  block
                  htmlType="submit"
                >
                  Add To Cart
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

AddNewItemsModalComponent.propTypes = {};

export default AddNewItemsModalComponent;
