import React, { useContext, useRef, useState } from 'react';
import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import DebounceInput from '../utility/DebounceInput';
import { useAxiosGet } from '../../configs/axios';
import { OrderContext } from '../../pages/Home';
import RemoteSelectForm from '../utility/RemoteSelectForm';
const { RangePicker } = DatePicker;

const OrderFilterInputsComponent = () => {
  const { getAllOrders, ordersSearchFilters, setOrdersSearchFilters } =
    useContext(OrderContext);
  const timoutRef = useRef(0);
  const { request: getAllMerchants } = useAxiosGet('/merchants');
  const { request: getAllCustomers } = useAxiosGet('/customers');
  const { request: getAllDrivers } = useAxiosGet('/couriers/list');
  const { request: getAllAgents } = useAxiosGet('/users/assign');

  const [merchantSearchValue, setMerchantSearchValue] = useState('');
  const [customerSearchValue, setCustomerSearchValue] = useState('');
  const [driverSearchValue, setDriverSearchValue] = useState('');
  const [agentSearchValue, setAgentSearchValue] = useState('');

  const fetchMerchantsDropdown = async (searchQuery) => {
    const response = await getAllMerchants({ search: searchQuery });

    return response.map((merchant) => ({
      label: `${merchant.merchant_id} - ${merchant.merchant_name} / ${merchant.merchant_city}`,
      value: JSON.stringify(merchant),
    }));
  };

  const fetchCustomersDropdown = async (searchQuery) => {
    const response = await getAllCustomers({ search: searchQuery });

    return response.map((customer) => ({
      label: `${customer.customer_id} - ${customer.customer_name} - ${customer.customer_phone}`,
      value: JSON.stringify(customer),
    }));
  };

  const fetchDriversDropdown = async (searchQuery) => {
    const response = await getAllDrivers({ search: searchQuery });

    return response.map((customer) => ({
      label: `${customer.courier_id} - ${customer.courier_name}`,
      value: JSON.stringify(customer),
    }));
  };

  const fetchAgentsDropdown = async (searchQuery) => {
    const response = await getAllAgents({ search: searchQuery });

    return response.map((user) => ({
      label: `${user.userName}`,
      value: JSON.stringify(user),
    }));
  };
  return (
    <div>
      <Form layout="vertical" onFinish={() => {}}>
        <Row gutter={[10, 0]} style={{ width: '100%', marginTop: 50 }}>
          <Col style={{ height: 30 }} span={2}>
            <Form.Item
              name="orderId"
              onChange={(newVal) => {
                const value = newVal.target.value;

                clearTimeout(timoutRef.current);
                timoutRef.current = setTimeout(() => {
                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    orderId: value,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }, 700);
              }}
            >
              <Input
                type="number"
                placeholder="order id"
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ height: 30 }} span={4}>
            <Form.Item name="driverId">
              <DebounceInput
                value={driverSearchValue}
                placeholder="Driver"
                fetchOptions={fetchDriversDropdown}
                onChange={(newValue) => {
                  if (newValue?.length > 1) {
                    return newValue.pop();
                  }

                  if (newValue?.length === 0) {
                    const newOrdersFilter = {
                      ...ordersSearchFilters,
                      driverId: undefined,
                    };
                    setOrdersSearchFilters(newOrdersFilter);
                    getAllOrders(newOrdersFilter);

                    return;
                  }

                  setDriverSearchValue(newValue);
                  const newDriverFilter = JSON.parse(
                    newValue[0].value,
                  ).courier_external_id;

                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    driverId: newDriverFilter,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }}
              />
            </Form.Item>
          </Col>

          <Col style={{ height: 30 }} span={4}>
            <Form.Item name="merchantId">
              <DebounceInput
                value={merchantSearchValue}
                placeholder="merchant"
                fetchOptions={fetchMerchantsDropdown}
                onChange={(newValue) => {
                  // if (newValue?.length > 1) {
                  //   return newValue.pop();
                  // }
                  if (newValue?.length === 0) {
                    const newOrdersFilter = {
                      ...ordersSearchFilters,
                      merchantId: undefined,
                      filteredMerchantIds: undefined
                    };
                    setOrdersSearchFilters(newOrdersFilter);
                    getAllOrders(newOrdersFilter);

                    return;
                  }

                  setMerchantSearchValue(newValue);
                  const newMerchantFilter = newValue.map((item) => {
                    return JSON.parse(item.value).merchant_id;
                  });

                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    // merchantId: newMerchantFilter,
                    filteredMerchantIds: newMerchantFilter,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ height: 30 }} span={5}>
            <Form.Item name="customerId">
              <DebounceInput
                value={customerSearchValue}
                placeholder="customer"
                fetchOptions={fetchCustomersDropdown}
                onChange={(newValue) => {
                  if (newValue?.length > 1) {
                    return newValue.pop();
                  }

                  if (newValue?.length === 0) {
                    const newOrdersFilter = {
                      ...ordersSearchFilters,
                      customerId: undefined,
                    };
                    setOrdersSearchFilters(newOrdersFilter);
                    getAllOrders(newOrdersFilter);

                    return;
                  }

                  setCustomerSearchValue(newValue);
                  const newCustomerFilter = JSON.parse(
                    newValue[0].value,
                  ).customer_id;

                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    customerId: newCustomerFilter,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ height: 30 }} span={5}>
            <Form.Item name="status">
              <RangePicker
                onChange={(newVal) => {
                  const newDateString = `${newVal[0].format(
                    'YYYY-MM-DD',
                  )}/${newVal[1].format('YYYY-MM-DD')}`;
                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    date: newDateString,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
          <Col style={{ height: 30 }} span={2}>
            <RemoteSelectForm
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              nameForm="city"
              allowClear={false}
              filterFromServer={false}
              endpoint="/cities/list"
              valueOption="city_code"
              labelOption="city_code"
              placeholder={'city'}
              onValueChange={({ object }) => {
                const newOrdersFilter = {
                  ...ordersSearchFilters,
                  city: object.value === 'all' ? undefined : object.value,
                };

                getAllOrders(newOrdersFilter);
                setOrdersSearchFilters(newOrdersFilter);
              }}
            />
          </Col>
          <Col style={{ height: 30 }} span={2}>
            <Form.Item name="agentId">
              <DebounceInput
                value={agentSearchValue}
                placeholder="Agent"
                fetchOptions={fetchAgentsDropdown}
                onChange={(newValue) => {
                  if (newValue?.length > 1) {
                    return newValue.pop();
                  }

                  if (newValue?.length === 0) {
                    const newOrdersFilter = {
                      ...ordersSearchFilters,
                      agentId: undefined,
                    };
                    setOrdersSearchFilters(newOrdersFilter);
                    getAllOrders(newOrdersFilter);

                    return;
                  }

                  setAgentSearchValue(newValue);
                  const newAgentFilter = JSON.parse(newValue[0].value)._id;

                  const newOrdersFilter = {
                    ...ordersSearchFilters,
                    agentId: newAgentFilter,
                  };

                  getAllOrders(newOrdersFilter);
                  setOrdersSearchFilters(newOrdersFilter);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

OrderFilterInputsComponent.propTypes = {};

export default OrderFilterInputsComponent;
