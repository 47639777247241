import React, { useCallback, useEffect, useRef, useState } from 'react';
import FlatList from 'flatlist-react/lib';
import { Button, Col, Input, Row, Tag, Typography } from 'antd';
import ChatRoomListItem from './ChatRoomListItem';
import chatStates from './chatStates';
import _ from 'lodash';
const CUSTOMER_CHATS = 'customer_agent,customer_general';
const { Text } = Typography;

const { Search } = Input;

function ChatRoomList({
  height,
  chatRooms,
  triggerLastAction,
  activeFilters,
  onSearch,
  setSelectedMessageTab
}) {
  const [filteredChatRooms, setFilteredChatRooms] = useState(chatRooms);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);

  useEffect(() => {
    if (
      activeFilters.roomType ||
      activeFilters.roomName ||
      activeFilters.roomLanguage ||
      activeFilters.issueTags ||
      activeFilters.roomAssignedToName
    ) {
      console.log('activeFilters', activeFilters);
      let filteredRooms = chatRooms;
      if (activeFilters.roomType) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomType === activeFilters.roomType;
        });
      }
      if (activeFilters.roomName) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomName === activeFilters.roomName;
        });
      }
      if (activeFilters.roomLanguage) {
        filteredRooms = filteredRooms.filter((room) => {
          return room.roomLanguage === activeFilters.roomLanguage;
        });
      }
      if (activeFilters.issueTags) {
        filteredRooms = filteredRooms.filter((room) =>
          // room.issueTags = [{name: 'tag1'}, {name: 'tag2'}]
          room?.issueTags?.find((tag) => tag.name === activeFilters.issueTags),
        );
      }
      if (activeFilters.roomAssignedToName) {
        filteredRooms = filteredRooms.filter(
          (room) =>
            room.roomAssignedToName === activeFilters.roomAssignedToName,
        );
      }
      const activeFiltersCount = Object.keys(activeFilters).filter(
        (key) => activeFilters[key],
      ).length;
      setActiveFiltersCount(activeFiltersCount);
      setFilteredChatRooms(filteredRooms);
    } else {
      setActiveFiltersCount(0);
      setFilteredChatRooms(chatRooms);
    }
  }, [activeFilters, chatRooms]);



  const onChatSelect = useCallback(
    (chatRoom) => {
      setSelectedMessageTab('1')
      chatStates.selectedChatRoomState.merge({
        selected_id: chatRoom._id,
        selected_chatroom: chatRoom,
      });
      triggerLastAction();
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );

  const isSelected = useCallback(
    (chatRoom) => {
      return (
        chatStates.selectedChatRoomState.selected_id.get() === chatRoom._id
      );
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <ChatRoomListItem
          key={item._id}
          item={item}
          onSelect={() => onChatSelect(item)}
          isSelected={isSelected(item)}
        />
      );
    },
    [onChatSelect, isSelected],
  );

  return (
    <div
      style={{
        width: '100%',
        height: height,
        maxHeight: height,
        minHeight: height,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #E9EDF5',
        overflowY: 'auto',
        // backgroundColor: '#E9EDF5',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <Row
        // give space between search and filter button
        style={{
          width: '96%',
          maxWidth: '100%',
          marginBottom: '0px',
          alignContent: 'space-between',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Col span={12}>
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
          />
        </Col>
        {/* <Col span={5}>
          <Button onClick={toggleFilterDrawer} color="danger">
            Filters
          </Button>
        </Col>
        <Col span={5}>
          <Button onClick={clearFiltersAndSearch} color="danger">
            ({activeFiltersCount}) Reset
          </Button>
        </Col> */}
      </Row>

      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #E9EDF5',
          overflowY: 'auto',
          // backgroundColor: '#E9EDF5',
          paddingTop: '10px',
        }}
      >
        {filteredChatRooms.length > 0 ? (
          <FlatList list={filteredChatRooms} renderItem={renderItem} />
        ) : (
          <Text>No chat rooms</Text>
        )}
      </div>
    </div>
  );
}

export default ChatRoomList;
