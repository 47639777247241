import { Button, Card, Col, Form, Input, Modal, Row, message } from 'antd';
import React from 'react';
import RemoteSelectForm from '../../utility/RemoteSelectForm';
import { useAxiosGetTaskManagement, useAxiosPostTaskManagement } from '../../../configs/axios';

const CreateTicketForProduct = ({ open, order_id, setOpen }) => {
  const { request: requestCreateTicket, loading: loadingCreateTicket } =
  useAxiosPostTaskManagement('/OperationTasks', {
      autoRun: false,
    });

  const onFinish = async (values) => {
    try {
  
      const body = {
        issue_id: values.issue_id.value,
        issue_source_type: 'product',
        issue_source_id: open,
        created_by_source: 'agent',
        task_comment: values.description,
      };
      await requestCreateTicket(body);
      message.success('operation successful');
    } catch {
      message.error('something went wrong');
    }
  };
  const onClose = () => {
    setOpen(null);
  };
  return (
    <Modal open={open} footer={null} onCancel={onClose}>
      <Card>
        <Form
          layout="vertical"
          style={{
            width: '100%',
          }}
          onFinish={onFinish}
        >
          <Row gutter={[10, 0]}>
            <Col
              span={24}
              style={{
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Create A Ticket
              </span>
            </Col>
            <Col span={24}>
              <Form.Item>
                <RemoteSelectForm
                  endpoint={'/OperationTasks/issueList'}
                  labelForm={'Issue'}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                  style={{
                    width: '100%',
                  }}
                  valueOption={'task_issue_id'}
                  labelOption={'task_issue_name'}
                  nameForm="issue_id"
                  allowClear={true}
                  placeholder={'Select a issue'}
                  query={{
                    issueType: 'product',
                  }}
                  fetchAxios={useAxiosGetTaskManagement}
                />
              </Form.Item>
            </Col>
            <Col
              span={18}
              style={{
                marginTop: -30,
              }}
            >
              <Form.Item name={'description'} label="Description">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                style={{
                  width: '100%',
                  backgroundColor: '#4BB543',
                }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default CreateTicketForProduct;
