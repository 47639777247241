import { Button, Col, Form, message, Modal, Row, Select } from 'antd';
import { useAxiosTaskManagementPut } from '../../../configs/axios';

function SendToOtherDepartment({ task, open, onClose }) {

  const { loading: sendToCourierLoading, request: sendToCourierRequest } =
    useAxiosTaskManagementPut('/courier/reAssignCourier', {});
  const [form] = Form.useForm();
  const supportDepartment = Form.useWatch('support_department', form);
    const onFinish =async (values) => {
     const body={
        operation_task_id: task.operation_task_id,
        issue_id: values.issue,

     };
        try {
           await  sendToCourierRequest(body);
            onClose();
            message.success('Task sent to other department successfully');
        }
        catch (e) {
            message.error('Failed to send task to other department');
            console.log(e);
        }
  };

  const issues = [
    {
      name: 'Merchant Support',
      values: 1,
      issues: [
        { value: 206, name: 'Customer location is wrong' },
        { value: 207, name: 'Customer refuse to pay the order' },
        { value: 222, name: 'Customer claims did not order.' },
        { value: 224, name: 'Completed order, customer has problem.' },
        { value: 204, name: "I can't find my order" },
        { value: 193, name: 'M: Closed' },
        { value: 194, name: 'M: Bad Behaviour' },
        { value: 202, name: 'My order is not ready' },
        { value: 203, name: "I can't find the merchant" },
        { value: 209, name: 'Merchant needs more time to prepare the order' },
        { value: 227, name: 'Item not available.' },
        { value: 228, name: 'Item price wrong.' },
        { value: 195, name: 'C: Bad Behaviour' },
        { value: 205, name: 'Customer not answering calls' },
        { value: 225, name: 'Accidentally completed order.' },
        { value: 208, name: 'Customer reporting item is missing' },
      ],
    },
    {
      name: 'Driver Support',
      values: 2,
      issues: [
        { value: 196, name: 'My shift is wrong' },
        { value: 197, name: 'I want to extend my shift' },
        { value: 198, name: 'I Want to leave early' },
        { value: 199, name: "I'm not getting orders" },
        { value: 200, name: 'My bike is damaged' },
        { value: 201, name: "I'm out of fuel" },
        { value: 234, name: 'Order rejected, but customer got it.' },
        { value: 211, name: 'My bike is damaged' },
        { value: 223, name: 'Received opposing orders.' },
        { value: 226, name: 'Another driver took my order.' },
        { value: 229, name: 'Car required.' },
        { value: 230, name: "Can't get to customer (roadblock)." },
        { value: 233, name: "Please complete order, I can't." },
        { value: 204, name: "I can't find my order" },
      ],
    },
    {
      name: 'Customer Support',
      values: 3,
      issues: [
        { value: 231, name: 'Customer claims received order.' },
        { value: 232, name: "Don't have change." },
        {
          value: 235,
          name: 'Customer confirmed with merchant to reject the order.',
        },
        {
          value: 236,
          name: 'Customer confirmed with driver to reject the order.',
        },
        { value: 206, name: 'Customer location is wrong' },
        { value: 207, name: 'Customer refuse to pay the order' },
        { value: 222, name: 'Customer claims did not order.' },
        { value: 224, name: 'Completed order, customer has problem.' },
      ],
    },
    {
      name: 'Garage',
      values: 4,
      issues: [
        { value: 239, name: 'My bike is damaged captain' },
        { value: 240, name: 'I my shift is wrong captain' },
        { value: 241, name: 'I want extend my shift captain' },
        { value: 242, name: 'I want leave early captain' },
        { value: 243, name: "I'm not getting orders captain" },
        { value: 244, name: "I'm out of fuel captain" },
      ],
    },
  ];

  return (
    <Modal
      onCancel={onClose}
      footer={null}
      open={open}
      width={500}
      onClose={onClose}
      title={'Send to other department'}
    >
      <Form onFinish={onFinish} form={form}>
        <Row gutter={[10, 20]} justify={'end'}>
          <Col span={24}>
            <Form.Item
              layout="vertical"
              name={'support_department'}
              label={'Support Department'}
              rules={[
                { required: true, message: 'Please select support department' },
              ]}
            >
              <Select
                placeholder={'Support Department'}
                onChange={() => {
                  form.setFieldsValue({ issue: undefined });
                }}
                options={issues.map((issue) => ({
                  label: issue.name,
                  value: issue.values,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              layout="vertical"
              name={'issue'}
              label={'Issue'}
              rules={[{ required: true, message: 'Please select issue' }]}
            >
              <Select
                disabled={!supportDepartment}
                placeholder={'Issue'}
                options={issues[supportDepartment - 1]?.issues.map((issue) => ({
                  label: issue.name,
                  value: issue.value,
                }))}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={sendToCourierLoading}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SendToOtherDepartment;
