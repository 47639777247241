import React, { useCallback, useEffect, useRef, useState } from 'react';
import FlatList from 'flatlist-react/lib';
import { Button, Col, Input, Row, Tag, Typography } from 'antd';
import ChatRoomListItem from './ChatRoomListItem';
import chatStates from './chatStates';
import _ from 'lodash';
import { useAxiosGetChat } from '../../configs/axios';
const CUSTOMER_CHATS = 'customer_agent,customer_general';
const { Text } = Typography;

const { Search } = Input;

function ResolvedChatRoomList({
  height,
  triggerLastAction,

}) {


  const {
    data: chatRooms,
   
  } = useAxiosGetChat(`/chats/v2/agents/resolvedRooms`, {
    autoRun: true,
    status: 'done',
  });


  const onChatSelect = useCallback(
    (chatRoom) => {
      chatStates.selectedChatRoomState.merge({
        selected_id: chatRoom._id,
        selected_chatroom: chatRoom,
      });
      // triggerLastAction();
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );

  const isSelected = useCallback(
    (chatRoom) => {
      return (
        chatStates.selectedChatRoomState.selected_id.get() === chatRoom._id
      );
    },
    [
      chatStates?.selectedChatRoomState?.selected_id,
      chatStates?.selectedChatRoomState?.selected_chatroom,
    ],
  );

  const renderItem = useCallback(
    (item, index) => {
      return (
        <ChatRoomListItem
          key={item._id}
          item={item}
          onSelect={() => onChatSelect(item)}
          isSelected={isSelected(item)}
        />
      );
    },
    [onChatSelect, isSelected],
  );

  return (
    <div
      style={{
        width: '100%',
        height: height,
        maxHeight: height,
        minHeight: height,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #E9EDF5',
        overflowY: 'auto',
        // backgroundColor: '#E9EDF5',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
     

      <div
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #E9EDF5',
          overflowY: 'auto',
          // backgroundColor: '#E9EDF5',
          paddingTop: '10px',
        }}
      >
       
          <FlatList list={chatRooms||[]} renderItem={renderItem} />
        
      </div>
    </div>
  );
}

export default ResolvedChatRoomList;
