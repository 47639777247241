import { Button, Popconfirm, message } from 'antd';
import React from 'react';
import {  useAxiosPut } from '../../../configs/axios';

function ChangeOrderPickUpAndDiningToOnDemand({
  closeModal,
  getAllOrders,
  selectedOrder,
}) {
 const{request:changeOrderRequest,loading:changeOrderLoading}= useAxiosPut('/orders/changeOrderType');
  const onConfirm = async () => {
    const body = {
      orderId: selectedOrder.orderId,
     
    };
   await  changeOrderRequest(body).then((res) => {
     message.success(res.message);
    closeModal();
    getAllOrders();
    });
  };
  return (
    <Popconfirm
    
    title="Are you sure to change order type to On Demand?"
    onConfirm={onConfirm}
    >
      <Button
      loading={changeOrderLoading}
      style={{
        justifyContent: 'start',
        alignItems: 'center',
        alignContent: 'start',
        display: 'flex',
      }}
    >
      {selectedOrder.orderTypeId == 5 ? 'Dining' : 'Pick up'}
      <span
        style={{
          marginLeft: '10px',
          marginRight: '10px',
          fontSize: '20px',
          marginTop: '1px',
        }}
      >
        ➜
      </span>
      On Demand
    </Button>
    </Popconfirm>

  );
}

export default ChangeOrderPickUpAndDiningToOnDemand;
