const chatFilters = {
  merchant: {
    name: 'Merchant',
    imageKey: 'chatRoomImage',
    nameKey: 'roomName',
    type: 'customer_agent',
    filterKey: 'roomName',
  },
  language: {
    name: 'Language',
    imageKey: null,
    nameKey: 'roomLanguage',
    type: null,
    filterKey: 'roomLanguage',
  },
  issue: {
    name: 'Issue',
    imageKey: null,
    nameKey: 'issueTags',
    color: 'color',
    type: null,
    filterKey: 'issueTags',
  },
  agent: {
    name: 'Agent',
    imageKey: null,
    nameKey: 'roomAssignedToName',
    type: null,
    filterKey: 'roomAssignedToName',
  },
};

export const generateFiltersData = (chatRooms) => {
  let merchantRooms = [];
  let languageRooms = [];
  let issueTagRooms = [];
  let agentRooms = [];
  chatRooms.current.forEach((room) => {
    // Merchant
    if (
      room.roomType === chatFilters.merchant.type &&
      room[chatFilters.merchant.nameKey] &&
      room[chatFilters.merchant.nameKey].length > 0
    ) {
      // Check if already exists, increase count
      const merchantIndex = merchantRooms.findIndex(
        (merchant) => merchant.name === room[chatFilters.merchant.nameKey],
      );

      if (merchantIndex > -1) {
        merchantRooms[merchantIndex].count += 1;
      } else {
        merchantRooms.push({
          name: room[chatFilters.merchant.nameKey],
          image: room[chatFilters.merchant.imageKey],
          count: 1,
          filterKey: chatFilters.merchant.filterKey,
        });
      }
    }
    // Language
    if (
      (!chatFilters.language.type ||
        room.roomType === chatFilters.language.type) &&
      room[chatFilters.language.nameKey] &&
      room[chatFilters.language.nameKey].length > 0
    ) {
      // Check if already exists, increase count
      const languageIndex = languageRooms.findIndex(
        (language) => language.name === room[chatFilters.language.nameKey],
      );

      if (languageIndex > -1) {
        languageRooms[languageIndex].count += 1;
      } else {
        languageRooms.push({
          name: room[chatFilters.language.nameKey],
          count: 1,
          filterKey: chatFilters.language.filterKey,
        });
      }
    }
    // Issue Tags
    if (
      (!chatFilters.issue.type ||
        room.roomType === chatFilters.issue.type) &&
      room[chatFilters.issue.nameKey] &&
      room[chatFilters.issue.nameKey].length > 0
    ) {
      if (
        room[chatFilters.issue.nameKey] &&
        room[chatFilters.issue.nameKey].length > 0
      ) {
        room[chatFilters.issue.nameKey].forEach((tag) => {
          // Check if already exists, increase count
          const tagIndex = issueTagRooms.findIndex(
            (issueTag) => issueTag.name === tag.name,
          );
          if (tagIndex > -1) {
            issueTagRooms[tagIndex].count += 1;
          } else {
            issueTagRooms.push({
              name: tag.name,
              color: tag.color,
              count: 1,
              filterKey: chatFilters.issue.filterKey,
            });
          }
        });
      }
    }

    // Agent
    if (
      (!chatFilters.agent.type || room.roomType === chatFilters.agent.type) &&
      room[chatFilters.agent.nameKey] &&
      room[chatFilters.agent.nameKey].length > 3
    ) {
      // Check if already exists, increase count
      const agentIndex = agentRooms.findIndex(
        (agent) => agent.name === room[chatFilters.agent.nameKey],
      );
      if (agentIndex > -1) {
        agentRooms[agentIndex].count += 1;
      } else {
        agentRooms.push({
          name: room[chatFilters.agent.nameKey],
          count: 1,
          filterKey: chatFilters.agent.filterKey,
        });
      }
    }
  });

  //sort all by count
  merchantRooms = merchantRooms.sort((a, b) => b.count - a.count);
  languageRooms = languageRooms.sort((a, b) => b.count - a.count);
  issueTagRooms = issueTagRooms.sort((a, b) => b.count - a.count);
  agentRooms = agentRooms.sort((a, b) => b.count - a.count);
  return {
    merchantRooms,
    languageRooms,
    issueTagRooms,
    agentRooms,
  };
};
