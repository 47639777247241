const MenuIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#D8D8D8" />
      <circle cx="8" cy="5" r="1" fill="#161B21" />
      <circle cx="8" cy="8" r="1" fill="#161B21" />
      <circle cx="8" cy="11" r="1" fill="#161B21" />
    </svg>
  );
};

export default MenuIcon;
