import { CloseOutlined } from '@ant-design/icons';
import {
  Image,
  Spin,
  Tooltip,
  Upload,
  message as alertMessage,
  Skeleton,
  Divider,
} from 'antd';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAxiosGetChat } from '../../configs/axios';
import ChatMap from './ChatMap';
function ChatMediaTab({ selectedChatroom, selectTab, source }) {
  const [messages, setMessages] = useState([]);
  const [canNotLoadMore, setCanNotLoadMore] = useState(false);
  const messagePerPage = 5;
  const isLoadMore = useRef(false);
  const messagePage = useRef(0);

  const { loading: chatroomMessagesLoading, request: chatroomMessagesRequest } =
    useAxiosGetChat('/chats/messages');
    const onLoadMoreMessages = async (chatroom) => {
    try {
      messagePage.current = messagePage.current + 1;
      if (isLoadMore.current) return;
      isLoadMore.current = true;
      const data = await chatroomMessagesRequest({
        chatSource: source,
        roomType: selectedChatroom.roomType,
        roomRef: selectedChatroom.roomRef,
        roomId: selectedChatroom._id,
        page: messagePage.current,
        limitMessages: true,
        limit: messagePerPage,
        messageId: messages[messages.length - 1]?._id,

      });
      if (data.messages.length === 0 && !canNotLoadMore) {
        setCanNotLoadMore(true);
      }

      setMessages([...messages, ...data.messages]);
      isLoadMore.current = false;
    } catch (e) {
      isLoadMore.current = false;
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedChatroom, selectTab]);
  const fetchData = async () => {
    if (selectedChatroom && selectTab === '3') {
      messagePage.current = 0;
      const data = await chatroomMessagesRequest({
        chatSource: source,
        roomType: selectedChatroom.roomType,
        roomRef: selectedChatroom.roomRef,
        roomId: selectedChatroom._id,
        page: 0,
        limitMessages: true,
        limit: messagePerPage,
        messageId: messages[messages.length - 1]?._id,
      });
      setMessages(data.messages);
    }
  };

  const renderTimeAgo = (date1, date2) => {
    if (date2 == undefined) {
      return null;
    }
    const time = dayjs(date1).format('YYYY-MM-DD').toString();
    const time2 = dayjs(date2).format('YYYY-MM-DD').toString();
    const con = time !== time2;
    if (con) {
      return (
        <div
          style={{
            opacity: '0.5',
            fontWeight: 'bold',
            fontSize: '12px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '1px',
              backgroundColor: '#4A5486',
              flex: 1,
              marginRight: 10,
            }}
          ></div>
          <span>
            {dayjs().diff(dayjs(time), 'day') == 0
              ? 'Today'
              : dayjs().diff(dayjs(time), 'day') == 1
              ? 'Yesterday'
              : dayjs(date1).format('DD MMM')}
          </span>
          <div
            style={{
              height: '1px',
              backgroundColor: '#4A5486',
              flex: 1,
              marginLeft: 10,
            }}
          ></div>
        </div>
      );
    }

    return null;
  };
  const tabs = [
    {
      id: 1,
      value: 'orders',
    },
    {
      id: 2,
      value: 'couriers',
    },
    {
      id: 3,
      value: 'customer_agent',
    },
    {
      id: 4,
      value: 'customer_general',
    },
    {
      id: 5,
      value: 'merchant_driver',
    },
    {
      id: 6,
      value: 'merchant_agent',
    },
    {
      id: 7,
      value: 'customer_driver',
    },
    {
      id: 8,
      value: 'customer_merchant',
    },
  ];
  /// false is left
  /// true is right
  const sides = {
    couriers: {
      canSendMessage: true,
      messageSource: {
        courier: false,
        'courier-system': false,
        'cs-agent': true,
        agent: true,
      },
    },
    orders: {
      canSendMessage: true,
      messageSource: { courier: false, 'cs-agent': true, agent: true },
    },
    customer_agent: {
      canSendMessage: true,
      messageSource: { customer: false, 'cs-agent': true, agent: true },
    },
    customer_general: {
      canSendMessage: true,
      messageSource: { customer: false, 'cs-agent': true, agent: true },
    },
    merchant_driver: {
      canSendMessage: false,
      messageSource: { merchant: false, driver: true },
    },
    merchant_agent: {
      canSendMessage: true,
      messageSource: { merchant: false, 'cs-agent': true, agent: true },
    },
    customer_driver: {
      canSendMessage: false,
      messageSource: { customer: false, driver: true },
    },
    customer_merchant: {
      canSendMessage: false,
      messageSource: { customer: false, merchant: true },
    },
  };
  const renderMessage = (chatContent, index) => {
    const isRight = sides[source].messageSource[chatContent.messageSource];
    const isPrevMessageIdSame =
      chatContent.createdById === messages[index - 1]?.createdById;
    const isNextMessageIdSame =
      chatContent.createdById === messages[index + 1]?.createdById;
    const bubbleColor = (isRight, loading, error) => {
      if (loading) {
        return '#1677ff55';
      } else if (error) {
        return '#ff0000aa';
      } else if (isRight) {
        return '#1677ff';
      }
      return '#F4F5F7';
    };
    return (
      <div
        key={index}
        style={{
          paddingInline: '20px',
          paddingBlock: '2px',
        }}
      >
        {renderTimeAgo(chatContent.createdAt, messages[index + 1]?.createdAt)}
        {!isNextMessageIdSame ? (
          <div
            style={{
              opacity: '0.5',
              fontWeight: 'bold',
              fontSize: '12px',
              marginBottom: '5px',
              display: 'flex',
              justifyContent: isRight ? 'flex-end' : 'flex-start',
            }}
          >
            {chatContent.createdByName}
          </div>
        ) : null}
        <div
          key={chatContent._id}
          style={{
            justifyContent: isRight ? 'flex-end' : 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: isRight ? 'row-reverse' : 'row',
            }}
          >
            {!isNextMessageIdSame ? (
              <div
                style={{
                  width: '40px',
                  // height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'end',
                }}
              >
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  src={chatContent.createdByImage}
                  alt=""
                />
              </div>
            ) : (
              <div
                style={{
                  width: '40px',
                  objectFit: 'cover',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  display: 'flex',
                }}
              ></div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                padding: '7px',
                borderRadius: '10px',
                backgroundColor: bubbleColor(
                  isRight,
                  chatContent?.loading,
                  chatContent?.error,
                ),
                color: isRight ? 'white' : 'black',
                maxWidth: '260px',
                minWidth: '120px',

                ////is left
                borderEndStartRadius:
                  !isRight && !isNextMessageIdSame && isPrevMessageIdSame
                    ? '0px'
                    : '10px',

                borderStartStartRadius:
                  !isRight && !isPrevMessageIdSame && isNextMessageIdSame
                    ? '0px'
                    : '10px',

                ////is Right
                borderEndEndRadius:
                  isRight && !isNextMessageIdSame && isPrevMessageIdSame
                    ? '0px'
                    : '10px',

                borderStartEndRadius:
                  isRight && !isPrevMessageIdSame && isNextMessageIdSame
                    ? '0px'
                    : '10px',
              }}
            >
              {chatContent.chatType === 'image' ? (
                <Image src={chatContent.chatMessage} width={200} />
              ) : chatContent.chatType === 'coordinate' ? (
                <div
                  style={{
                    width: '240px',
                    height: '260px',
                  }}
                >
                  <ChatMap chat={chatContent} />
                </div>
              ) : (
                <span
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {chatContent.chatMessage}
                </span>
              )}
              <div
                style={{
                  fontSize: '10px',
                  color: isRight ? 'white' : 'black',
                  // float: 'right',
                  alignSelf: 'flex-end',
                }}
              >
                {moment(chatContent.createdAt).format('hh:mm A')}
              </div>
            </div>
          </div>
        
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        // backgroundColor: '#757575',
        paddingBlock: '10px',
        height: '100%',
        marginTop: -15,
      }}
    >
      {/* chat contents */}
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 190px)',
          // backgroundColor: '#757575',
          position: 'relative',
        }}
        id="chat-content"
      >
        {chatroomMessagesLoading && messages.length == 0 ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div
            id="scrollableDiv2"
            style={{
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={onLoadMoreMessages}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              hasMore={true}
              inverse={true}
              loader={
                canNotLoadMore ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ color: 'grey' }}>No more messages</span>
                  </div>
                ) : (
                  <Skeleton
                    avatar
                    paragraph={{ rows: 1 }}
                    active
                    style={{
                      // paddingLeft: '10px',
                      paddingRight: '90px',
                      width: '90%',
                      alignSelf: 'center',
                    }}
                  />
                )
              }
              endMessage={
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: 'grey', marginBottom: 25 }}>
                    No more messages
                  </span>
                </div>
              }
              scrollableTarget="scrollableDiv2"
            >
              {messages.map((chatContent, index) => {
                return renderMessage(chatContent, index);
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>

      {/* chat actions */}
    </div>
  );
}

export default ChatMediaTab;
