import { Button, Form, Input, Select, message } from 'antd';
import React from 'react';
import { useAxiosGet, useAxiosPost } from '../../../configs/axios';

function PenaltyMerchants(props) {
  const { getAllOrders, selectedOrder, closeModal } = props;

  const { loading: merchantPenaltyLoading, request: merchantPenaltyReq } =
    useAxiosPost('/merchantPenalties');
  const { data: getAllPenaltyMerchant, loading: penaltyMerchantLoading } =
    useAxiosGet('/merchantPenalties', { autoRun: true });

  let options = [];
  getAllPenaltyMerchant?.forEach((penalty) =>
    options.push({
      value: penalty.merchant_penalty_reason_id,
      label: penalty.reason,
    }),
  );

  async function onFinishPenalty(values) {
    const body = {
      ...values,
      orderId: selectedOrder.orderId,
      merchantId: selectedOrder.merchant.merchant_id,
    };
    await merchantPenaltyReq(body);
    message.success("Merchant Penalty Added");
    closeModal();
    getAllOrders();
  }
  return (
    <Form layout="vertical" onFinish={onFinishPenalty}>
      <Form.Item name="penaltyReasonId" label="Reason">
        <Select loading={penaltyMerchantLoading} options={options} />
      </Form.Item>
      <Form.Item label="Note" name="note">
        <Input />
      </Form.Item>
      <Button loading={merchantPenaltyLoading} type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}

export default PenaltyMerchants;
