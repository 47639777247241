import { Form, Input, Modal, Select, message } from "antd";
import { useAxiosPost } from "../../configs/axios";

export default function CustomerVIPModal({open, setOpen, customer}){

    const [vipForm] = Form.useForm();

    const { loading: vipLoading, request: makeCustomerVip ,error:vipErr} =
    useAxiosPost('/vip');


    const onVipFormConfirm = async (values) => {
        try {
          const customerId = customer.customer_id
          const tierId = values.tierId.value
          const orderId = values.orderId
          await makeCustomerVip({
            customerId: customerId,
            tierId: tierId,
            orderId: orderId
          }).then(()=>{
              message.success('Customer subscribed to VIP successfully');
              vipForm.resetFields();
          })

        } catch (e) {
          message.error('Error subscribing VIP customer');
        }
      };
    return <Modal
    title="Make Customer VIP"
    destroyOnClose
    open={open}
    onOk={() => {
      vipForm.submit();
    }}
    okButtonProps={{
      loading: vipLoading,
    }}
    onCancel={() => {
      setOpen(false)
    }}
  >
    <Form
      loading={true}
      form={vipForm}
      layout="vertical"
      onFinish={onVipFormConfirm}
    >

      {/* <Form.Item name="customerId" >
        <DebounceInput
          value={customerSearchValue}
          placeholder="Filter by customer"
          fetchOptions={fetchCustomersDropdown}
          onChange={(newValue) => {
            if (newValue?.length > 1) {
              return newValue.pop();
            }
            setCustomerSearchValue(newValue);
          }}
        />
      </Form.Item> */}
      <Form.Item
        name="tierId"
        label="Tier Id"
        rules={[
          {
            required: true,
            message: "Please input amount",
          },
          {
            validator: (_, value) => {
              if (value > 5) {
                return Promise.reject(
                  new Error("Amount must be less than 5")
                );
              }
              if (value < 0) {
                return Promise.reject(
                  new Error("Amount must be greater than 0")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          labelInValue
          filterOption={false}
          options={[
            {
              label: "Eco",
              value: 4,
            },
            {
              label: "Standard",
              value: 1,
            },
            {
              label: "3 Month Standard",
              value: 2,
            },
            {
              label: "1 Year Standard",
              value: 3,
            }
          ]}
        />
      </Form.Item>
      <Form.Item
        name="orderId"
        label="Order Id"
        rules={[
          {
            required: false,
            message: "Please input order id",
          },

        ]}
      >
        <Input type="number" placeholder="E.g. 134..." />
      </Form.Item>

    </Form>
  </Modal>
}