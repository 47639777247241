import React from "react";
import { Button, Form, message, Select } from "antd";
import { useAxiosGet, useAxiosPost } from "../../../configs/axios";

function AssignAgentForm(props) {
  const { selectedOrder, getAllOrders, ordersSearchFilters, closeModal } =
    props;

  const { data: usersData, loading: usersLoading } = useAxiosGet(
    "/users/assign",
    {
      autoRun: true,
    }
  );

  const { request: assignAgentRequest, loading: assignAgentLoading } =
    useAxiosPost("/orders/assignAgent");

  const onAssignAgent = async (values) => {
    try {
      const requestBody = {
        orderId: selectedOrder.orderId,
        agentId: values.agentId,
      };

      await assignAgentRequest(requestBody);
      getAllOrders(ordersSearchFilters);
      closeModal();
      message.success("Agent assigned successfully");
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <Form onFinish={onAssignAgent} layout="vertical">
      <Form.Item label="Agent" name="agentId">
        <Select
          loading={usersLoading}
          placeholder="Select Agent"
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
          options={usersData?.map((user) => {
            return {
              value: user._id,
              label: user.userName,
            };
          })}
        />
      </Form.Item>

      <Button loading={assignAgentLoading} type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}

export default AssignAgentForm;
