import { Table, Typography, Tabs } from 'antd';
import React from 'react';
import AgentList from './ components/AgentList';
import AgentActivityTable from './ components/AgentActivityTable';


function SupportTeamMetrics() {
  const tabItems = [
    {
      key: '1',
      label: 'Agent List',
      children: <AgentList />, // Render AgentList component
    },
    {
      key: '2',
      label: 'Agent Activity',
      children: <AgentActivityTable />, // Render Table component
    }
  ];

  return (
    <div
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      <Typography.Title
        level={2}
        style={{
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        Support Team Metrics
      </Typography.Title>
      <Tabs items={tabItems} />
    </div>
  );
}

export default SupportTeamMetrics;
