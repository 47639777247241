const SendIcon = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.63319C8.00029 4.74724 7.97011 4.85929 7.91257 4.95776C7.85504 5.05623 7.77224 5.13754 7.67274 5.19328L0.955252 9.03431C0.858738 9.08903 0.749751 9.11797 0.638801 9.11832C0.536483 9.11811 0.435706 9.09338 0.344917 9.0462C0.254128 8.99901 0.175973 8.93075 0.117004 8.84713C0.0580338 8.76352 0.0199676 8.66698 0.00599596 8.56563C-0.00797572 8.46427 0.00255428 8.36103 0.0367032 8.26458L1.13088 5.06606C1.14166 5.03442 1.16208 5.00694 1.18928 4.9875C1.21647 4.96805 1.24907 4.95761 1.28251 4.95764H4.15937C4.20325 4.95774 4.24668 4.94881 4.28696 4.93142C4.32724 4.91403 4.36351 4.88854 4.39352 4.85654C4.42354 4.82453 4.44665 4.7867 4.46143 4.74539C4.47621 4.70408 4.48233 4.66017 4.47943 4.61639C4.47217 4.53409 4.43409 4.45757 4.37281 4.40215C4.31153 4.34674 4.23158 4.31651 4.14897 4.31754H1.28531C1.25192 4.3176 1.21936 4.30722 1.19217 4.28785C1.16498 4.26848 1.14453 4.24109 1.13368 4.20952L0.035903 1.009C-0.00684695 0.886363 -0.0112794 0.75362 0.0231945 0.628402C0.0576684 0.503184 0.129417 0.391415 0.228914 0.307937C0.32841 0.224459 0.450946 0.173222 0.580249 0.16103C0.709553 0.148838 0.839506 0.176268 0.952852 0.239677L7.67394 4.0759C7.7728 4.13156 7.85509 4.2125 7.91238 4.31042C7.96968 4.40835 7.99991 4.51974 8 4.63319Z"
        fill="#919191"
      />
    </svg>
  );
};

export default SendIcon;
