import { Button, Form, Input, InputNumber, message, Select } from "antd";
import React from "react";
import { useAxiosGet, useAxiosPost } from "../../../configs/axios";
const CreateProductForm = (props) => {
  const { selectedOrder, getAllOrders, ordersSearchFilters, closeModal } =
    props;
  const { data: categoriesData } = useAxiosGet("/categories", {
    autoRun: true,
    merchantId: selectedOrder.merchant.merchant_id,
  });

  const { request: addProductRequest, loading: addProductLoading } =
    useAxiosPost("/orders/addExtraProduct");

  const onSubmitNewProduct = async (values) => {
    try {
      const requestBody = {
        ...values,
        orderId: selectedOrder.orderId,
      };

      await addProductRequest(requestBody);
      message.success("Product added successfully");
      closeModal();
      getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error;
    }
  };
  return (
    <Form layout="vertical" onFinish={onSubmitNewProduct}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the product name!",
          },
        ]}
        label="Product Name"
        name="productName"
      >
        <Input placeholder=" Product Name" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the product name!",
          },
        ]}
        label="Product Price"
        name="productPrice"
      >
        <InputNumber
          style={{
            width: "100%",
          }}
          placeholder=" Product Price"
          type="number"
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please input the product name!",
          },
        ]}
        label="Product Quantity"
        name="quantity"
      >
        <InputNumber
          placeholder=" Product Quantity"
          type="number"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
      <Form.Item label="Category" name="categoryId">
        <Select
          placeholder="Select Product Category"
          options={categoriesData?.map((category) => ({
            label: category.product_category_name.en,
            value: category.product_category_id,
          }))}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={addProductLoading}>
        Submit
      </Button>
    </Form>
  );
};

export default CreateProductForm;
