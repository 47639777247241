import { Button, Col, Form, Modal, Row, message } from 'antd';
import styles from './styles/AssignAgentModal.module.css';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useAxiosGetChat, useAxiosPostChat } from '../../configs/axios';
import RemoteSelectForm from '../utility/RemoteSelectForm';
const AssignAgentModal = ({ selectedChatroom, chatSource }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <button
        className={styles.AssignAgentButton}
        onClick={() => setShowModal(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        <img src="/svg/agentIcon.svg" className={styles.agentIconStyle} />

      </button>
      {showModal ? (
        <AgentModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedChatroom={selectedChatroom}
          chatSource={chatSource}
        />
      ) : null}
    </div>
  );
};
const AgentModal = ({
  showModal,
  setShowModal,
  selectedChatroom,
  chatSource,
}) => {
  const { request: requestAssignChat ,loading:loadingAssignChat } = useAxiosPostChat('/agents/assign', {
    autoRun: true,
  });
  const onFinish = async (values) => {
    try {
      const body = {
        toAgentId: values.agent.value,
        comment: values.comment,
        roomId: selectedChatroom._id,
        chatSource:selectedChatroom.roomType,
      };

      await requestAssignChat(body);
      setShowModal(false);
      message.success('Agent assigned')
    }
    catch (e) {
      message.error('Something went wrong')
    }
  };
  return (
    <Modal
      title="Select a agent"
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <Form onFinish={onFinish}>
        <Row gutter={[10, 10]} justify={'end'}>
          <Col span={24}>
            <RemoteSelectForm
              nameForm={'agent'}
              fetchAxios={useAxiosGetChat}
              endpoint={'/agents'}
              labelOption={'userName'}
              valueOption={'_id'}
            />
          </Col>

          <Col span={24}>
            <Form.Item name={'comment'}>
              <TextArea className={styles.textAreaStyle } />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Col>
          <Col>
            <Button className={styles.submitButton} htmlType="submit"
            loading={loadingAssignChat}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default AssignAgentModal;
