import { useCallback, useEffect, useState } from 'react';
import useLocalStorage from '../../configs/localStorage';
import { useHookstate } from '@hookstate/core';
import { useAxiosGetChat, useAxiosPostChat, useAxiosPut } from '../../configs/axios';
import { Button, Input, Skeleton, Tabs, Tag, Typography } from 'antd';
import chatStates from './chatStates';
import ChatMessage from './Message';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import ChatScriptTab from '../Chat/ChatScriptTab';
import { darkModeLocalConst } from '../../configs/constants';
const { Search } = Input;
const format = 'hh:mm A';
const { Text } = Typography;
function MessageList({ socketRef, height, setSelectedMessageTab, selectedMessageTab }) {
  const {
    request: requestForChangeLocation,
    loading: loadingForChangeLocation,
  } = useAxiosPut('/orders/status');
  const [auth] = useLocalStorage('auth', null);
  const [message, setOnMessage] = useState('');
  const [chatroom, setChatroom] = useState(null);
  const [chatroomMessages, setChatroomMessages] = useState([]);
  const [colorLocalStorage] = useLocalStorage(
    darkModeLocalConst,
    null
  );
  const messagePerPage = 25;
  const selected_chatroom = useHookstate(
    chatStates.selectedChatRoomState.selected_chatroom,
  );
  const { loading: chatroomMessagesLoading, request: chatroomMessagesRequest } =
    useAxiosGetChat('/chats/messages');
  const { request: sendMessageRequest, loading: sendMessageIsLoading } =
    useAxiosPostChat('/chats');
  const { request: resolveChatroom } = useAxiosPostChat('/chats/resolveChat');

  const onMessageReceived = useCallback((data) => {
    const room = selected_chatroom.get();
    if (room?._id === data.chatRoomId) {
      console.log('Message received', data);
      const uniqueKey = Date.now();
      setChatroomMessages((prev) => [...prev, { ...data, _id: uniqueKey }]);
    }
  }, []);

  const sendMessage = async (message, messageType = 'text') => {
    const room = selected_chatroom?.get?.();

    if (!room || message.length == 0 || sendMessageIsLoading) {
      return;
    }
    try {
      setOnMessage('');
      const requestBody = {
        message,
        roomRef: room.roomRef,
        messageType,
        roomId: room._id,
        chatSource: room.roomType,
        messageSource: 'agent',
      };
      if (room.status != 'opened') {
        //update chatRoom status to opened
        selected_chatroom.set((prev) => ({
          ...prev,
          status: 'opened',
        }));
      }
      //setChatroomMessages((prev) => [...prev, newMessage]);
      await sendMessageRequest(requestBody);

      // Scroll to bottom
      const chat = document.getElementById('chat-messages');
      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    } catch (error) {
      console.log('Error sending message', error);
    }
  };

  useEffect(() => {
    const room = selected_chatroom.get();

    const roomChannel = `${room?.roomType}:${room?.roomRef}`;

    if (socketRef?.socket) {
      const socket = socketRef.socket;

      if (socket?.connected) {
        socket.emit('join', { room: roomChannel });

        socket.on('sent-message', onMessageReceived);
      }
    }

    return () => {
      if (socketRef?.socket) {
        const socket = socketRef.socket;
        socket.off('sent-message', onMessageReceived);
        socket.emit('left_room', {
          room: roomChannel,
          userId: auth?.user?.id,
        });
      }
    };
  }, [selected_chatroom?._id?.get?.(), socketRef?.socket]);

  const fetchChatMessages = async () => {
    const room = selected_chatroom.get();
    if (!room?._id) return;
    // Fetch chat messages
    const data = await chatroomMessagesRequest({
      chatSource: room.roomType,
      roomType: room.roomType,
      roomRef: room.roomRef,
      roomId: room?._id,
      page: 0,
      limit: messagePerPage,
    });

    setChatroom({ ...room, _id: data.roomId });
    const reversedMessages = _.reverse(data.messages);
    setChatroomMessages(reversedMessages);
  };

  useEffect(() => {
    fetchChatMessages();
  }, [selected_chatroom?._id?.get?.()]);

  return (
    selected_chatroom?._id?.get?.() && (
      <div
        style={{
          width: '100%',
          height: height,
          maxHeight: height,
          minHeight: height,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            backgroundColor: '#6244671',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4 style={{
            flex: 1,
          }}>{selected_chatroom?.get?.()?.roomName}</h4>
          <Button
            style={{
              marginRight: '10px',
              marginLeft: '10px',
            }}
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={() => {
              selected_chatroom.set({
                roomType: '',
                roomRef: '',
                roomType: '',
              });
            }}
          />
          <Button
            type="primary"
            onClick={async () => {
            try {
              const selectedChatroom = selected_chatroom.get();
              await resolveChatroom({
                roomType: selectedChatroom.roomType,
                roomRef: selectedChatroom.roomRef,
                chatSource: selectedChatroom.roomType,
              });
            } catch (error) {
              console.log('====================================');
              console.log(error);
              console.log('====================================');
            }

            }}
          >
            Resolve
          </Button>
        </div>
       
        <div
          style={{
            height: '100%',
            maxHeight: '100%',
            padding: '10px',
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            flexDirection: 'column',
            overflowY: 'auto',
            // backgroundColor: '#0B192C',
          }}
        > <div
        style={{
              backgroundColor: '#ffffff28',
          padding: '5px',
     
      }}
      >
        {selected_chatroom?.get?.()?.issueTags?.map((tag) => <Tag color={tag.color}>{tag.name}</Tag>)}
      </div>
       
          {chatroomMessagesLoading ? (
            <Skeleton active />
          ) : chatroomMessages?.length > 0 ? (
              
              <Tabs
                style={{
                  height:'100%'
                }}
                onChange={(e) => {
                  setSelectedMessageTab(e);
                }}
              activeKey={selectedMessageTab}
                items={[
                  {
                    key:'1',
                    label:'messages',
                    children: (<ChatMessage id="chat-messages" messages={chatroomMessages}
                      requestForChangeLocation={requestForChangeLocation}
                       loadingForChangeLocation={loadingForChangeLocation}
                    />)
                  },
                  {
                    key:'2',
                    label:'script',
                    children:(    <ChatScriptTab
                      setMessage={setOnMessage}
                      setSelectedTab={setSelectedMessageTab}
                      source={selected_chatroom?.get?.()?.roomType}
                      selectedChatroom={selected_chatroom?.get?.()}
                      colorLocalStorage={colorLocalStorage}
                    />)
                  }
                ]}
            />
          
           
          ) : null}
        </div>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#469',
          }}
        >
          <Search
            placeholder="Type a message"
            enterButton="Send"
            size="large"
            value={message}
            onChange={(e) => setOnMessage(e.target.value)}
            onSearch={(value) => {
              sendMessage(value);
            }}
          />
        </div>
      </div>
    )
  );
}

export default MessageList;
