import { Space, Modal, message, Col, Row, Button } from 'antd';
import ToInProgress from './icons/ToInProgress';
import RenderTasks from './RenderTasks';
import { useAxiosPut } from '../../configs/axios';
import classes from './style/task-board.module.css';
import { useState } from 'react';
import { current } from '@reduxjs/toolkit';

const SubTaskModal = ({
  selectedSubTasks,
  openSubTaskModal,
  onCloseSubTaskModal,
  extraProps,
  statusTexts,
  getTasks,
}) => {
  const { loading: changeTaskLoading, request: changeTaskRequest } =
    useAxiosPut('/OperationTasks/groupUpdate', {
      autoRun: true,
    });
  const [filter, setFilter] = useState(null);
  const changeTaskStatus = async ({}) => {
    try {
      let newStatus = '';
      switch (statusTexts.currentTask) {
        case 'todo':
          newStatus = 'in_progress';
          break;
        case 'in_progress':
          newStatus = 'done';
          break;
        case 'done':
          newStatus = 'todo';
          break;
                  default:
          newStatus = 'todo';
      }
      await changeTaskRequest({
        operation_task_ids: selectedSubTasks.map(
          (task) => task.operation_task_id,
        ),
        task_status: newStatus,
      });
      message.success('Task status updated successfully');
      getTasks();
      onCloseSubTaskModal();
    } catch (error) {
      message.error('Failed to update task status');
    }
  };
  const handleStatusClick = (issueName) => {
    if (filter === issueName) {
      setFilter(null);
      return;
    }
    setFilter(issueName);
  };

  const uniqueIssueTypes = {};

  selectedSubTasks.forEach((task) => {
    if (!uniqueIssueTypes[task.task_issue_name]) {
      uniqueIssueTypes[task.task_issue_name] = {
        name: task.task_issue_name,
        count: 1,
      };
    } else {
      uniqueIssueTypes[task.task_issue_name].count =
        uniqueIssueTypes[task.task_issue_name].count + 1;
    }
  });
  const getModalSize = () => {
    switch (
      (filter
        ? selectedSubTasks.filter((task) => task.task_issue_name === filter)
        : selectedSubTasks
      ).length
    ) {
      case undefined:
      case 0:
      case 1:
        return '560px';
      case 2:
        return '1070px';
      default:
        return '1580px';
    }
  };
  return (
    <Modal
      okText={statusTexts.nextStatusText}
      onOk={changeTaskStatus}
      okButtonProps={{ loading: changeTaskLoading }}
      open={openSubTaskModal}
      onCancel={onCloseSubTaskModal}
      width={getModalSize()}
    >
      <Row gutter={[10, 10]}>
        {Object.values(uniqueIssueTypes)?.map((issue, index) => (
          <Col key={index}>
            <Button
              className={classes.statusButton}
              onClick={() => handleStatusClick(issue.name)}
              style={{
                borderColor: filter === issue.name ? '#1890ff' : '#d9d9d9',
                color:
                  filter === issue.name ? '#1890ff' : 'rgba(0, 0, 0, 0.85)',
              }}
            >
              {issue.name}
              <span
                className={classes.circle}
                style={{
                  marginLeft: 10,
                }}
              >
                ({issue.count || 0})
              </span>
            </Button>
          </Col>
        ))}

        <Col span={24}>
          <Row
            gutter={[10, 0]}
            style={{
              width: '100%',
              maxHeight: '70vh',
              overflowY: 'auto',
            }}
            // className={classes.subTaskModal}
          >
            {(filter
              ? selectedSubTasks.filter(
                  (task) => task.task_issue_name === filter,
                )
              : selectedSubTasks
            ).map((task, index) => (
              <Col>
                <div className={classes.calSize}>
                  <RenderTasks
                    key={index}
                    task={task}
                    index={index}
                    statusText={statusTexts.nextStatusText}
                    statusCode={statusTexts.currentTask}
                    StatusIcon={ToInProgress}
                    {...extraProps}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default SubTaskModal;
