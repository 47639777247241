import { useRef } from 'react';
import { Button, Tooltip, Image } from 'antd';
import classes from './style/task-board.module.css';
import { useAxiosGet } from '../../configs/axios';
import Phone from './icons/Phone';
import Chat from './icons/Chat';
import MenuIcon from './icons/MenuIcon';
import ToInProgress from './icons/ToInProgress';
import RenderMenu from './RenderMenu';
import CustomerAndDriverDisplay from './CustomerandDrivertDisplay';
import AgentIcon from './icons/AgentIcon';
import TaskIcon from './icons/TaskIcon';
import SendIcon from './icons/SendIcon';
import VipIcon from './icons/VipIcon';
import ClockIcon from './icons/ClockIcon';
import { EditOutlined } from '@ant-design/icons';
import useToken from 'antd/es/theme/useToken';

const RenderTasks = ({
  task,
  statusCode,
  statusText,
  changeTaskStatus,
  changeTaskLoading,
  onOpenOrderMap,
  onOpenChangeOrderStatusModal,
  onOpenOrderCart,
  onOpenRefund,
  onOpenOrderEdit,
  onOpenAssignCourier,
  onOpenDrawerChat,
  onOpenMerchantInfo,
  copyToClipboard,
  onOpenTaskDrawer,
  statusColor,
  onOpenCustomerInfo,
  onOpenDriverInfo,
  board,
  sendCommend, sendCommentLoading, dayjs, onOpenEditProductIssueModal
}) => {
  const inputValue = useRef('');
  const {
    data: currentOrder,
    request: getCurrentOrder,
    loading: getCurrentOrderLoading,
  } = useAxiosGet('/orders/getOrderById', {
    orderId: task.source_id,
    autoRun: false,
  });
  const onOpenActionMenuChange = async (open, e) => {
    if (!open) {
      return;
    }
    if (task.issue_source_type == 'order' && !currentOrder) {
      const data = await getCurrentOrder({
        orderId: task.issue_source_id,
      });
    }
  };


  const getUserResponsibleName = (task) => {
    if (task.task_status === 'todo') {
      return task.created_by_name;
    } else if (task.task_status === 'in_progress') {
      return task.task_inprogress_by_name || task.created_by_name;
    } else if (task.task_status === 'done') {
      return task.task_done_by_name || task.task_inprogress_by_name || task.created_by_name;
    }
    return 'Unknown Status'; // Fallback case if the task_status is not recognized
  };
  const token = useToken()

  const getStyles = () => ({
    backgroundColor: token.colorBgBase,
    color: token.colorTextBase,
  });


  return (
    <div
      className={classes.parent}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onOpenTaskDrawer(task, board);
        }}
        className={classes.taskCard} style={getStyles()} 
      >
        <div
          className={classes.headerContainer}
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(task.operation_task_id);
          }}
        >
          <div className={classes.ticketIdContainerLeft}>
            <p className={classes.ticketId}>
              {' '}
              T# <b>{task.operation_task_id}</b>
            </p>
            <p className={`${classes.row} ${classes.ticketIdTimer}`}>
              <ClockIcon />
              {
                statusCode === 'todo'
                  ? dayjs().from(dayjs(task.created_at), true)
                  : statusCode === 'in_progress'
                    ? dayjs().from(dayjs(task.task_inprogress_at), true)
                    : statusCode === 'done'
                      ? dayjs().from(dayjs(task.updated_at), true)
                      : statusCode === 'reject'
                        ? dayjs().from(dayjs(task.task_rejected_at), true)
                        : statusCode === 'on_hold' ? dayjs().from(dayjs(task.task_onhold_at), true) : null


              }

            </p>
          </div>
          <div
            className={classes.merchantIdContainer}
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(task.issue_source_id);

            }}
          >
            <div>
              <p className={classes.ticketId}>{task.issue_source_type}</p>
              <p className={classes.ticketId}>
                {' '}
                <b>#{task.issue_source_id}</b>
              </p>
            </div>
            <div className={classes.taskContainer}>
              {task.tags?.map((tag, idx) => (
                <div className={classes.category}
                  key={tag.source_name}
                >{tag.source_name}</div>
              ))}
            </div>
          </div>
        </div>

        <div className={classes.cardBody}>
          <div className={classes.cardHeader}>
            <div className={classes.left}>
              <div>
                <h4 className={classes.headerColor}>{task.task_issue_name}</h4>

              </div>
              <div
                style={{
                  marginLeft: '15px',
                }}
              >

              </div>
            </div>

            <div className={classes.menuButton}>
              <Tooltip
                title={
                  <RenderMenu
                    onOpenChangeOrderStatusModal={onOpenChangeOrderStatusModal}
                    currentOrder={currentOrder}
                    onOpenOrderMap={onOpenOrderMap}
                    onOpenOrderCart={onOpenOrderCart}
                    onOpenRefund={onOpenRefund}
                    onOpenOrderEdit={onOpenOrderEdit}
                    onOpenAssignCourier={onOpenAssignCourier}
                    onOpenMerchantInfo={onOpenMerchantInfo}
                  />
                }
                className={classes.tooltip}
                trigger={['click']}
                onClick={(e) => e.stopPropagation()}
                onOpenChange={onOpenActionMenuChange}
              >
                <div>
                  <MenuIcon />
                </div>
              </Tooltip>
            </div>
          </div>

          <div className={classes.cardContent}>
            <div className={`${classes.collapseContent} `}>
              {task?.merchant ? (
                <div>
                  <div
                    className={`${classes.merchantContainer} ${classes.hasChat}`}
                  >
                    <div className={classes.nameAndIcon}>
                      {/* <Merchant /> */}
                      <Image
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        src={task?.merchant?.source_image}
                        alt="Merchant"
                        width={35}
                        className={classes.imageStyle}
                      />
                      <div className={classes.merchantTextContainer}>
                        <div
                          className={`${classes.row} ${classes.merchantNamecontainer}`}
                        >
                          <VipIcon width={15} height={20} />

                          <span
                            className={classes.merchantName}
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(task.merchant.source_id);
                            }}
                          >
                            {task?.merchant?.source_name}{' '}
                            {task?.merchant?.source_city
                              ? `(${task?.merchant?.source_city})`
                              : ''}
                          </span>
                        </div>
                        <span className={classes.merchantAccountManager}>
                          AM: {task?.source_subtitle}
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        type="ghost"
                        icon={
                          task.merchantOrderCountMessages ? (
                            <div>
                              {' '}
                              <div
                                className={classes.badgeContainer}
                                size="small"
                                count={5}
                              >
                                <Chat fill={'#F79C9C'} />
                                <span className={classes.badge}>{task.merchantOrderCountMessages}</span>
                              </div>
                            </div>
                          ) : (
                            <Chat fill={'#919191'} />
                          )
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onOpenDrawerChat(task, 'merchant');
                        }}
                      />
                      <Tooltip
                        placement="top"
                        title={task.merchant?.source_phone}
                      >
                        <Button
                          type="ghost"
                          icon={<Phone fill={'#919191'} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(task.merchant?.source_phone);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {task?.customer || task.driver ? (
                    <div className={classes.dividerClass} />
                  ) : null}
                </div>
              ) : null}
              {/* /// product */}
              {task?.product ? (
                <div>
                  <div
                    className={`${classes.merchantContainer} ${classes.hasChat}`}
                  >
                    <div className={classes.nameAndIcon}>
                      {/* <Merchant /> */}
                      <Image
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        src={task?.product?.source_image}
                        alt="Merchant"
                        width={35}
                        className={classes.imageStyle}
                      />
                      <div className={classes.merchantTextContainer}>
                        <div
                          className={`${classes.row} ${classes.merchantNamecontainer}`}
                        >

                          <span
                            className={classes.merchantName}
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(task.product.source_id);
                            }}
                          >
                            {task?.product?.source_name}{' '}
                            {task?.product?.source_city
                              ? `(${task?.product?.source_city})`
                              : ''}
                          </span>




                        </div>


                        <span className={classes.merchantAccountManager}>
                          #{task?.product?.source_id}
                        </span>
                      </div>
                    </div>
                    <Button

                      type='text' icon={<EditOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenEditProductIssueModal(task.product)
                      }} />
                  </div>

                </div>
              ) : null}



              <CustomerAndDriverDisplay
                task={task}
                onOpenCustomerInfo={onOpenCustomerInfo}
                onOpenDriverInfo={onOpenDriverInfo}
                onOpenDrawerChat={onOpenDrawerChat}
                copyToClipboard={copyToClipboard}
              />
            </div>
          </div>
          <div className={classes.footerStyle}>
            <div>
              <Button
                className={`${classes['statusCode']} `}
                style={{ background: '#767CB2' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                icon={<AgentIcon width={11} height={11} />}
              >

                {getUserResponsibleName(task)}



              </Button>
            </div>
            <div>
              {task.task_issue_counter ? (
                <Button
                  className={classes['task-container']}
                  icon={<TaskIcon width={16} height={16} />}
                >
                  x{task.task_issue_counter}
                </Button>
              ) : null}
            </div>


            <div className={classes.row}>
              <Button
                className={`${classes.row} ${classes['progressContainer']}`}
                onClick={(e) => {
                  e.stopPropagation();
                  changeTaskStatus({
                    operation_task_id: task.operation_task_id,
                    task_status: statusCode,
                    task_comment: inputValue.current,


                  });
                }}
                loading={changeTaskLoading}
              >
                <ToInProgress
                  className={classes.buttonStatusIcon}
                  fill={statusColor}
                />
                {statusText}
                <span className={classes['progress-time-circle']}>
                  {dayjs().from(dayjs(task.created_at), true)}
                </span>
              </Button>
            </div>
          </div>
          {task.task_comment && (
            <div className={classes.agentTextContainer}>
              <p className={classes.agentText}>
                {task.task_comment_by_name ? <b>{task.task_comment_by_name} : </b> : ''}
                {highlightMentions(task.task_comment).map((part, index) => (
                  <span
                    key={index}
                    style={part.style}
                  >
                    {part.text}
                  </span>
                ))}
              </p>
              {/* <SmallChatIcon width={26} height={26} /> */}
            </div>
          )}
          <div className={classes.inputContainer}>
            <input
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                inputValue.current = e.target.value;
              }}
              className={classes.inputStyle}
              placeholder="Share your insights !"
            />
            <Button
              loading={sendCommentLoading}
              onClick={async (e) => {
                e.stopPropagation();
                const body = {
                  taskId: task.operation_task_id,
                  comment: inputValue.current,
                };
                await sendCommend(body);
              }}
              type="text"
              icon={<SendIcon width={15} height={15} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderTasks;



const highlightMentions = (comment) => {
  const generateColors = (count) => {
    // Adjusted colors for higher contrast
    const colors = ['#6A9C89', '#4682B4', '#87CEEB', '#DAA520', '#E0FFFF', '#B0E57C'];
    return colors.slice(0, count);
  };

  const extractMentions = (comment) => {
    const mentionRegex = /@(\w+)/g;
    const mentions = [];
    let match;
    while ((match = mentionRegex.exec(comment)) !== null) {
      mentions.push(match[1]);
    }
    return mentions;
  };

  const mentions = extractMentions(comment);
  const colors = generateColors(mentions.length);

  let parts = [];
  let lastIndex = 0;

  mentions.forEach((mention, index) => {
    const color = colors[index];
    const mentionRegex = new RegExp(`@${mention}`, 'g');
    const match = mentionRegex.exec(comment);
    if (match) {
      // Push text before the mention
      if (match.index > lastIndex) {
        parts.push({ text: comment.substring(lastIndex, match.index), style: {} });
      }
      // Push the mention with style
      parts.push({
        text: match[0],
        style: {
          backgroundColor: color,
          borderRadius: '4px',
          padding: '2px 4px',
          color: '#fff' // Ensure text is readable on the background
        }
      });
      lastIndex = match.index + match[0].length;
    }
  });

  // Push remaining text after the last mention
  if (lastIndex < comment.length) {
    parts.push({ text: comment.substring(lastIndex), style: {} });
  }

  return parts;
};
