const Chat =  ({fill="#313131"}) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82318 0H5.07547C3.72978 0.0013148 2.43957 0.536473 1.48802 1.48802C0.536473 2.43957 0.0013148 3.72978 0 5.07547V5.8502C0.0013148 7.1959 0.536473 8.4861 1.48802 9.43765C2.43957 10.3892 3.72978 10.9244 5.07547 10.9257H6.9527L7.90125 12.4155C7.96827 12.5237 8.06188 12.6129 8.17316 12.6746C8.28444 12.7364 8.40967 12.7686 8.53693 12.7681C8.66503 12.767 8.79071 12.7331 8.90199 12.6697C9.01327 12.6062 9.10643 12.5153 9.1726 12.4056L10.0864 10.9157C11.3683 10.8898 12.5893 10.3637 13.4884 9.44962C14.3876 8.53559 14.8937 7.30619 14.8986 6.02402V5.07547C14.8973 3.72978 14.3622 2.43957 13.4106 1.48802C12.4591 0.536473 11.1689 0.0013148 9.82318 0ZM4.96622 6.26736H9.93243C10.0641 6.26736 10.1905 6.31969 10.2836 6.41282C10.3767 6.50596 10.4291 6.63227 10.4291 6.76399C10.4291 6.8957 10.3767 7.02202 10.2836 7.11515C10.1905 7.20829 10.0641 7.26061 9.93243 7.26061H4.96622C4.8345 7.26061 4.70819 7.20829 4.61505 7.11515C4.52192 7.02202 4.46959 6.8957 4.46959 6.76399C4.46959 6.63227 4.52192 6.50596 4.61505 6.41282C4.70819 6.31969 4.8345 6.26736 4.96622 6.26736ZM4.46959 4.0127C4.46959 3.88099 4.52192 3.75467 4.61505 3.66154C4.70819 3.5684 4.8345 3.51608 4.96622 3.51608H9.93243C10.0641 3.51608 10.1905 3.5684 10.2836 3.66154C10.3767 3.75467 10.4291 3.88099 10.4291 4.0127C10.4291 4.14441 10.3767 4.27073 10.2836 4.36387C10.1905 4.457 10.0641 4.50932 9.93243 4.50932H4.96622C4.8345 4.50932 4.70819 4.457 4.61505 4.36387C4.52192 4.27073 4.46959 4.14441 4.46959 4.0127Z"
        fill={fill}
      />
    </svg>
  );
};

export default Chat;
