import { Button, Divider, Form, Input, message, Modal, Spin, Tag } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../configs/axios";

function AssignOrderTagForm(props) {
  const { selectedOrder, getAllOrders, ordersSearchFilters, closeModal } =
    props;
  const [assignedTags, setAssignedTags] = useState([]);

  const { data: availableTagsData, loading: availableTagsLoading } =
    useAxiosGet("/tags", {
      autoRun: true,
    });

  const { loading: assignTagLoading, request: assignTagRequest } = useAxiosPost(
    `/orders/tags`,
    {
      tags: assignedTags,
    }
  );

  useEffect(() => {
    setAssignedTags(selectedOrder.orderTags);
  }, []);

  const onSetTags = async () => {
    try {
      const requestBody = {
        orderId: selectedOrder.orderId,
        tags: assignedTags,
      };

      await assignTagRequest(requestBody);
      closeModal();
        await getAllOrders(ordersSearchFilters);
      message.success("Tags assigned successfully");
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: "50%",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Assigned Tags
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Available Tags
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          minHeight: "300px",
        }}
      >
        <div
          style={{
            width: "100%",
            border: "1px solid #e8e8e8",
            padding: "10px 15px",
            borderRadius: "15px",
          }}
        >
          {assignedTags.map((tag) => (
            <Tag
              key={tag._id}
              color={tag.color}
              onClick={() => {
                assignedTags.splice(assignedTags.indexOf(tag), 1);
                setAssignedTags([...assignedTags]);
              }}
              style={{
                margin: "3px",
                cursor: "pointer",
              }}
            >
              {tag.name}
            </Tag>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            borderRadius: "15px",
            padding: "10px 15px",
            border: "1px solid #e8e8e8",
          }}
        >
          {availableTagsLoading ? (
            <Spin size="large" />
          ) : (
            availableTagsData
              ?.filter(
                (el) =>
                  assignedTags.findIndex((tag) => tag._id === el._id) === -1
              )
              ?.map((tag) => (
                <Tag
                  key={tag._id}
                  color={tag.color}
                  onClick={() => {
                    setAssignedTags([...assignedTags, tag]);
                  }}
                  style={{
                    margin: "3px",
                    cursor: "pointer",
                  }}
                >
                  {tag.name}
                </Tag>
              ))
          )}
        </div>
      </div>

      <div
        style={{
          float: "right",
          marginTop: "10px",
        }}
      >
        <Button loading={assignTagLoading} onClick={onSetTags} type="primary">
          Save
        </Button>
      </div>
    </div>
  );
}

export default AssignOrderTagForm;
